import Author from "./Author";
import Catalog from "./Catalog";

export default class Song {
    id: number;
    source: string;
    title: string;
    description: string;
    authors: Array<Author>;
    authorsText: Array<Author>;
    authorsMusic: Array<Author>;
    artists: Array<Author>;
    catalog: Array<Catalog>;
    copyrights: string;

    added: boolean; // mixin для плейлиста

    constructor(id: number, source: string, title: string, description: string, authors: Array<Author>,
                authorsText: Array<Author>, authorsMusic: Array<Author>, artists: Array<Author>,
                catalog: Array<Catalog>, copyrights: string, added: boolean = false) {
        this.id = id;
        this.source = source;
        this.title = title;
        this.description = description;
        this.authors = authors;
        this.authorsText = authorsText;
        this.authorsMusic = authorsMusic;
        this.artists = artists;
        this.catalog = catalog;
        this.copyrights = copyrights;
        this.added = added;
    }

    public toString = () : string => {
        return `Song: ${this.id} ${this.title} `
    }
}