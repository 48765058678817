import "./PlayerSongCopyrightsComponent.less";

export default {
    controller: PlayerSongCopyrightsComponentController,
    transclude: false,
    bindings: {
        song: "<"
    },
    template: `
        <div class="player-song-copyrights">
            <p><span ng-i18next="song.author.music">Автор музыки</span>: {{$ctrl.allNames($ctrl.song.authorsMusic)}}</p>
            <p><span ng-i18next="song.author.lyrics">Автор текста</span>: {{$ctrl.allNames($ctrl.song.authorsText)}}</p>
            <p>{{$ctrl.song.copyrights}}</p>
        <div>
    `.trim()
};
function PlayerSongCopyrightsComponentController($scope) {

    $scope.$ctrl.allNames = function(arr) {
        return !!arr ? arr.map(item => item.name).join(', ') : "";
    };
}

PlayerSongCopyrightsComponentController.$inject = ["$scope"];