import BrandingService from "../../../branding/BrandingService";
import {IAngularStatic, IHttpParamSerializer, IHttpPromise, IHttpService, IPromise, IQService, IHttpResponse} from "angular";
import Catalog from "../entity/Catalog";
import Song from "../entity/Song";
import CatalogAndSong from "../entity/CatalogAndSong";
import {DefaultContext, ExecutionContext} from "./entity/ActionContext";
import {ClassConstructor, plainToInstance} from "class-transformer";

export type HttpParam = { [key: string]: string };

export class CatalogContent {
    catalog: Catalog;
    songs: Song[];
    catalogSize: number | null;

    constructor(catalog: Catalog, songs: Song[], catalogSize: number | null = null) {
        this.catalog = catalog;
        this.songs = songs;
        this.catalogSize = catalogSize;
    }
}

export class EventContent extends CatalogContent {
    eventId: string;
    start: Date;
    end: Date;
}

export default class DataService {

    static $inject = [/*'angular',*/ '$http','$q', '$httpParamSerializer', 'BrandingService'];

    private readonly angular: IAngularStatic;
    private readonly $http: IHttpService;
    private readonly $q: IQService;
    private readonly $httpParamSerializer: IHttpParamSerializer;
    private readonly brandingService: BrandingService;

    constructor(
        // Add the parameter and type definition.
        /*angular: window.angular,*/
        $http: IHttpService,
        $q: IQService,
        $httpParamSerializer: IHttpParamSerializer,
        brandingService: BrandingService
    ) {
        this.angular = window.angular; // todo
        this.$http = $http;
        this.$q = $q;
        this.$httpParamSerializer = $httpParamSerializer;
        this.brandingService = brandingService;
    }

    private adaptUrl(url: string) : string {
        const durl = document.URL;
        if (durl === null || (durl.indexOf('http://') === -1 && durl.indexOf('https://') === -1)) {
            // Cordova
            switch (this.brandingService.operator) {
                // todo: adopt "Tele2", "TCell", "MegaFonTJ", "MegaFon", "Ucell", "Mobiuz", "Megacom", "Uztelecom"
                case 'MegaFon':
                    return "http://karaoke.megafonpro.ru" + url;
                case 'Tele2':
                    return "http://karaoke.tele2.ru" + url;
                case 'T2':
                    return "http://karaoke.t2.ru" + url;
                case 'Okg':
                    return "http://karaoke.o.kg" + url;
                default:
                    return "http://karaoke.jumpit.ru" + url;
            }
        } else {
            // browser - get url
            return "." + url;
        }
    }

    /**
     * Путь к данным, требующим авторизации (например, должен проходить через прокси)
     *
     * @param url относительная часть пути, начинающаяся со '/'
     * @returns {string} полный путь
     */
    public serviceUrl(url: string): string {
        //return "." + url;
        let adaptedUrl;
        switch (this.brandingService.operator) {
            case 'MegaFon':
                adaptedUrl = url;
                break;
            case 'Tele2':
                /*const durl = document.URL;
                if (!!durl && durl.toLowerCase().indexOf("//funcontent.ru/waptele2/wap/proxy") !== -1) {
                    adaptedUrl = '/' + url;
                } else {
                    adaptedUrl = '/proxy' + url;
                }*/
                // adaptedUrl = '/proxy' + url;
                adaptedUrl = url;
                break;
            case 'Okg':
                adaptedUrl = url;
                break;
            default:
                adaptedUrl = url;
        }

        return this.adaptUrl(adaptedUrl);
    };

    /**
     * Путь к данным, НЕ требующим авторизации (может использоваться в обход прокси)
     *
     * @param url относительная часть пути, начинающаяся со '/'
     * @returns полный путь
     */
    public dataUrl(url: string): string {
        //return "http://gda.sofitlabs.ru/srv/karaokesrv/karaoke" + url;
        //return '/srv/karaoke' + url;
        //return '.' + url;
        return this.adaptUrl(url);
    };

    /**
     * Запрос, который должен быть проведён через прокси
     *
     * @param url относительный адрес
     * @param angularHttpParams параметры запроса для $http
     * @param method HTTP-метод
     * @returns $http
     */
    public serviceHttp<T>(url: string, angularHttpParams: Object = {}, method = 'GET'): IHttpPromise<T> {
        //return "/waptele2/wap/proxy/karaoke" + url;

        return this.$http<T>(jQuery.extend(true, {
            method: method,
            crossDomain: true,
            cache: false,
            url: this.serviceUrl(url)
        }, angularHttpParams));
    };

    /**
     * Запрос, которые НЕ требуют авторизации
     *
     * @param url относительный адрес
     * @param angularHttpParams дополниельне параметры запроса
     * @param method HTTP-метод
     * @returns
     * todo: переписать с указанием типов
     */
    public dataHttp<T>(url: string, angularHttpParams: Object = {}, method = 'GET'): IHttpPromise<T> {
        //return "/waptele2/wap/proxy/karaoke" + url;

        return this.$http<T>(jQuery.extend(true, {
            method: method,
            crossDomain: true,
            cache: true,
            url: this.dataUrl(url)
        }, angularHttpParams));
    };

    /**
     * Запрос, которые НЕ требуют авторизации
     *
     * @param url относительный адрес
     * @param cls класс ответа
     * @param angularHttpParams дополниельне параметры запроса
     * @param method HTTP-метод
     * @returns
     */
    public dataHttpTypedArray<T>(url: string, cls: ClassConstructor<T>, angularHttpParams: Object = {}, method = 'GET'): IHttpPromise<T[]> {
        //return "/waptele2/wap/proxy/karaoke" + url;

        const def = this.$q.defer<IHttpResponse<T[]>>()
        this.$http<T[]>(jQuery.extend(true, {
            method: method,
            crossDomain: true,
            cache: true,
            url: this.dataUrl(url)
        }, angularHttpParams)).then((response) => {
            if ((!!response.data && angular.isArray(response.data))) {
                response.data = plainToInstance(cls, response.data);
            }
            def.resolve(response)
        }).catch((reason) => {
            def.reject(reason)
        })

        return def.promise;
    };

    public dataPlaylist<DataTop>(url: string, params: Object = {}): IHttpPromise<DataTop> {
        return this.dataHttp<DataTop>(url, params);
    }

    /**
     * Отправка post-запроса
     * @param {string} url
     * @param data Blob или другие данные
     * @param {string} csrf
     * @param responseType
     * @returns {*}
     */
    public sendPost(url: string, data: FormData|Blob|any, csrf: string, responseType: string): any { //todo: remove any
        if (data instanceof FormData) {
            // todo: remove?
            return this.$http.post(url, data, {
                withCredentials: true,
                headers: {
                    'Content-Type': undefined,
                    'X-CSRF-TOKEN': csrf
                },
                transformRequest: this.angular.identity
            }).then((response) => {
                return response;
            }).catch((errorResponse) => {
                console.log("Error", errorResponse.status);
                throw errorResponse;
            });
        } else {
            const postData = data instanceof Blob ? data : new Blob([JSON.stringify(data)], {type: 'application/json'});
            return this.$http.post(url, postData, {
                responseType: responseType,
                headers: {
                    // It as important to set the content type header to undefined so that the XHR send method can set the
                    // content type header. Otherwise the AngularJS framework will override with content type application/json.
                    'Content-Type': undefined,
                    'X-CSRF-TOKEN': csrf
                }

            }).then(function (response) {
                /*var data = response.data;
                var status = response.status;
                var statusText = response.statusText;
                var headers = response.headers;
                var config = response.config;*/
                return response;

            }).catch(function (errorResponse) {
                console.log("Error", errorResponse.status);
                throw errorResponse;
            });
        }

    };

    public logout(logoutPath: string, csrf: string, params: JSON): IHttpPromise<string> {
        const dr = this.$q.defer<IHttpResponse<string>>();

        /*this.$http.post<string>(logoutPath, null, {
            headers: {
                'X-CSRF-TOKEN': csrf,
                "Authorization": "Basic logout"
            }
        }).then(function (handleResponse: IHttpResponse<string>) {
            dr.resolve(handleResponse);
        }).catch(function (errorResponse) {
            dr.reject(errorResponse);
        });*/

        const logoutAuth = 'Basic ' + btoa('none:none');
        const logoutPostParams = !!params ? this.$httpParamSerializer(params) : null;

        // IE
        if (document.execCommand("ClearAuthenticationCache")) {
            this.$http.post<string>(logoutPath, logoutPostParams, {
                headers: {
                    'X-CSRF-TOKEN': csrf,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((r: IHttpResponse<string>) => dr.resolve(r)).catch(e => dr.reject(e));

        } else if (/firefox|iceweasel|fxios/i.test(window.navigator.userAgent)) { // Firefox (72.0.1)

            // url.username = 'none';
            // url.password = 'none';
            const logoutUrl = new URL(logoutPath, document.baseURI).href.replace("://", "://none:none@");
            this.$http.post<string>(logoutUrl, params, {
                headers: {
                    'X-CSRF-TOKEN': csrf
                }
            }).then((r: IHttpResponse<string>) => dr.resolve(r)).catch(e => dr.reject(e));

        } else if (/chrome/i.test(window.navigator.userAgent) || this.brandingService.operator === 'Tele2' || this.brandingService.operator === 'T2') { // Google Chrome (79.0.3945.88)
            console.log("chr")
            fetch(logoutPath, {
                method: 'POST',
                redirect: 'manual',
                credentials: 'include',
                body: logoutPostParams,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': logoutAuth,
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-TOKEN': csrf
                }
            }).then((fr) => {
                console.log("f then")
                console.log(fr.redirected)
                console.log(fr.status)
                console.log(fr.url)
                // if (fr.redirected) {
                //     console.log("f r " + fr.url)
                //     window.location.href = fr.url;
                //     return
                // }

                fr.text().then((text) => {
                    dr.resolve({
                        data: text,
                        status: fr.status,
                        headers: null,
                        config: null,
                        statusText: fr.statusText,
                        xhrStatus: 'complete'
                    } as IHttpResponse<string>);
                }).catch((err) => {
                    if (err.status === 401) {
                        dr.resolve({
                            data: "401",
                            status: fr.status,
                            headers: null,
                            config: null,
                            statusText: fr.statusText,
                            xhrStatus: 'complete'
                        } as IHttpResponse<string>);
                    } else {
                        dr.reject({
                            data: err.response,
                            status: err.status,
                            headers: null,
                            config: null,
                            statusText: err.statusText,
                            xhrStatus: 'error'
                        } as IHttpResponse<string>);
                    }
                })

            }).catch(err => {
                dr.reject({
                    data: err.response,
                    status: err.status,
                    headers: null,
                    config: null,
                    statusText: err.statusText,
                    xhrStatus: 'error'
                } as IHttpResponse<string>);
            });

        } else { // Safari (12)
            const xhr = new XMLHttpRequest();
            xhr.open("POST", logoutPath, true, 'none', 'none');
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.setRequestHeader('X-CSRF-TOKEN', csrf);
            xhr.onload = function () {
                dr.resolve({
                    data: xhr.response,
                    status: xhr.status,
                    headers: null,
                    config: null,
                    statusText: xhr.statusText,
                    xhrStatus: 'complete'
                } as IHttpResponse<string>);
            };
            xhr.onerror = function(err) {
                dr.reject({
                    data: xhr.response,
                    status: xhr.status,
                    headers: null,
                    config: null,
                    statusText: xhr.statusText,
                    xhrStatus: 'error'
                } as IHttpResponse<string>);
            };
            xhr.send(logoutPostParams)
        }

        return dr.promise;
    };

    public sendBeacon(url: string, params: HttpParam) : void {
        const serviceUrl = this.serviceUrl(url);
        const formData = this.$httpParamSerializer(params);
        if (!!navigator.sendBeacon && !!Blob) {
            navigator.sendBeacon(serviceUrl, new Blob([formData], {
                type: 'application/x-www-form-urlencoded'
            }));
        } else {
            window.fetch(serviceUrl, {
                method: 'POST', headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: formData,
                credentials: 'include'
            });
        }
    };

    public requestCatalogs(): IPromise<CatalogAndSong[]> {
        const def = this.$q.defer<CatalogAndSong[]>()
        this.dataHttp<CatalogAndSong[]>('/catalogs.json').then((response) => {
            // hack
            let result = [];
            response.data.forEach((item) => {
                const json = item as any;
                const tsj = json.topSong;

                const ts: Song = new Song(
                    tsj.id,
                    tsj.source,
                    tsj.title,
                    tsj.description,
                    tsj.authors,
                    tsj.authorsText,
                    tsj.authorsMusic,
                    tsj.artists,
                    tsj.catalog,
                    tsj.copyrights
                );

                const cas: CatalogAndSong = new CatalogAndSong(
                    json.id as number,
                    json.source as string,
                    json.title as string,
                    json.description as string,
                    json.priority as number,
                    ts as Song
                );
                
                result.push(cas);
            })

            def.resolve(result);
        }).catch((reason) => {
            def.reject(reason);
        })
        return def.promise;
        // return this.dataHttp<CatalogAndSong[]>('/catalogs.json');
    }

    public requestTop(): IHttpPromise<CatalogContent> {
        return this.dataHttp<CatalogContent>('/top.json');
    }

    public requestLatest(): IHttpPromise<CatalogContent> {
        return this.dataHttp<CatalogContent>('/latest.json');
    }

    public requestEventsCatalogs(): IHttpPromise<Array<EventContent>> {
        return this.dataHttp<Array<EventContent>>('/events.json');
    }

    /*public history() {
        return this.dataHttp('/history/load')
    }*/

    public songs(songsIds: number[]): IHttpPromise<Song[]> {
        let query = "";
        songsIds.forEach(item => {
            query = query + (query.length > 0 ? ',' : '') + item;
        });
        return this.dataHttpTypedArray<Song>('/songs?id=' + query, Song);
    }

    public perfSong(playbackId: string): IHttpPromise<Song> {
        return this.dataHttp<Song>(`/performance/song?sid=${playbackId}`, Song);
    }
}
