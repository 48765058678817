export default class SearchController {

    constructor($scope, $controller, $routeParams, $location, DataService, PlaylistService, TextUtilService) {

        $scope.searchModel = {
            byName: true,
            byGenre: false,
            byAuthor: false,
            value: ""
        };

        $scope.active = false;
        $scope.message = null;

        angular.extend(this, $controller('BasePlaylistController', {
            $scope: $scope,
            playlistOptions: {
                title: "search.title",
                play: false,
                playlist: true
            },
            songs: null,  // null - признак отсутствия поиска
            sourceId: 'search'
        }));

        // блокировка кнопки поиска без данных
        $scope.$watchCollection('[searchModel.byGenre, searchModel.byName, searchModel.byAuthor, searchModel.value]', function(newValues, oldValues){
            var newModel = $scope.searchModel;
            $scope.active = (newModel.byGenre || newModel.byName || newModel.byAuthor) && newModel.value.length > 1;
        });

        $scope.doSearch = function () {
            var value = $scope.searchModel.value;
            if (value.length > 0) {
                $scope.go("/search/" + value + "/");
            }
        };

        $scope._doSearch = function () {
            $scope.message = null;

            DataService.dataHttp('/search-song', {
                params: {
                    'byGenre': $scope.searchModel.byGenre,
                    'byName': $scope.searchModel.byName,
                    'byAuthor': $scope.searchModel.byAuthor,
                    'q': $scope.searchModel.value
                }
            }).
            then(function (response) {
                $scope.songs = PlaylistService.processExternalSongList(response.data);
            }, function () {
                $scope.message = TextUtilService.localizedText("search.error"); // "Невозможно выполнить поиск, попробуйте позже."
                $scope.songs = null;
            });
        };

        if ($routeParams.query != null) {
            $scope.searchModel.value = $routeParams.query;
            $scope._doSearch();
        }
    }
}

SearchController.$inject = ['$scope', '$controller', '$routeParams', '$location', 'DataService', 'PlaylistService', 'TextUtilService'];