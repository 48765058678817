import PlayerStatus from "./PlayerStatus"

export type EventHandler = (status: PlayerStatus) => void;
export type ErrorHandler = (cause: PlayerError) => void;
export type AnalyzerEventHandler = (analyzerData: AnalyzerData) => void;

export enum ErrorState {
    NO_SUPPORT = "e_no_support",
    URL = "e_url",
    URL_NOT_SET = "e_url_not_set",
    UNKNOWN = ""
}

export class AnalyzerData {

    private readonly _analyserBufferLength: number;
    private readonly _analyserNodeDataArray: Float32Array;
    private readonly _amplitude: number;

    constructor(analyserBufferLength: number, analyserNodeDataArray: Float32Array) {
        this._analyserBufferLength = analyserBufferLength;
        this._analyserNodeDataArray = analyserNodeDataArray;
        this._amplitude = amplitude(analyserBufferLength, analyserNodeDataArray);
    }

    get analyserBufferLength(): number {
        return this._analyserBufferLength;
    }

    get analyserNodeDataArray(): Float32Array {
        return this._analyserNodeDataArray;
    }

    get amplitude(): number {
        return this._amplitude;
    }
}

export function amplitude(analyserBufferLength: number, analyserNodeDataArray: Float32Array): number {
    // const freqs = audioUtil.frequencies()
    const freqs = analyserNodeDataArray;
    const minHz = 40;
    const maxHz = 100;
    // var avg = average(analyser, freqs, minHz, maxHz);
    // var radius = Math.min(width, height) / 4 * avg

    let avg = 0;
    for (var i = 0; i < analyserBufferLength; i++) {
        avg += analyserNodeDataArray[i] / 128.0;
    }
    avg = avg / analyserBufferLength;

    let wv = Math.max(0, Math.min(1, Math.abs(avg)));
    let wv2 = avg < -1000 ? 0.01 : Math.max(0.1, (wv - 0.5) * 3);
    //console.info(`${jPlayerStatus.currentTime} - ${self.link.scope.karaoke.current} - ${avg} - ${wv} - ${wv2}`);

    return wv2;
}

export class PlayerError {
    
    private readonly _state: ErrorState;
    private readonly _details: string;

    constructor(state: ErrorState, details: string = null) {
        this._state = state;
    }

    get state(): ErrorState {
        return this._state;
    }

    get details(): string {
        return this._details;
    }
}

export class SongSource {
    private readonly _mp3: () => string = null;
    private readonly _m4a: () => string = null;

    constructor(mp3: () => string, m4a: () => string) {
        this._mp3 = mp3;
        this._m4a = m4a;
    }

    get mp3(): string {
        return !!this._mp3 ? this._mp3() : null;
    }

    get m4a(): string {
        return !!this._m4a ? this._m4a() : null;
    }
}

export interface IPlayerEngine {

    readonly audioContext: AudioContext;

    init(): IPlayerEngine;

    status(): PlayerStatus;

    play(): void;

    pause(): void;

    destroy(): PlayerStatus;

    setVolume(value: number): void;

    getVolume(): number;

    setCurrentTime(currentTime: number): void;

    addCurrentTime(offsetTime: number): void;

    createMediaElementSource(audioContext: AudioContext): AudioNode;

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    addOnLoadedData(f: EventHandler): void;

    addOnPlay(f: EventHandler): void;

    addOnPause(f: EventHandler): void;

    addOnTimeUpdate(f: EventHandler): void;

    addOnAnalyzerUpdate(f: AnalyzerEventHandler): void;

    addOnEnded(f: EventHandler): void;

    addOnSeeked(f: EventHandler): void;

    addOnRateChange(f: EventHandler): void;

    addOnError(f: ErrorHandler): void;

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    removeOnLoadedData(func: EventHandler): void;

    removeOnPlay(f: EventHandler): void;

    removeOnPause(f: EventHandler): void;

    removeOnTimeUpdate(f: EventHandler): void;

    removeAnalyzerUpdate(f: AnalyzerEventHandler): void

    removeOnEnded(f: EventHandler): void;

    removeOnSeeked(f: EventHandler): void;

    removeOnRateChange(f: EventHandler): void;

    removeOnError(f: ErrorHandler): void;
}