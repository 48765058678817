import "angularjs-slider/dist/rzslider.js";
import './TrackFragmentSliderComponent.less';
import {Component} from "../../../general/decorators";
import {TimeLine} from "../model/PlayerModel";

@Component({
    controllerAs: '$ctrl',
    transclude: false,
    bindings: {
        handler: "<",
        timeLine: "<",
        noTime: "<",
        handleRewind: "<",
        minMs: "<",
        maxMs: "<"
    },
    template: `
        <rzslider
         rz-slider-model="$ctrl.minMs" 
         rz-slider-high="$ctrl.maxMs"
         rz-slider-options="$ctrl.rzOptions"></rzslider>`
})
export default class TrackFragmentSliderComponent implements ng.IOnInit, ng.IOnChanges {

    public handler: (startMs: number, finishMs: number) => void;
    public timeLine: TimeLine;
    public noTime: boolean;
    public handleRewind: (progress: number) => void;
    public minMs: number;
    public maxMs: number;
    public rzOptions: ng.RzSlider.RzOptions;

    $onInit(): void {
        const songLengthMs = !!this.timeLine ? this.timeLine.duration : 0
        this.minMs = !!this.minMs ? this.minMs : 0
        this.maxMs = !!this.maxMs ? this.maxMs : songLengthMs
        this.rzOptions = {
            floor: 0,
            ceil: 1,
            minLimit: 0,
            maxLimit: songLengthMs,
            // step: 1 / this.graduation,
            precision: 1,
            hidePointerLabels: true,
            hideLimitLabels: true,
            onChange: (id: string, modelValue: number, highValue: number, pointerType: "min" | "max") => this.handler(this.minMs, this.maxMs),
            showSelectionBar: true
        };
    }

    $onChanges(changes: ng.IOnChangesObject): void {
        if (!!changes.songLengthMs) {
            const newMaxMs = Number(changes.songLengthMs);
            this.rzOptions.maxLimit = newMaxMs;
            this.maxMs = newMaxMs;
        }
    }
}
