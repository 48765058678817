import UserPaymentProfile from "./UserPaymentProfile";
import Battle from "../../performance/Battle";
import BrandingService from "../../../../branding/BrandingService";

export class UserCampaign {
    public readonly source: string;
    public readonly medium: string;
    public readonly campaign: string;
    public readonly content: string;
    public readonly clickId: string;
    public readonly ggclickid: string;
}

export default class UserProfile {

    constructor(
        private readonly brandingService: BrandingService,
        public readonly authenticated: boolean = false,
        public readonly paymentProfile: UserPaymentProfile = new UserPaymentProfile(brandingService),
        public readonly paid: boolean = false,
        public readonly msisdn: string = null,
        public readonly promos: Array<string> = [],
        public readonly nickName: string = null,
        public readonly profileId: number = null,
        public readonly battles: Array<Battle> = null,
        public readonly inviteBattleId: number = null,
        public readonly inviteBattleCode: string = null,
        public readonly campaign: UserCampaign = null,
        public readonly csrf: string = null
    ) {
    }
}
