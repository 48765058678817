//var _rootScope = new WeakMap();

// import "../../../external/bootstrap/bootstrap.js";
import "../../../external/bootstrap/js/affix.js";

class AffixDirective {
    constructor($rootScope, $timeout) {
        this.restrict = 'AC';
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
    }

    applyAffix ($scope, $element, $attrs) {
        AffixDirective.instance.$timeout(function () {
            var top = $($element).offset().top;
            $($element).affix({offset: {top: top}});

            var affix = $attrs.affix;
            if (affix != null) {
                $($element).css('top', affix + 'px');
            }
        }, 1000);
    }

    link($scope, $element, $attrs) {
        var $rootScope = AffixDirective.instance.$rootScope,
            applyAffix = AffixDirective.instance.applyAffix;

        $rootScope.$on('$stateChangeSuccess', function() {
            $element.removeData('bs.affix').removeClass('affix affix-top affix-bottom');
            applyAffix($scope, $element, $attrs);
        });

        applyAffix($scope, $element, $attrs);
    }

    static factory($rootScope, $timeout){
        AffixDirective.instance = new AffixDirective($rootScope, $timeout);
        return AffixDirective.instance;
    }
}

AffixDirective.factory.$inject = ['$rootScope', '$timeout'];
export default AffixDirective.factory;