/**
 * Базовая конфигурация караоке-приложений
 *
 * @param $locationProvider
 * @param $httpProvider
 */
export default function karaokeConfig($locationProvider, $httpProvider) {
    $httpProvider.defaults.headers.common = {
        "X-Requested-With": "Karaoke"
    };

    // use the HTML5 History API
    $locationProvider.html5Mode(false);

    /*if (!!bootbox) {
        bootbox.addLocale("ru-ru", {
            OK      : "Ok",
            CANCEL  : "Отмена",
            CONFIRM : "Применить"
        });

        bootbox.setDefaults({
            locale: "ru-ru",
        });
    }*/

    // автоматическая конвертация дат вида "2017-03-11T11:30:00Z"
    var dateRegex = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\.\d+)?([+-][0-2]\d(:?[0-5]\d)?|Z)$/;
    function recurseObject(object) {
        var result = object;
        if (object != null) {
            result = angular.copy(object);
            for (var key in result) {
                var property = result[key];
                if (typeof property === 'object') {
                    result[key] = recurseObject(property);
                } else if (typeof property === 'string' && dateRegex.test(property)) {
                    result[key] = new Date(property);
                }
            }
        }
        return result;
    }
    $httpProvider.defaults.transformResponse = function(data) {
        try {
            var object;
            if (typeof data === 'object') {
                object = data;
            } else {
                object = JSON.parse(data);
            }
            return recurseObject(object);
        } catch(e) {
            return data;
        }
    };
}

karaokeConfig.$inject = ["$locationProvider", "$httpProvider"];