import {ActionContext, PayActionContext, SongContext, TrialTransaction} from "./entity/ActionContext";
import TextUtilService from "./TextUtilService";
import BrandingService, {TrialSettings} from "../../../branding/BrandingService";
import {IQService} from "angular";
import SongListCompressorService from "./SongListCompressorService";
import StorageService from "./StorageService";
import Song from "../entity/Song";
import {SongAction} from "./entity/SongAction";

export default class TrialService {

    static $inject = ['$q', 'StorageService', 'BrandingService', 'TextUtilService'];

    private trialSettings: TrialSettings;
    private songsInTrial: Map<number, TrialTransaction>; // todo:

    constructor(
        private readonly q: IQService,
        private readonly storageService: StorageService,
        private readonly brandingService: BrandingService,
        private readonly textUtilService: TextUtilService
    ) {
        this.default()
        this.songsInTrial = new Map<number, TrialTransaction>(); //
    }

    /**
     * Движок триала по-умолчанию для данного оператора
     */
    public default() {
        this.trialSettings = this.brandingService.trialSettings;
        this.trialSettings.load(this.storageService)
    }

    /**
     * Переопределение движка триала для промо-событий
     * @param trialSettings имплементация промо-триала
     */
    public override(trialSettings: TrialSettings) {
        if (this.trialSettings !== trialSettings) {
            this.trialSettings = trialSettings;
            this.trialSettings.load(this.storageService)
        } else {
            // console.info("already set");
        }
    }

    /**
     * Возможен ли try-доступ
     * @param actionContext {ActionContext}
     * @return {boolean}
     */
    public isTryAllowed(actionContext: ActionContext) : boolean {
        return this.createTrialTransaction(actionContext) !== null;
    };

    public isTryMainAllowed(actionContext: ActionContext) : boolean {
        if (actionContext instanceof SongContext && actionContext.isPlusSongAction()) {
            return this.createTrialTransaction(new SongContext(actionContext.song, SongAction.PERFORM_RECORD)) !== null;
        } else {
            return this.isTryAllowed(actionContext);
        }
    };

    /**
     *
     * @param actionContext {ActionContext} целевое действие
     * @return {TrialTransaction|null} транзакция для триал-доступа, либо null, если она недоступна
     */
    public createTrialTransaction(actionContext: ActionContext): TrialTransaction | null {
        if (!actionContext || !actionContext.constructor || !actionContext.constructor.name) {
            return null;

            // } else if (actionContext.constructor.name === CatalogContext.name) {
            //     return null; // not implemented

        } else if (actionContext instanceof PayActionContext) {
            // никакого try если хотим просто подписаться
            return null;

        } else if (actionContext instanceof SongContext) { /*actionContext.constructor.name === SongContext.name ||*/
            console.info(actionContext);
            const song: Song = actionContext.song;
            const source: string = song == null ? null : song.source;
            const songAction: SongAction = actionContext.songAction;

            if (actionContext.isPlusSongAction()) {
                console.info('[TS] deny plus');
                return null; // для plus-действий триал не доступн
            }

            const existedTrial = !song ? null : this.songsInTrial.get(song.id);
            if (!!existedTrial) {
                console.info("[TS] return existed trial");
                return existedTrial;
            }

            if (!!source) {
                const allowed = this.trialSettings.isTrialAllowed(source);
                if (allowed) {
                    return new TrialTransaction(
                        true,
                        (trx) => {
                            if (!this.songsInTrial.has(song.id)) {
                                this.songsInTrial.set(song.id, trx);
                                this.trialSettings.onBeginAction(source, this.storageService)
                            }
                        },
                        (trx) => {
                            if (this.songsInTrial.has(song.id)) {
                                this.songsInTrial.delete(song.id);
                                this.trialSettings.onSubmitAction(source, this.storageService)
                            }
                        },
                        (trx) => {
                            if (this.songsInTrial.has(song.id)) {
                                this.songsInTrial.delete(song.id);
                                this.trialSettings.onRollbackAction(source, this.storageService)
                            }
                        }
                    );
                } else {
                    console.info('[TS] deny no attempts left');
                    return null;
                }
            }
        }

        /*return !!userData && !!userData.msisdn && userData.paymentProfile.trial.terms == true;*/
        // todo: test
        // if (NODE_ENV === "dev") return true;
        //return !StorageService.get("tryExpired");

        console.info('[TS] deny default');
        return null;
    };

    /**
     * Запрет try-доступа (временный, на 1 день)
     */
    /*_this.markTryAsUsed = function (actionContext) {
        StorageService.set("tryExpired", true, 1);
    };*/

    /**
     * Разрешение try-доступа
     */
    /*_this.markTryAsUnused = function (actionContext) {
        StorageService.remove("tryExpired");
    };*/

    /**
     * Промо-текст, описывающий возможность текущего действия
     * @return {String}
     */
    public describe(source: string = null/*context*/): string {
        if (!!source) {
            // const songs = _this.trialSettings.counters.songs[source];
            // const toFree = TextUtilService.pluralizeSongs(songs, 'песню', null);
            // return !!toFree ? `Вы можете исполнить бесплатно ${toFree}` : null;
            return this.trialSettings.renderText(source, this.textUtilService);
        } else {
            //return "Вы можете исполнить бесплатно одну песню из основного каталога и 10 песен из раздела \"Поем дома\"";
            return this.trialSettings.renderText(null, this.textUtilService);
            //return _this.trialSettings.defaultText;
        }
    };
}