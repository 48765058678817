import {IRootScopeService} from "angular";

export default class StorageService {

    // private readonly $rootScope: IRootScopeService;
    private readonly ipCookie: any;
    private readonly preferCookies: boolean;

    /**
     * идентификатор объекта-обёртки для ограничения хранения объекта по времени
     */
    private readonly timeWrapperObjId: string;

    static $inject = [/*'$rootScope', */'ipCookie'];

    constructor(/*$rootScope: IRootScopeService,*/ ipCookie: any) {
        // this.$rootScope = $rootScope;
        this.ipCookie = ipCookie;
        //var preferCookies = (window.localStorage == null);
        this.preferCookies = (document.URL.indexOf('http://') !== -1 || document.URL.indexOf('https://') !== -1);
        this.timeWrapperObjId = "Storage.timeWrapper";
    }

    private static addDays(date: Date, days: number): Date {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    public get(key: string, defaultValue = null): any {
        const orDefault = (value) => !!value ? value : defaultValue;
        if (this.preferCookies) {
            return orDefault(this.ipCookie(key));
        } else {
            let objAsString = window.localStorage.getItem(key);

            if (typeof objAsString === 'string') {
                const obj = JSON.parse(objAsString);
                if (!!obj &&
                    obj.hasOwnProperty("objid") && (obj.objid === this.timeWrapperObjId) &&
                    obj.hasOwnProperty("tillTime") && obj.tillTime instanceof Date &&
                    obj.hasOwnProperty("payload")) {

                    const outdated = (new Date()).getTime() > obj.tillTime.getTime();
                    if (outdated) {
                        window.localStorage.removeItem(key);
                        return defaultValue;
                    } else {
                        return orDefault(obj.payload);
                    }
                } else {
                    return objAsString;
                }
            } else {
                return objAsString;
            }
        }
    }

    /**
     * Сохранение пары ключ-значение на ограниченное число дней
     * @param key ключ
     * @param value значение
     * @param days если null - на неопределённое время
     */
    public set(key: string, value: any, days: number | null = null): void {
        if (this.preferCookies) {
            this.ipCookie(key, value, {expires: days != null ? days : 365});
        } else {
            // если указано ограничение по дням, добавляем обёртку
            value = days == null ? value : {
                objid: this.timeWrapperObjId,
                tillTime: StorageService.addDays(new Date(), days),
                payload: value
            };

            window.localStorage.setItem(key, JSON.stringify(value));
        }
    };

    public remove(key: string): void {
        if (this.preferCookies) {
            this.ipCookie.remove(key);
        } else {
            window.localStorage.removeItem(key);
        }
    };
}