import {SongAction} from './SongAction';
import Song from "../../entity/Song";

export type MarkableFunc = ((TrialTransaction) => void) | boolean;

const NO_OP: MarkableFunc = (TrialTransaction) => {};

export interface ExecutionContext {

}

export class DefaultContext implements ExecutionContext {

}

export class SuccessPaymentContext implements ExecutionContext {

}

export class TrialTransaction implements ExecutionContext {

    private _trialUsed: boolean;
    private _beginAction: MarkableFunc;
    private _submitAction: MarkableFunc;
    private _rollbackAction: MarkableFunc;

    /**
     * Контекст, позволяющий подтивердить/отменить платное действие
     * @param trialUsed {boolean}
     * @param beginAction {Function}
     * @param submitAction {Function}
     * @param rollbackAction {Function}
     */
    constructor(
        trialUsed: boolean,
        beginAction: MarkableFunc = NO_OP,
        submitAction: MarkableFunc = NO_OP,
        rollbackAction: MarkableFunc = NO_OP
    ) {
        if (typeof beginAction !== "function") {
            throw new Error("beginAction must be function");
        } else if (typeof submitAction !== "function") {
            throw new Error("submitAction must be function");
        } else if (typeof rollbackAction !== "function") {
            throw new Error("rollbackAction must be function");
        }

        this._beginAction = beginAction;
        this._submitAction = submitAction;
        this._rollbackAction = rollbackAction;
        this._trialUsed = trialUsed;
    }

    beginAction() {
        if (typeof this._beginAction === "function") {
            const result = this._beginAction(this);
            this._beginAction = true;
            return result;
        }
    }

    /**
     * @return {*} результат выполнения метода или undefined, если действие не было указано
     */
    submitAction() {
        if (this._beginAction !== true) {
            console.warn("unable to call submitAction: beginAction has not been called");
            return undefined;

        } else if (this._rollbackAction === true) {
            console.warn("unable to call submitAction: rollbackAction already called");
            return undefined;

        } else if (this._submitAction === true) {
            console.warn("unable to call submitAction: already called");
            return undefined;

        } else if (typeof this._submitAction === "function") {
            const result = this._submitAction(this);
            this._submitAction = true;
            return result;

        } else {
            console.warn("unable to call submitAction, wrong state: " + this._submitAction);
            return undefined;
        }
    }

    /**
     * @return {*} результат выполнения метода или undefined, если действие не было указано
     */
    rollbackAction() {
        if (this._beginAction !== true) {
            console.info("ignore call rollbackAction: beginAction has not been called");
            // console.warn("unable to call rollbackAction: beginAction has not been called");
            return undefined;

        } else if (this._submitAction === true) {
            console.warn("unable to call rollbackAction: submitAction already called");
            return undefined;

        } else if (this._rollbackAction === true) {
            console.warn("unable to call rollbackAction: already called");
            return undefined;

        } else if (typeof this._rollbackAction === "function") {
            const result = this._rollbackAction(this);
            this._rollbackAction = true;
            return result;

        } else {
            console.warn("unable to call rollbackAction, wrong state: " + this._rollbackAction);
            return undefined;
        }
    }

    /**
     * Был ли использован триал
     * @return {boolean}
     */
    get trialUsed() {
        return this._trialUsed;
    }
}

export class ActionContext {
}

export class PayActionContext extends ActionContext {
}

export class SubMainContext extends PayActionContext {
}

export class SubPlusContext extends PayActionContext {
}

export class SubMainLandingContext extends SubMainContext {
}

/*export class GoContext extends ActionContext {
    constructor(public path: string) {
        super();
    }
}*/

/*
class AuthorContext extends ActionContext {
}

class CatalogContext extends ActionContext {

    constructor(catalogId) {
        super();
        this._catalogId = catalogId;
    }

    get catalogId() {
        return this._catalogId;
    }
}*/

export class SongContext extends ActionContext {

    private readonly _song: Song;
    private readonly _songAction: SongAction;

    //todo: alternativeAction?
    //todo: or playlist context?

    /**
     * @param song {Song}
     * @param songAction {SongAction}
     */
    constructor(song: Song, songAction: SongAction) {
        super();
        this._song = song;
        this._songAction = songAction;
    }

    get song(): Song {
        return this._song;
    }

    get songAction(): SongAction {
        return this._songAction;
    }

    public isMainSongAction(): boolean {
        return [
            SongAction.PERFORM,
            SongAction.PERFORM_RECORD
        ].some(x => x === this._songAction);
    }

    public isPlusSongAction(): boolean {
        return [
            SongAction.PERFORM_BATTLE,
            SongAction.PERFORM_CONCERT
        ].some(x => x === this._songAction);
    }
}

export class ResponseBattleSongContext extends SongContext {

    private readonly _battleId;

    constructor(song: Song, battleId: number) {
        super(song, SongAction.PERFORM_BATTLE);
        this._battleId = battleId;
    }

    get battleId() {
        return this._battleId;
    }
}