/**
 * Конфигурация путей для AngularJS, также зачитывается из Java для генерации статистики
 */
module.exports = [
    {
        path: "/",
        route: {
            templateUrl: "./pages/main.html",
            controller: "MainPageController"
        },
        description: {
            title: "Заглавная страница"
        }
    },
    {
        path: "/jukebox",
        route: {
            templateUrl: "./pages/jukebox.html",
            controller: "JukeboxController"
        },
        description: {
            title: "Плеер"
        }
    },
    {
        path: "/play/:songId",
        route: {
            templateUrl: "./pages/jukebox.html",
            controller: "JukeboxController"
        },
        description: {
            title: "Плеер",
            params: {
                ":songId": "Идентификатор песни"
            }
        }
    },
    {
        path: "/catalog",
        route: {
            templateUrl: "./pages/catalog.html",
            controller: "CatalogController"
        },
        description: {
            title: "Каталог"
        }
    },
    {
        path: "/playlist",
        route: {
            templateUrl: "./pages/playlist-my.html",
            controller: "MyPlaylistController"
        },
        description: {
            title: "Мои песни"
        }
    },
    {
        path: "/playlist/free",
        route: {
            templateUrl: "./pages/playlist.html",
            controller: "FreePlaylistController"
        },
        description: {
            title: "Бесплатные песни"
        }
    },
    {
        path: "/playlist/new",
        route: {
            templateUrl: "./pages/playlist-new.html",
            controller: "NewPlaylistController"
        },
        description: {
            title: "Избранное"
        }
    },
    {
        path: "/playlist/popular",
        route: {
            templateUrl: "./pages/playlist-popular.html",
            controller: "PopularPlaylistController"
        },
        description: {
            title: "Популярные"
        }
    },
    {
        path: "/playlist/:catalogId/",
        route: {
            templateUrl: "./pages/playlist.html",
            controller: "CatalogPlaylistController"
        },
        description: {
            title: "Раздел каталога \":catalogId\"",
            params: {
                ":catalogId": "Идентификатор каталога"
            }
        }
    },
    {
        path: "/search",
        route: {
            templateUrl: "./pages/search.html",
            controller: "SearchController"
        },
        description: {
            title: "Страница поиска"
        }
    },
    {
        path: "/search/:query",
        route: {
            templateUrl: "./pages/search.html",
            controller: "SearchController"
        },
        description: {
            title: "Страница поиска",
            params: {
                ":query": "Искомый текст"
            }
        }
    },
    {
        path: "/feedback",
        route: {
            templateUrl: "./pages/feedback.html",
            controller: "FeedbackController"
        },
        description: {
            title: "Обратная связь"
        }
    },
    {
        path: "/playback/:playbackId",
        route: {
            templateUrl: "./pages/playback.html",
            controller: "PlaybackController"
        },
        description: {
            title: "Прослушивание записи",
            /*params: {
                ":playbackId": "Идентификатор записи"
            }*/
        }
    },
    {
        path: "/playback/:playbackId/tone",
        route: {
            templateUrl: "./pages/playback-tone.html",
            controller: "PlaybackToneController"
        },
        description: {
            title: "Создание рингбэктона",
            params: {
                ":playbackId": "Идентификатор записи"
            }
        }
    },
    {
        path: "/profile",
        route: {
            templateUrl: "./pages/profile.html",
            controller: "ProfileController"
        },
        description: {
            title: "Профиль пользователя"
        }
    },
    {
        path: "/history",
        route: {
            templateUrl: "./pages/history.html",
            controller: "HistoryController"
        },
        description: {
            title: "Мои исполнения"
        }
    },
    {
        path: "/battle",
        route: {
            templateUrl: "./pages/battle.html",
            controller: "BattleController"
        },
        description: {
            title: "Батл: список"
        }
    },
    {
        path: "/battle/:battleId/",
        route: {
            templateUrl: "./pages/battle.html",
            controller: "BattleController"
        },
        description: {
            title: "Батл: просмотр деталей",
            params: {
                ":battleId": "Идентификатор батла"
            }
        }
    },
    {
        path: "/battle/:battleId/accept",
        route: {
            templateUrl: "./pages/battle.html",
            controller: "BattleController"
        },
        description: {
            title: "Батл: принятие приглашения",
            params: {
                ":battleId": "Идентификатор батла"
            }
        }
    },
    {
        path: "/event/ny/rules",
        route: {
            templateUrl: "./pages/events/2020-12-new-year-rules.html",
            controller: "SimplePageController"
        },
        description: {
            title: "Событие \"ny\": правила проведения"
        }
    },
    {
        path: "/event/ny/main",
        route: {
            templateUrl: "./pages/events/2020-12-new-year-main.html",
            controller: "MainPromoPageController"
        },
        description: {
            title: "Событие \"ny\": заглавная страница"
        }
    },
    {
        path: "/event/:eventId/playlist",
        route: {
            templateUrl: "./pages/playlist.html",
            controller: "EventPlaylistController"
        },
        description: {
            title: "Событие \":eventId\": плейлист",
            params: {
                ":eventId": "Идентификатор события"
            }
        }
    },


    {
        path: "/gift/",
        route: {
            templateUrl: "./pages/promo-code.html",
            controller: "PromoCodeController"
        },
        description: {
            title: "Активация промо-кода"
        }
    },
    {
        path: "/gift/:code",
        route: {
            templateUrl: "./pages/promo-code.html",
            controller: "PromoCodeController"
        },
        description: {
            title: "Активация промо-кода"
        }
    },

    {
        // действие по-умолчанию
        route: {
            templateUrl: "./pages/main.html",
            controller: "MainPageController"
        }
    }
];