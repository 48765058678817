/**
 * Playlist каталога
 */
export default class CatalogPlaylistController {

    constructor($scope, $routeParams, $controller) {
        const catalogId = $routeParams.catalogId;
        angular.extend(this, $controller('SimplePlaylistController', {
            $scope: $scope,
            source: `/catalog/${catalogId}.json`,
            backUrl: "/catalog",
            playlistOptions: {
                title: "core.loading",
                play: true, // по-умолчанию показываем проигрывание плейлиста
                playlist: true // отображаем функцию добавления в плейлист
            },
            sourceId: 'catalog:' + catalogId
        }));
    }
}

CatalogPlaylistController.$inject = ['$scope', '$routeParams', '$controller'];