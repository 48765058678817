class TimeFormat {

    constructor(
        public readonly showHour: boolean,
        public readonly showMin: boolean,
        public readonly showSec: boolean,
        public readonly padHour: boolean,
        public readonly padMin: boolean,
        public readonly padSec: boolean,
        public readonly sepHour: string,
        public readonly sepMin: string,
        public readonly sepSec: string,
    ) {
    }
}

export default class ConvertTime {

    private readonly timeFormat: TimeFormat;

    constructor() {
        this.timeFormat = new TimeFormat(
            false,
            true,
            true,
            false,
            true,
            true,
            ":",
            ":",
            ""
        );
    }

    public time(value: number): string { // function used on jPlayer.prototype._convertTime to enable per instance options.
        const s = (!!value && typeof value === 'number') ? value : 0;

        const myTime = new Date(s * 1000),
            hour = myTime.getUTCHours(),
            min = this.timeFormat.showHour ? myTime.getUTCMinutes() : myTime.getUTCMinutes() + hour * 60,
            sec = this.timeFormat.showMin ? myTime.getUTCSeconds() : myTime.getUTCSeconds() + min * 60,
            strHour = (this.timeFormat.padHour && hour < 10) ? "0" + hour : hour,
            strMin = (this.timeFormat.padMin && min < 10) ? "0" + min : min,
            strSec = (this.timeFormat.padSec && sec < 10) ? "0" + sec : sec;

        let strTime = "";
        strTime += this.timeFormat.showHour ? strHour + this.timeFormat.sepHour : "";
        strTime += this.timeFormat.showMin ? strMin + this.timeFormat.sepMin : "";
        strTime += this.timeFormat.showSec ? strSec + this.timeFormat.sepSec : "";
        return strTime;
    }
}