export default class PlayerStatus {

    private readonly _paused: boolean;

    /**
     * The duration of the media
     */
    private readonly _currentTime: number;

    /**
     * The current time in seconds
     */
    private readonly _duration: number;

    private readonly _progress: number;

    private readonly _playbackRate: number;
    private readonly _aenum: boolean;

    constructor(
        paused: boolean,
        currentTime: number,
        duration: number,
        playbackRate: number = 1,
        aenum: boolean = true
    ) {
        this._paused = paused;
        this._currentTime = currentTime;
        this._duration = duration;
        this._progress = currentTime * 100 / duration;
        this._playbackRate = playbackRate;
        this._aenum = aenum;
    }

    get paused(): boolean {
        return this._paused;
    }

    get currentTime(): number {
        return this._currentTime;
    }

    get duration(): number {
        return this._duration;
    }

    get progress(): number {
        return this._progress;
    }

    get playbackRate(): number {
        return this._playbackRate;
    }

    get aenum(): boolean {
        return this._aenum;
    }

    public toString(): string {
        return `${this._currentTime} of ${this._duration}`;
    }
}