function endsWith(string, suffix) {
    return string.indexOf(suffix, string.length - suffix.length) !== -1;
}

function areElement(ele) {
    return !(!ele || !(ele.className));
}

function hasClass(ele, cls) {
    return areElement(ele) && !!cls && !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}

function removeClass(ele, cls) {
    ele.className = ele.className.replace(new RegExp('(\\s|^)' + cls + '(\\s|$)'), ' ');
}

function addClass(ele, cls) {
    ele.className += ((endsWith(ele.className, ' ') || ele.className.length === 0) ? cls : (' ' + cls));
}

/**
 * HTML5 Audio Read-Along
 * @author Weston Ruter, X-Team
 * @license MIT/GPL
 * https://github.com/westonruter/html5-audio-read-along
 */
const ReadAlong = {
    
    /**
     * {HTMLElement}
     */
    text_element: null,

    /**
     * {IPlayerEngine}
     */
    audioEngine: null,

    /**
     * {Boolean}
     */
    autofocus_current_word: true,

    words: [],
    blocks: [],

    /**
     *
     * @param textElement {HTMLElement}
     * @param audioEngine {IPlayerEngine}
     */
    init: function (textElement, audioEngine) {
        this.text_element = textElement;
        this.audioEngine = audioEngine;
        this.words = [];
        this.blocks = [];

        this.generateWordList();
        this.generateBlockList();
        this.addEventListeners();

        const status = audioEngine.status();
        this.selectCurrentBlock(status);
        this.selectCurrentWord(status);
    },

    destroy: function() {
        if (!!this && !!this.audioEngine && !!this.eventListeners) {
            this.audioEngine.removeOnPlay(this.eventListeners.play);
            this.audioEngine.removeOnPause(this.eventListeners.pause);
            this.audioEngine.removeOnSeeked(this.eventListeners.seeked);
            this.audioEngine.removeOnRateChange(this.eventListeners.rateChange);
        }
    },

    /**
     * Build an index of all of the words that can be read along with their begin,
     * and end times, and the DOM element representing the word.
     */
    generateWordList: function () {
        var te = this.text_element;
        var word_els = this.text_element.querySelectorAll('[data-begin]');
        this.words = Array.prototype.map.call(word_els, function (word_el, index) {
            var begin = parseFloat(word_el.getAttribute('data-begin'));
            var end = parseFloat(word_el.getAttribute('data-end'));
            var dur = end - begin;
            var word = {
                'index': index,
                'begin': begin,
                'dur': dur,
                'end': end,
                'element': word_el
            };
            //word_el.tabIndex = 0; // to make it focusable/interactive
            word_el.setAttribute('data-index', word.index);
            return word;
        });
    },

    /**
     * From the audio's currentTime, find the word that is currently being played
     * @todo this would better be implemented as a binary search
     */
    getCurrentWord: function (currentTime) {
        var i;
        var len;
        var is_current_word;
        var word = null;
        for (i = 0, len = this.words.length; i < len; i++) {
            is_current_word = (
                (currentTime >= this.words[i].begin && currentTime < this.words[i].end) ||
                (currentTime < this.words[i].begin)
            );
            if (is_current_word) {
                word = this.words[i];
                //console.info(this.words[i].element.innerHTML + '' + this.words[i].begin + ' ' + currentTime + ' ' + this.words[i].end);
                break;
            }
        }

        if (word === null) {
            var msg = 'Unable to find current word and we should always be able to.' +
                ' Time is ' + currentTime + "." +
                " Last word time is " + this.words[this.words.length - 1].end;
            console.info(msg);
            //throw new Error(msg);
        }
        return word;
    },

    _current_end_select_timeout_id: null,
    _current_next_select_timeout_id: null,

    /**
     * Select the current word and set timeout to select the next one if playing
     * status {PlayerStatus}
     */
    selectCurrentWord: function(status) {
        return this.selectCurrentWordImpl(status.currentTime, !status.paused, status.playbackRate, status.aenum);
    },


    selectCurrentWordImpl: function(currentTime, isPlaying, playbackRate, aenum) {
        var that = this;
        var current_word = this.getCurrentWord(currentTime);
        var is_playing = isPlaying !== false;
        var playback_rate = !playbackRate ? 1.0 : playbackRate;

        if (current_word !== null && !hasClass(current_word.element, 'speaking')) {
            //this.removeWordSelection(); //+!

            // анимация долгого куплета
            var duration = Math.round(1000 * current_word.dur) + 'ms';
            current_word.element.style.transitionDuration = duration;
            current_word.element.style.WebkitTransitionDuration = duration;

            addClass(current_word.element, 'speaking');
            this.checkWordSelection(currentTime); //+

            if (this.autofocus_current_word == true) {
                current_word.element.focus();
            }
        }

        /**
         * The timeupdate Media event does not fire repeatedly enough to be
         * able to rely on for updating the selected word (it hovers around
         * 250ms resolution), so we add a setTimeout with the exact duration
         * of the word.
         */
        if (current_word !== null && is_playing) {
            // Remove word selection when the word ceases to be spoken
            var seconds_until_this_word_ends = current_word.end - currentTime; // Note: 'word' not 'world'! ;-)
            if (aenum) {
                seconds_until_this_word_ends *= 1.0 / playback_rate;
            }
            clearTimeout(this._current_end_select_timeout_id);
            this._current_end_select_timeout_id = setTimeout(
                function () {
                    //if (is_playing) { // we always want to have a word selected while paused
                        //addClass(current_word.element, 'pass');
                        //removeClass(current_word.element, 'speaking'); //+!
                    //}
                },
                Math.max(seconds_until_this_word_ends * 1000, 0)
            );

            // Automatically trigger selectCurrentWord when the next word begins
            var next_word = this.words[current_word.index + 1];
            if (next_word) {
                var seconds_until_next_word_begins = next_word.begin - currentTime;

                var orig_seconds_until_next_word_begins = seconds_until_next_word_begins; // temp
                if (aenum) {
                    seconds_until_next_word_begins *= 1.0 / playback_rate;
                }
                clearTimeout(this._current_next_select_timeout_id);
                this._current_next_select_timeout_id = setTimeout(
                    function () {
                        that.selectCurrentWord(that.audioEngine.status());
                    },
                    Math.max(seconds_until_next_word_begins * 1000, 0)
                );
            }
        }
    },

    checkWordSelection: function(currentTime) {
        this.words.forEach(function(element, index){
//            console.log('Called '+ document.getElementById('passage-audio').currentTime);
            if (element.begin < currentTime) {
                if (!hasClass(element.element, 'speaking')) {
                    addClass(element.element, 'speaking');
                }
                if (!hasClass(element.element, 'finish')) {
                    addClass(element.element, 'finish');
                }
            }
        });
    },

    removeBlockSelection: function() {
        // There should only be one element with .speaking, but selecting all for good measure
        var spoken_block_els = this.text_element.querySelectorAll('div[data-block_begin].speakingBlock');
        Array.prototype.forEach.call(spoken_block_els, function (spoken_block_el) {
            removeClass(spoken_block_el, 'speakingBlock');
        });
    },

    removeWordSelection: function() {
        // There should only be one element with .speaking, but selecting all for good measure
        var spoken_word_els = this.text_element.querySelectorAll('span[data-begin].speaking');
        Array.prototype.forEach.call(spoken_word_els, function (spoken_word_el) {
            //addClass(spoken_word_el, 'pass');
            removeClass(spoken_word_el, 'speaking');
        });
    },


    addEventListeners: function () {
        var that = this;

        that.eventListeners = {
            play: (status) => {
                that.selectCurrentWord(status);
                that.selectCurrentBlock(status);
                addClass(that.text_element, 'speaking');
                addClass(that.text_element, 'speakingBlock');
            },
            pause: (status) => {
                if (status.currentTime === 0) {
                    //Если стоп, то удаляем все отметки, начинаем заново
                    that.removeWordSelection();
                }
                that.selectCurrentWord(status); // We always want a word to be selected
                that.selectCurrentBlock(status);
                //addClass(that.text_element, 'pass');
                removeClass(that.text_element, 'speaking');
                //+ addClass(that.text_element, 'speaking');
                //+ addClass(that.text_element, 'speakingBlock');
            },
            seeked: (status) => {
                that.selectCurrentWord(status);
                that.selectCurrentBlock(status);

                /**
                 * Address probem with Chrome where sometimes it seems to get stuck upon seeked:
                 * http://code.google.com/p/chromium/issues/detail?id=99749
                 */
                if (!status.paused) {
                    var previousTime = status.currentTime;
                    setTimeout(function () {
                        if (!status.paused && previousTime === status.currentTime) {
                            // todo: implement
                            // audio_element.currentTime += 0.01; // Attempt to unstick
                        }
                    }, 500);
                }
            },
            rateChange: (status) => {
                that.selectCurrentWord(status);
                that.selectCurrentBlock(status);
            }
        };

        /**
         * Select next word (at that.audio_element.currentTime) when playing begins
         */
        that.audioEngine.addOnPlay(that.eventListeners.play);

        /**
         * Abandon seeking the next word because we're paused
         */
        that.audioEngine.addOnPause(that.eventListeners.pause);

        /**
         * Select a word when seeking
         */
        that.audioEngine.addOnSeeked(that.eventListeners.seeked);

        /**
         * Select a word when seeking
         */
        that.audioEngine.addOnRateChange(that.eventListeners.rateChange);
    },

    /**
     * для отображения блоками
     */
    generateBlockList: function () {
        var block_els = this.text_element.querySelectorAll('[data-block_begin]');
        this.blocks = Array.prototype.map.call(block_els, function (block_el, index) {
            var block = {
                'block_begin': parseFloat(block_el.getAttribute('data-block_begin')),
                'block_end': parseFloat(block_el.getAttribute('data-block_end')),
                'element': block_el,
                'index': index
            };
            //block_el.tabIndex = 0; // to make it focusable/interactive
            // block.index = index;
            block_el.setAttribute('data-index', block.index);
            return block;
        });
    },

    getCurrentBlock: function (currentTime) {
        var i;
        var len;
        var is_current_block;
        var block = null;
        for (i = 0, len = this.blocks.length; i < len; i += 1) {
            is_current_block = (
                (currentTime >= this.blocks[i].block_begin && currentTime < this.blocks[i].block_end) ||
                (currentTime < this.blocks[i].block_begin)
            );
            if (is_current_block) {
                block = this.blocks[i];
                break;
            }
        }

        if (block === null) {
            var msg = 'Unable to find current block and we should always be able to.' +
                ' Time is ' + currentTime + "." +
                " Last word time is " + this.words[this.words.length - 1].end;
            console.info(msg);
            //throw Error(msg);
        }
        return block;
    },

    _current_end_select_timeout_id_block: null,
    _current_next_select_timeout_id_block: null,

    /**
     *
     * @param status {PlayerStatus}
     */
    selectCurrentBlock: function(status) {
        return this.selectCurrentBlockImpl(status.currentTime, !status.paused, status.playbackRate, status.aenum);
    },

    selectCurrentBlockImpl: function(currentTime, isPlaying, playbackRate, aenum) {
        var that = this;
        var current_block = this.getCurrentBlock(currentTime);
        var is_playing = isPlaying !== false;
        var playback_rate = !playbackRate ? 1.0 : playbackRate;

        if (current_block !== null && !hasClass(current_block.element, 'speakingBlock')) {
            this.removeBlockSelection();
            addClass(current_block.element, 'speakingBlock');
        }

        if (current_block !== null && is_playing) {
            var seconds_until_this_block_ends = current_block.block_end - currentTime;
            if (aenum) {
                seconds_until_this_block_ends *= 1.0 / playback_rate;
            }
            clearTimeout(this._current_end_select_timeout_id_block);
            this._current_end_select_timeout_id_block = setTimeout(
                function () {
                    if (is_playing) { // we always want to have a word selected while paused
                        removeClass(current_block.element, 'speakingBlock');
                    }
                },
                Math.max(seconds_until_this_block_ends * 1000, 0)
            );

            // Automatically trigger selectCurrentWord when the next word begins
            var next_block = this.blocks[current_block.index + 1];
            if (next_block) {
                var seconds_until_next_block_begins = next_block.block_begin - currentTime;

                var orig_seconds_until_next_block_begins = seconds_until_next_block_begins; // temp
                if (aenum) {
                    seconds_until_next_block_begins *= 1.0 / playback_rate;
                }
                clearTimeout(this._current_next_select_timeout_id_block);
                this._current_next_select_timeout_id_block = setTimeout(
                    function () {
                        that.selectCurrentBlock(that.audioEngine.status());
                    },
                    Math.max(seconds_until_next_block_begins * 1000, 0)
                );
            }
        }

    }
};

export default ReadAlong;