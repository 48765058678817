/**
 * Базовый контроллер плейлиста
 */
import {karaoke} from "../../karaoke";
import IRootScopeService = karaoke.IRootScopeService;
import DataService from "../../services/DataService";
import PlaylistService from "../../services/PlaylistService";
import PlayerService from "../../services/PlayerService";
import TextUtilService from "../../services/TextUtilService";
import DecoratorService from "../../services/DecoratorService";
import Song from "../../entity/Song";

export class PlaylistOptions {
    /**
     *
     * @param title
     * @param play по-умолчанию показываем проигрывание плейлиста
     * @param playlist отображаем функцию добавления в плейлист
     */
    constructor(
        public title: string,
        public play: boolean,
        public playlist: boolean
    ) {
    }
}

export default class BasePlaylistController {

    static $inject = [
        '$scope', '$location', '$controller', '$rootScope',
        'DataService', 'PlaylistService', 'PlayerService', 'TextUtilService', 'playlistOptions',
        'songs', 'sourceId', 'DecoratorService'
    ];

    constructor(
        private readonly $scope,
        private readonly $location: ng.ILocationService,
        private readonly $controller: ng.IControllerService,
        private readonly $rootScope: IRootScopeService,
        private readonly dataService: DataService,
        private readonly playlistService: PlaylistService,
        private readonly playerService: PlayerService,
        private readonly textUtilService: TextUtilService,
        private readonly playlistOptions: PlaylistOptions,
        private readonly songs: [Song],
        private readonly sourceId: string,
        private readonly decoratorService: DecoratorService
    ) {

        angular.extend(this, $controller('BaseController', {$scope: $scope}));

        // TODO: ЗАВЕРНУТЬ ВСЁ В 1 ОБЪЕКТ

        $scope.songs = songs;// == null ? [] : songs;
        $scope.playlistOptions = playlistOptions;

        function findSong(songId: number) {
            let song = null;
            $scope.songs.forEach(function (item) {
                if (item.id === songId) {
                    song = item;
                }
            });
            return song;
        }

        const basePlayAction: (song: Song, playlistTitleCode: string, backPath: string, songsPlaylist: Song[], sourceId: string) => void = $scope.playAction;

        $scope.playAction = function (songOrSongId : Song | number, entirePlaylist : boolean = false) {
            const song: Song = isSong(songOrSongId) ? songOrSongId : findSong(songOrSongId as number);
            const backPath = $location.path();
            if (song != null) {
                basePlayAction(song, playlistOptions.title, backPath, entirePlaylist ? $scope.songs : [song], sourceId);
            } else {
                console.warn("song with id " + songOrSongId + " not found!");
            }
        };
        
        function isSong(songOrSongId : Song | number) {
            return !!songOrSongId && !!(songOrSongId as any).id; // todo better way, instanceof
        }

        $scope.addToPlaylistAction = (songOrSongId : Song | number,) => {
            const song: Song = isSong(songOrSongId) ? songOrSongId : findSong(songOrSongId as number);
             if (song != null) {
                this.playlistService.add(song);
                song.added = true;
            } else {
                console.warn("song with id " + songOrSongId + " not found!");
            }
        };

        $scope.action = function (songId: number) {

            if ($scope.playlistOptions.play) {
                $scope.playAction(songId);
            } else {
                $scope.addToPlaylistAction(songId);
            }
        };

        $scope.promoCatalogItems = decoratorService.promoCatalogContentItems((song) => $scope.playAction(song));
        $scope.promoNewItems = decoratorService.promoSongsPopularItems((song) => $scope.playAction(song));
        $scope.promoPopularItems = decoratorService.promoSongsNewItems((song) => $scope.playAction(song));
    }
}
