import DataService from "../../services/DataService";
import {IDeferred, IHttpPromise, IPromise, IQService, IWindowService} from "angular";
import {saveAs} from 'file-saver';
import Battle, {BattleStates} from "../../performance/Battle";
import AuthService from "../../services/AuthService";
import Song from "../../entity/Song";

export enum ErrorReason {
    NO_USER_TO_INVITE = 'NO_USER_TO_INVITE',
    FOREIGN_MSISDN = 'FOREIGN_MSISDN',
    UNABLE_VERIFY_MSISDN = 'UNABLE_VERIFY_MSISDN',
    ILLEGAL_STATE = 'ILLEGAL_STATE'
}

export class ResponseWrapper<T> {
    constructor(
        public readonly body: T,
        public readonly error: boolean,
        public readonly errorCode: ErrorReason,
    ) {
    }
}

export default class PerformanceService {

    static $inject = ['$q', '$window', 'DataService', 'AuthService'];

    constructor(
        private readonly $q: IQService,
        public $window: IWindowService,
        public dataService: DataService,
        public authService: AuthService
    ) {
    }

    public appleMobileOS(): boolean {
        const userAgent = window.navigator.userAgent;
        return !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i);
        // return !!userAgent.match(/Mac|iPhone|iPod|iPad/i);
    }

    public download(recordId: string, csrf: string): void {
        const newTab = true || this.appleMobileOS(); // todo: always new tab
        if (newTab) {
            // const url = DataService.serviceUrl(`/performance/obtainAudioRecord?externalId=${recordId.externalId}&prefix=${recordId.prefix}&suffix=${saveData.suffix}`);
            const url = this.dataService.serviceUrl(`/performance/obtainAudioRecord?sid=${recordId}`);
            // console.info(url);
            this.$window.open(url, 'karaoke-download');
        } else {
            this.dataService.sendPost(`/performance/obtainAudioRecord`, recordId, csrf, 'blob')
                .then(function (obtainResponse) {
                    const data = obtainResponse.data;
                    const status = obtainResponse.status;
                    const statusText = obtainResponse.statusText;
                    const headers = obtainResponse.headers;
                    const config = obtainResponse.config;

                    const contentType = headers('content-type');
                    const contentDisposition = headers('content-disposition'); //
                    const cdrx = new RegExp('.*name="(.*)".*');
                    const fileName = (contentDisposition.match(cdrx) || [undefined, undefined])[1];
                    const blob = new Blob([data], {type: contentType});
                    saveAs(blob, fileName);
                    // invokeSaveAsDialog(blob, fileName);
                    // return obtainResponse;
                });
        }
    }

    public downloadMixed(song:Song, recordId: string, volumeMinus:number, volumeVocal:number, csrf: string): void {
        const url = this.dataService.serviceUrl(`/performance/obtainAudioRecordMix?songId=${song.id}&sid=${recordId}&volumeMinus=${volumeMinus}&volumeVocal=${volumeVocal}`);
        this.$window.open(url, 'karaoke-download');

        /*const newTab = true || this.appleMobileOS(); // todo: always new tab
        if (newTab) {
            const url = this.dataService.serviceUrl(`/performance/obtainAudioRecordMix?songId=${song.id}&sid=${recordId}&volumeMinus=${volumeMinus}&volumeVocal=${volumeVocal}`);
            this.$window.open(url, 'karaoke-download');
        } else {
            this.dataService.sendPost(`/performance/obtainAudioRecordMix`, recordId, csrf, 'blob')
                .then(function (obtainResponse) {
                    const data = obtainResponse.data;
                    const status = obtainResponse.status;
                    const statusText = obtainResponse.statusText;
                    const headers = obtainResponse.headers;
                    const config = obtainResponse.config;

                    const contentType = headers('content-type');
                    const contentDisposition = headers('content-disposition'); //
                    const cdrx = new RegExp('.*name="(.*)".*');
                    const fileName = (contentDisposition.match(cdrx) || [undefined, undefined])[1];
                    const blob = new Blob([data], {type: contentType});
                    saveAs(blob, fileName);
                    // invokeSaveAsDialog(blob, fileName);
                    // return obtainResponse;
                });
        }*/
    }


    public publishRingTone(song: Song, recordId: string, volumeMinus: number, volumeVocal: number, sinceMs: number, tillMs: number, csrf: string): void {
        const url = this.dataService.serviceUrl(`/performance/submitRingTone?songId=${song.id}&sid=${recordId}&volumeMinus=${volumeMinus}&volumeVocal=${volumeVocal}&sinceMs=${sinceMs}&tillMs=${tillMs}`);
        this.$window.open(url, 'karaoke-ringtone');
    }

    public battle(battleId: number): IPromise<Battle | null> {
        const defResult: IDeferred<Battle> = this.$q.defer();
        if (battleId === null) {
            defResult.resolve(null);
        } else {
            this.dataService.serviceHttp<Battle>(`/performance/battle/load?battleId=${battleId}`)
                .then((httpResponse) => {
                    defResult.resolve(httpResponse.data);
                })
                .catch((onReject) => defResult.reject(onReject));
        }
        /*this.battles().then((data) => {
            const result: Battle = (!!data && data.length > 0)
                ? data.filter((balle: Battle) => balle.id == battleId).shift() : null;
            defResult.resolve(!!result ? result : null);
        }).catch((onReject) => defResult.reject(onReject));*/
        return defResult.promise;
    }

    public battles(): IPromise<Array<Battle>> {
        const defResult: IDeferred<Array<Battle>> = this.$q.defer();
        // return this.dataService.serviceHttp<Array<Battle>>('/performance/battle/load');
        /*this.dataService.serviceHttp<Array<Battle>>('/performance/battle/load').then((httpResponse) => {
            defResult.resolve(httpResponse.data);
        }).catch((onReject) => defResult.reject(onReject));*/
        // defResult.resolve(this.authService.battles());

        let battles: Battle[] = [];
        this.authService.battles().forEach((b) => battles.push(b));

        // батл по инвайту по ссылке
        const battleId = this.authService.userData.inviteBattleId;
        if (!!battleId && !battles.find((b) => b.id == battleId)) {
            this.dataService.serviceHttp<Battle>(`/performance/battle/load?battleId=${battleId}`)
                .then((httpResponse) => {
                    const inviteBattle: Battle = httpResponse.data
                    battles.push(inviteBattle);
                    defResult.resolve(battles);
                })
                .catch((onReject) => {
                    console.warn(`unable to load invite battle ${battleId}`)
                    // do nothing
                    defResult.resolve(battles);
                });
        } else {
            defResult.resolve(battles);
        }

        return defResult.promise;
    }

    public songDic(songsIds: number[]): Map<number, Song> {
        let songsDic: Map<number, Song> = new Map<number, Song>();
        if (!!songsIds && songsIds.length > 0) {
            this.dataService.songs(songsIds).then(function (response) {
                response.data.forEach((song) => {
                    songsDic.set(song.id, song);
                });
            }, function (reason) {
                console.warn("Unable to load song: " + reason);
            });
        }
        return songsDic
    }

    public verifyMsisdn(msisdn: string): IPromise<boolean | null> {
        const defResult: IDeferred<boolean | null> = this.$q.defer();
        this.dataService.serviceHttp<ResponseWrapper<boolean>>(`/performance/battle/verifyMsisdn/${msisdn}`)
            .then(function (response) {
                defResult.resolve(response.data.error ? null : response.data.body);
            }, function (reason) {
                console.warn("Unable to verify msisdn: " + reason);
                defResult.resolve(null);
            });
        return defResult.promise;
    }

    public inviteRandom(battleId: number, sameSong: boolean): IPromise<ResponseWrapper<void>> {
        const defResult: IDeferred<ResponseWrapper<void>> = this.$q.defer();
        this.dataService.serviceHttp<ResponseWrapper<void>>(
            `/performance/battle/inviteRandom?battleId=${battleId}&sameSong=${sameSong}`)
            .then(function (response) {
                defResult.resolve(response.data);
            }, function (reason) {
                defResult.reject(reason);
            });
        return defResult.promise;
    }

    public invite(battleId: number, sameSong: boolean, msisdn: string): IPromise<ResponseWrapper<string>> {
        const defResult: IDeferred<ResponseWrapper<string>> = this.$q.defer();
        const msisdnParam = !!msisdn ? `&number=${msisdn}` : '';
        this.dataService.serviceHttp<ResponseWrapper<string>>(
            `/performance/battle/invite?battleId=${battleId}&sameSong=${sameSong}` + msisdnParam)
            .then(function (response) {
                defResult.resolve(response.data);
            }, function (reason) {
                defResult.reject(reason);
            });
        return defResult.promise;
    }

    public sms(dst: string, message: string): void {
        console.info(`send message to ${dst}: ${message}`);
        if (/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)) {
            window.open(`sms:/${dst}&body=${encodeURIComponent(message)}`);
        } else {
            window.open(`sms:/${dst}?body=${encodeURIComponent(message)}`);
        }
        // window.open(`sms:${dst};?&body=${encodeURIComponent(message)}`);
    }

    public battleVerifyCode(battleId: number, battleCode: string): IHttpPromise<boolean> {
        return this.dataService.serviceHttp<boolean>(
            `/performance/battle/verifyInvite?battleId=${battleId}&battleCode=${battleCode}`);
    }

    public availableResponseBattles(songId: number) : IPromise<Array<Battle>> {
        const defResult: IDeferred<Array<Battle>> = this.$q.defer();
        this.battles().then((battles) => {
            let availableBattles = [];
            battles.forEach((b) => {
                if (!b.owner && [
                    BattleStates.OPPONENT_CHOOSE,
                    BattleStates.OPPONENT_INVITE_APPROVE,
                    BattleStates.OPPONENT_INVITE_AWAIT
                ].indexOf(b.status) >= 0) {
                    if (!b.sameSong || (b.sameSong && b.ownerSongId === songId)){
                        availableBattles.push(b);
                    } else {
                        console.info(`skip ${b}`);
                    }
                }
            });
            defResult.resolve(availableBattles);
        })

        return defResult.promise;
    }

    battleResult(battle: Battle): string | null {
        if (battle.status === BattleStates.COMPLETE) {
            const dif = (battle.ownerScore - battle.opponentScore) * (battle.owner ? 1 : -1);
            const otherName = battle.owner ? battle.ownerName : battle.opponentName;
            const score = `${Math.round(battle.ownerScore * 100)} : ${Math.round(battle.opponentScore * 100)}`;
            if (dif === 0) {
                // ничя
                return `Ничья с ${otherName}, счёт ${score}`
            } else if (dif > 0) {
                // победа
                return `Поздравляем! Вы победили ${otherName} со счётом ${score}`
            } else {
                // проигрыш
                return `Победил ${otherName} со счётом ${score}`
            }
        }
        return null;
    }
}
