import "./SubtitleComponent.less";
import template from "./SubtitleTemplate.html";
import ReadAlong from './subs_synchronized';

export default {
    transclude: true,
    bindings: {
        sub: "<",
        audio: "<"
    },
    controller: SubtitleComponent,
    template: template
};

function SubtitleComponent($element, $scope, $q, $interval, $timeout, TextUtilService, ModalMessageService) {

    const _this = this;

    var self = {};

    self.$player = null; // todo
    self.$subs = null;
    self.redrawInterval = null;
    $scope.ajustText = true;

    const deferredInit = $q.defer();
    const deferredSubData = $q.defer();
    const deferredJPlayer = $q.defer();

    $q.all([deferredInit.promise, deferredSubData.promise, deferredJPlayer.promise]).then((result) => initSubs(result[1]));

    function initSubs(subData) {

        //console.info("[KP] initSubs start");

        var textElement = $($element[0]).find(".passage");

        var textData = "";

        if (subData != null) {
            subData.forEach(function (block) {

                if (block != null) {
                    textData += '<div class="karaokeBlock" data-block_begin="' + block.timeStart/1000 + '" data-block_end="' + block.timeEnd/1000 + '">\n';
                    textData += '<span class="karaokeRow">\n';
                    block.data.forEach(function (word) {
                        if (word != null) {
                            textData += '<span data-begin="' + word.timeStart/1000 + '" data-end="' + word.timeEnd/1000 + '">' + word.letters + '</span>'
                        } else {
                            textData += '\n</span>\n<br/>\n<span class="karaokeRow">\n';
                        }
                    });
                    textData += '</span>\n';
                    textData += '</div>\n';
                }

            });

            textElement.html(textData);
            ajustSubs(textElement);

            var args = {
                text_element: textElement[0],
                audio_element: $($element[0]).find(".jp-jplayer audio")[0] // todo
            };
            ReadAlong.init(args);

            self.$subs = textElement;
            textElement.addClass("ready");

            //console.info("[KP] initSubs OK");
        } else {
            ModalMessageService.alert(TextUtilService.localizedText("performance.basic.error.sub")); // "Невозможно загрузить субтитры!"
            //console.info("[KP] initSubs FAIL");
        }
    }

    /**
     * Подгонка субтитров под ширину экарна
     *
     * todo: отслеживание изменения размера экрана
     *
     * @param textElement passage блок
     */
    function ajustSubs(textElement) {

        //console.info("[KP] ajustText start");

        if ($scope.ajustText) {

            var fromSize = 14;
            var tillSize = 64;

            var resultMinFontSize = tillSize;
            var resultMaxFontSize = fromSize;

            var passage = textElement;

            passage.css({
                'font-size': 'initial'
            });

            var blocks = passage.find('.karaokeBlock');

            var realSize = function (obj, fontSize) {

                var width = 0;
                var height = 0;

                if (obj.css('visibility') == 'hidden' && obj.css('display') != 'none') {
                    // используем сам объект

                    if (fontSize != null) {
                        obj.css({'font-size' : fontSize + 'px' });
                    }

                    width = obj.outerWidth();
                    height = obj.outerHeight();

                    if (fontSize != null) {
                        obj.css({'font-size' : '' });
                    }


                } else {
                    // придётся использовать объект-клон

                    var clone = obj.clone();

                    var cssData = {
                        'visibility': "hidden"
                    };

                    if (fontSize != null) {
                        cssData['font-size'] = fontSize + 'px';
                    }

                    clone.css(cssData);

                    $('body').append(clone);
                    width = clone.outerWidth();
                    height = clone.outerHeight();
                    clone.remove();
                }

                return {
                    width: width,
                    height: height
                };
            };

            var optimalFontSize = function (ourText, maxWidth, maxHeight) {
                var minFontSize = fromSize;
                var maxFontSize = tillSize;

                var fontSize = Math.floor((maxFontSize + minFontSize) / 2);

                do {
                    var size = realSize(ourText, fontSize);
                    var match = size.height <= maxHeight && size.width <= maxWidth;
                    if (match) {
                        minFontSize = fontSize;
                    } else {
                        maxFontSize = fontSize;
                    }

                    fontSize = Math.floor((maxFontSize + minFontSize) / 2);
                } while (fontSize > minFontSize);

                return fontSize;
            };


            var largestRow = null;
            var calcRow = function(ref){
                return {
                    ref: ref,
                    size: {
                        width: ref.outerWidth(),
                        height: ref.outerHeight()
                    },
                    smallerThen: function (otherRef) {
                        ////console.info(this.size.width + ' ~ ' + otherRef.width())
                        return this.size.width < otherRef.outerWidth()
                    }
                };
            };
            blocks.each(function () {

                $(this).find(".karaokeRow").each(function () {

                    if (largestRow == null) {
                        largestRow = calcRow($(this));
                    } else {
                        if (largestRow.smallerThen($(this))) {
                            largestRow = calcRow($(this))
                        }
                    }
                });
            });
            resultMinFontSize = optimalFontSize(largestRow.ref, passage.outerWidth(), passage.outerHeight() / 2);


            //console.info("[KP] ajustText set size " + resultMinFontSize);

            // выставляем единый размер шрифта по просьбе Саши Коровнна
            passage.css({
                'font-size': resultMinFontSize + 'px'
            });
            /*
             blocks.each(function () {
             $(this).css({
             'font-size': resultMinFontSize + 'px'
             });
             });
             */
        }

        //console.info("[KP] ajustText finish");
    }

    function start() {

    }

    function pause() {

    }

    function resume() {

    }

    function stop() {

    }


    // Use es6 destructuring to extract exactly what we need
    this.$onChanges = function({binding}) {
        console.info("SubtitleComponent $onChanges");
        if (angular.isDefined(binding)) {
            console.log({
                currentValue: binding.currentValue,
                isFirstChange: binding.isFirstChange()
            });
        }
    };

    this.$onInit = function () {

        console.info("SubtitleComponent $onInit");

        const textElement = $($element[0]).find(".passage");

        console.info(">>>>>>>>>");
        console.info($($element[0]));
        console.info(textElement);

        textElement.html('!!!!!!!');

        $scope.$on('player:initPlayerData', function (event, playerData) {
            console.info('[SSS] initPlayerData event');

            deferredInit.resolve(true);

            const $subs = self.$subs;
            initSubs($scope.$ctrl.sub); // todo
            self.redrawInterval = $interval(function() {
                if ($subs != null && $player != null && $player.data() != null && !$player.data().jPlayer.status.paused) {
                    $subs.hide();
                    $subs.get(0).offsetHeight; // no need to store this anywhere, the reference is enough
                    $subs.show();
                }
            }, 250);
        });

        $scope.$on('player:state', function (event, playerState) {
            const oldState = _this.playerState;
            const newState = playerState;

            console.info("got player:state " + playerState);

            if (newState === 'end') {
                stop();
            } else if (newState === 'play' && oldState === 'pause') {
                resume();
            } else if (newState === 'play') {
                start();
            } else if (newState === 'pause') {
                pause();
            }

            _this.playerState = newState;
        });

    };

    this.$onDestroy = function () {
        console.info("SubtitleComponent $onDestroy");
    };
}

SubtitleComponent.$inject = ['$element', '$scope', '$q', '$interval', '$timeout', 'TextUtilService', 'ModalMessageService'];