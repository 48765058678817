import Tile from "./entity/Tile";
import {IQService, IPromise, IDeferred} from "angular";
import DataService from "./DataService";
import {karaoke} from "../karaoke";
import IRootScopeService = karaoke.IRootScopeService;
import Song from "../entity/Song";
import PlaylistService from "./PlaylistService";
import Catalog from "../entity/Catalog";
import CatalogAndSong from "../entity/CatalogAndSong";

export default class DecoratorService {

    static $inject = ['$rootScope', '$q', 'DataService', 'PlaylistService'];

    constructor(
        private readonly $rootScope: IRootScopeService,
        private readonly $q: IQService,
        private readonly DataService: DataService,
        private readonly PlaylistService: PlaylistService
    ) {
    };

    public actionCatalog(catalogId): () => void {
        return () => this.$rootScope.go(!catalogId ? 'catalog' : `/playlist/${catalogId}/`);
    }

    //_this.actionSong = (songId) => () => $rootScope.go(!catalogId ? 'catalog' : `/playlist/${catalogId}/`);

    /*_this.promoCatalog = (catalogId) => {
        if (!catalogId) {
            return new Promo("Каталог", _this.actionCatalog(null), []);
        } else {
            return new Promo("Каталог", _this.actionCatalog(catalogId), []);
        }
    };*/

    /**
     * Выдача непосредственно каталогов
     */
    public promoCatalogItems(): IPromise<Tile[]> {
        const defResult: IDeferred<Tile[]> = this.$q.defer();
        const _this = this;
        this.DataService.requestCatalogs().then(function (data) {
            let result = [];
            const img = (catalog: Catalog) => _this.$rootScope.catalogImageUrl(catalog.id, 'large');
            const act = (catalog: Catalog) => _this.actionCatalog(catalog.id);
            data.forEach((catalog: CatalogAndSong) => {
                const name = catalog.title;
                const description = null; //catalog.description;
                // todo pass last object catalog entity
                result.push(new Tile(name, description, img(catalog), act(catalog), null));
            });
            defResult.resolve(result);
        }, function () {
            defResult.resolve([]);
        });
        return defResult.promise;
    };

    /**
     * Выдача содержимого каталогов
     */
    public promoCatalogContentItems(playAction: (Song, Catalog) => void): IPromise<Tile[]> {
        const defResult: IDeferred<Tile[]> = this.$q.defer();
        const _this = this;
        this.DataService.requestCatalogs().then(function (data) {
            let result = [];
            const img = (song) => _this.$rootScope.songImageUrl(song);
            const act = (song, catalog) => () => playAction(song, catalog);
            data.forEach((catalog) => {
                const song = catalog.topSong;
                // const songId = song.id;
                const name = song.title;
                const description = song.artists[0].name; //data[i].description;
                // todo pass last object song entity
                result.push(new Tile(name, description, img(song), act(song, catalog), song));
            });
            defResult.resolve(result);
        }, function () {
            defResult.resolve([]);
        });
        return defResult.promise;
    };

    /**
     * Выдача tiles по песням
     */
    public promoSongsItems(songs: Song[], playAction: (Song) => void): IPromise<Tile[]> {
        const defResult: IDeferred<Tile[]> = this.$q.defer<Tile[]>();
        let result = [];
        const img = (song) => this.$rootScope.songImageUrl(song);
        const act = (song) => () => playAction(song);
        if (!!songs) {
            songs.forEach((song) => {
                // const songId = song.id;
                const name = song.title;
                const description = song.artists[0].name;
                // todo pass last object song entity
                result.push(new Tile(name, description, img(song), act(song), song));
            });
        }
        defResult.resolve(result);
        return defResult.promise;
    };

    public promoSongsPopularItems(playAction: (Song) => void): IPromise<Tile[]> {
        const defResult: IDeferred<Tile[]> = this.$q.defer();
        const _this = this;
        this.DataService.requestTop().then((response) => {
            const songs = _this.PlaylistService.processExternalSongList(response.data.songs);
            _this.promoSongsItems(songs, playAction).then((items) => {
                defResult.resolve(items);
            });
        }, () => defResult.resolve([]));
        return defResult.promise;
    };

    public promoSongsNewItems(playAction: (Song) => void): IPromise<Tile[]> {
        const defResult: IDeferred<Tile[]> = this.$q.defer();
        const _this = this;
        this.DataService.requestLatest().then((response) => {
            const songs = _this.PlaylistService.processExternalSongList(response.data.songs);
            _this.promoSongsItems(songs, playAction).then((items) => {
                defResult.resolve(items);
            });
        }, () => defResult.resolve([]));
        return defResult.promise;
    };
}
