import Catalog from "./Catalog";
import Song from "./Song";

export default class CatalogAndSong extends Catalog {
    
    public topSong: Song;

    constructor(id: number, source: string, title: string, description: string, priority: number, _topSong: Song) {
        super(id, source, title, description, priority);
        this.topSong = _topSong;
    }

    /*constructor(
        public id: number,
        public source: string,
        public title: string,
        public description: string,
        public priority: number,
        public topSong: Song
    ) {
    }*/
}