import "./DisclaimerComponent.less";
import template from "./DisclaimerTemplate.html";
import {Component} from "../../../general/decorators";
import {karaoke} from "../../karaoke";
import BrandingService from "../../../../branding/BrandingService";
import {SubMainContext} from "../../services/entity/ActionContext";
import AuthService from "../../services/AuthService";
import TrialService from "../../services/TrialService";
import UserProfile from "../../services/entity/UserProfile";
import modalRecordTemplate from "../../player/pages/ModalRecord.html";
import TextUtilService from "../../services/TextUtilService";
import IScope = karaoke.IScope;
import EventService from "../../services/EventService";

@Component({
    controllerAs: '$ctrl',
    template: template
})
export default class DisclaimerComponent implements ng.IOnInit {

    static $inject = ['$rootScope','$scope', '$sce', '$compile', 'AuthService', 'TrialService', 'TextUtilService', 'EventService'];

    public unsubscribed: boolean;
    public show: boolean;
    public showTerms: () => void;
    public brandingService: BrandingService;
    public subscribeMain: () => void;
    public trialTerms: string;
    public terms: any;
    public price: string;
    public enterNotSubscribe: boolean;
    public eventId: string;

    constructor(
        private readonly $rootScope: karaoke.IRootScopeService,
        private readonly $scope: any,
        private readonly $sce: ng.ISCEService,
        private readonly $compile: ng.ICompileService,
        private readonly authService: AuthService,
        private readonly trialService: TrialService,
        private readonly textUtilService: TextUtilService,
        private readonly eventService: EventService
    ) {
        this.show = false;
        this.updateState();
    }

    private updateState() {
        this.enterNotSubscribe = !this.authService.isAuthenticatedFull() && this.authService.hasSubscribeOnAuth();
    }

    $onInit(): void {

        this.$rootScope.$watch("user", (user: UserProfile) => {
            this.unsubscribed = user?.paymentProfile?.subMain !== true;
            // this.$scope.$apply(() => {
            this.show = /*this.show ||*/ this.unsubscribed;
            this.updateState();
            // })
        });

        this.eventService.onActiveEvent(eventId => {
            /*this.$scope.$apply(() => {*/
            this.show = /*this.show ||*/ true;
            this.eventId = eventId;
            /*})*/

        })

        this.showTerms = this.$rootScope.showTerms;
        this.brandingService = this.$rootScope.brandingService;
        this.subscribeMain = () => this.authService.submitActionDefault(new SubMainContext());


        // смотреть за языком и компилировать
        const render = () => {
            const rawTerms = this.textUtilService.localizedText("disclaimer.terms", {interpolation: {escapeValue: false}});
            const priceText = this.brandingService.subscriptionPrice(this.textUtilService)
            const price = this.textUtilService.localizedText("disclaimer.price", {price: priceText, interpolation: {escapeValue: false}});
            const terms = !this.$rootScope.showTerms ? rawTerms : rawTerms
                .replace('<span>', '<a data-ng-show="!!$ctrl.showTerms" data-ng-click="$ctrl.showTerms()">')
                .replace('</span>', '</a>');

            this.price = price;
            this.trialTerms = this.trialService.describe(null);


            // this.terms = this.$sce.trustAsHtml(terms);
            // this.terms = this.$compile(angular.element(terms))(this.$scope);

            // const scope = this.$scope.$new(true, this.$scope);
            // const scope = angular.merge(newScope, this.data[i]);
            const scope = this.$scope;
            const element = $('#disclaimer-terms');

            setTimeout(() => {
                element.empty();
                element.append(this.$compile(`<span>${terms}</span>`)(scope));
            }, 1);

        }

        /*this.$scope.$apply(() => {
        })*/
        render();
        this.$rootScope.$on("i18nextLanguageChange", () => {
            render();
        });
        // на случай, если в пришедшей информации о пользователе будет событие, меняющее логику триала
        this.$rootScope.$on("contextLoaded", () => {
            render();
        });
    };
}
