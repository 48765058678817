import {SongAction} from "../services/entity/SongAction";
import {SubMainContext} from "../services/entity/ActionContext";
import {QuickSearch} from "../common/Search";
import {karaoke} from "../karaoke";
import IScope = karaoke.IScope;
import IRootScopeService = karaoke.IRootScopeService;
import PlaylistService from "../services/PlaylistService";
import DataService from "../services/DataService";
import TextUtilService from "../services/TextUtilService";
import BrandingService from "../../../branding/BrandingService";
import AuthService from "../services/AuthService";
import EventService from "../services/EventService";
import ModalMessageService from "../services/ModalMessageService";
import Song from "../entity/Song";

export default class RootController {

    static $inject = ['$scope', '$rootScope', 'PlaylistService', 'DataService', 'ModalMessageService', 'TextUtilService', 'BrandingService', 'AuthService', 'EventService'];

    constructor(
        $scope: any, // todo: IScope
        $rootScope: IRootScopeService,
        playlistService: PlaylistService,
        dataService: DataService,
        modalMessageService: ModalMessageService,
        textUtilService: TextUtilService,
        brandingService: BrandingService,
        authService: AuthService,
        eventService: EventService
    ) {

        $rootScope.mode = 'app';

        $scope.menuOpen = false;
        $scope.toggleMenu = () => $scope.menuOpen = !$scope.menuOpen;
        $scope.closeMenu = () => $scope.menuOpen = false;
        $scope.$watch("menuOpen", function(open, oldValue) {
            var body = $('body'),
                main = $('#main'),
                firstClick = true;

            if (open === true) {
                body.addClass("menuOpen");
                main.bind("click", function (event) {

                    if (firstClick === true) {
                        firstClick = false;
                    } else {
                        $scope.$apply(function() {
                            $scope.menuOpen = false;
                        });
                    }

                });
            } else {
                body.removeClass("menuOpen");
                main.unbind("click");
            }
        });

        $scope.bodyStyleClassLanguage = '';
        $scope.bodyStyleClassEvent = '';

        const updateLangStyle = () => $scope.bodyStyleClassLanguage = `lang-${$rootScope.language}`;
        updateLangStyle();
        $rootScope.$on("i18nextLanguageChange", () => {
            setTimeout(() => {
                updateLangStyle();
            }, 10);
        });

        eventService.onActiveEvent((eventId) => $scope.bodyStyleClassEvent = `event-${eventId}`);

        // быстрый поиск
        $scope.quickSearch = new QuickSearch($scope.go);
        // todo: только если десктоп? лимит?
        /*$scope.$watch("quickSearch.value", ((newVal, oldVal) => {
            if (!!newVal && newVal.length >= 2) {
                dataService.dataHttp<Array<Song>>('/search-song', {
                    params: {
                        'byGenre': $scope.quickSearch.searchModel.byGenre,
                        'byName': $scope.quickSearch.searchModel.byName,
                        'byAuthor': $scope.quickSearch.searchModel.byAuthor,
                        'q': newVal
                    }
                }).
                then(function (response) {
                    $scope.quickSearch.songs = playlistService.processExternalSongList(response.data);
                });
            }
        }));*/

        // фон страницы
        $scope.background = {
            _image: null, /*true/false/string*/
            style: {
                'display': 'none'
            },
            cls: {},
            image: (img) => {
                $scope.background._image = img;
                if (($scope.background._image === true || $scope.background._image === null) && !!brandingService.background) {
                    $scope.background.style = {
                        'background-image': `url(${brandingService.background})`
                    };
                } else if (typeof ($scope.background._image) === 'string') {
                    $scope.background.style = {
                        'background-image': `url(${$scope.background._image})`
                    };
                } else /*if ($scope.background._image === false) */{
                    $scope.background.style = {
                        'display': 'none'
                    };
                }

                $scope.background.cls = {
                    custom: (typeof ($scope.background._image) === 'string')
                };
            }
        };

        // обновление плейлиста
        $scope.$on('playlist:updated', function (event, songs) {
            $scope.playlist = {
                songs: songs,
            };
        });

        // хранилище плейлиста
        $scope.playlist = {
            songs: playlistService.songs,
        };

        // загрузка каталога для главного меню
        dataService.requestCatalogs().
        then(function (data) {
            $scope.catalogs = data;
        }, function () {
            modalMessageService.alert(textUtilService.localizedText("catalog.unable-to-load")); // "Невозможно загрузить данные альбомов."
            $scope.error = true;
        });

        $scope.addToPlaylistAction = function (song) {
            console.info(song);
            if (song != null) {
                playlistService.add(song);
                song.added = true;
            }
        };

        $scope.SongAction = SongAction;
        $scope.actionAvailable = function (action: SongAction) {
            // return BrandingService.plusAvailable || BrandingService.subscriptionPlusPrice;
            // return $scope.user.paymentProfile.isActionAvailable(action);
            return authService.userData.paymentProfile.isActionAvailable(action)
        };

        $scope.subscribeMain = () => {
            authService.submitActionDefault(new SubMainContext());
        };
    }
}

