export enum BattleStates {
    BEGIN = 'BEGIN',
    PERF_INITIATOR_UPLOADED = 'PERF_INITIATOR_UPLOADED',
    PERF_INITIATOR_SCORED = 'PERF_INITIATOR_SCORED',
    OPPONENT_CHOOSE = 'OPPONENT_CHOOSE',
    OPPONENT_INVITE_AWAIT = 'OPPONENT_INVITE_AWAIT',
    OPPONENT_INVITE_APPROVE = 'OPPONENT_INVITE_APPROVE',
    OPPONENT_INVITE_TIMEOUT = 'OPPONENT_INVITE_TIMEOUT',
    PERF_OPPONENT_PRECONDITIONS = 'PERF_OPPONENT_PRECONDITIONS',
    PERF_OPPONENT_MODE_CHOOSE = 'PERF_OPPONENT_MODE_CHOOSE',
    PERF_OPPONENT_MODE_SAME_TRACK = 'PERF_OPPONENT_MODE_SAME_TRACK',
    PERF_OPPONENT_MODE_OWN_TRACK = 'PERF_OPPONENT_MODE_OWN_TRACK',
    PERF_OPPONENT_RECORDING = 'PERF_OPPONENT_RECORDING',
    PERF_OPPONENT_UPLOADED = 'PERF_OPPONENT_UPLOADED',
    PERF_OPPONENT_SCORED = 'PERF_OPPONENT_SCORED',
    CANCEL = 'CANCEL',
    COMPLETE = 'COMPLETE'
}

export default class Battle {
    constructor(
        public id: number,
        public status: BattleStates,
        public sameSong: boolean,
        public invite: string,
        public owner: boolean,
        public inviteTime: Date,
        public inviteRandomUser: boolean,
        public created: Date,

        public ownerName: string,
        public ownerScore: number,
        public ownerSongId: number,
        public ownerRecord: string,

        public opponentName: string,
        public opponentScore: number,
        public opponentSongId: number,
        public opponentRecord: string,
    ) {
    }
}