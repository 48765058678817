export enum SongAction {

    /**
     * Исполнение мелодии
     */
    PERFORM = 'PERFORM',

    /**
     * Исполнение с записью
     */
    PERFORM_RECORD = 'PERFORM_RECORD',

    /**
     * Режим «Батл»
     */
    PERFORM_BATTLE = 'PERFORM_BATTLE',

    /**
     * Режим «Концерт»
     */
    PERFORM_CONCERT = 'PERFORM_CONCERT'
}