export default class Catalog {

    constructor(
        public id: number,
        public source: string,
        public title: string,
        public description: string,
        public priority: number
    ) {
    }
}