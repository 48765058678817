/**
 * Стандартный плейлист с загрузкой данных по url
 */
import DataService, {CatalogContent} from "../../services/DataService";
import PlaylistService from "../../services/PlaylistService";
import TrialService from "../../services/TrialService";
import FeatureService from "../../services/FeatureService";
import TextUtilService from "../../services/TextUtilService";
import ModalMessageService from "../../services/ModalMessageService";

export default class SimplePlaylistController {

    static $inject = [
        '$scope', '$controller', '$anchorScroll', '$timeout',
        'DataService', 'PlaylistService', 'TrialService', 'FeatureService', 'TextUtilService', 'ModalMessageService',
        'source', 'playlistOptions', 'backUrl', 'sourceId'
    ];

    constructor(
        private readonly $scope,
        private readonly $controller: ng.IControllerService,
        private readonly $anchorScroll: ng.IAnchorScrollService,
        private readonly $timeout: ng.ITimeoutService,

        private readonly dataService: DataService,
        private readonly playlistService: PlaylistService,
        private readonly trialService: TrialService,
        private readonly featureService: FeatureService,
        private readonly textUtilService: TextUtilService,
        private readonly modalMessageService: ModalMessageService,

        private readonly source: string | CatalogContent,
        private readonly playlistOptions,
        private readonly backUrl: string,
        private readonly sourceId: string
    ) {

        angular.extend(this, $controller('BasePlaylistController', {
            $scope: $scope,
            playlistOptions: playlistOptions,
            songs: null,
            sourceId: sourceId
        }));

        $scope.pageLoading = false;

        $scope.backurl = backUrl;

        // pagination
        $scope.itemsPerPage = this.featureService.isSearchBot() ? 4096 : 50; // для бота отдаём максимум песен
        // $scope.itemsPerPage = 2;
        $scope.currentPage = 0;
        $scope.total = null; // изначально неизвестен

        /**
         * Переход на предудущую страницу
         */
        $scope.prevPage = function() {
            if ($scope.currentPage > 0) {
                $timeout(function () {
                    $scope.currentPage--;
                }, 300);
            }
        };

        /**
         * Стиль для кнопки перехода на предудущую страницу 
         * @returns {String}
         */
        $scope.prevPageDisabled = function() {
            return $scope.currentPage === 0 ? "disabled" : "";
        };

        /**
         * Переход на следующую страницу
         */
        $scope.nextPage = function() {
            if ($scope.currentPage < $scope.pageCount() - 1) {
                $timeout(function () {
                    $scope.currentPage++;
                }, 300);
            }
        };

        /**
         * Стиль для кнопки перехода на следующую страницу 
         * @returns {string}
         */
        $scope.nextPageDisabled = function() {
            return $scope.currentPage === $scope.pageCount() - 1 ? "disabled" : "";
        };

        /**
         * Общее количество страниц
         */
        $scope.pageCount = function() {
            return $scope.total ? Math.ceil($scope.total / $scope.itemsPerPage) : 0;
        };

        /**
         * Наблюдение за номером текущей страницы и подгрузка соответствующих данных
         */
        $scope.$watch("currentPage", (newValue, oldValue) => {
            //$scope.pagedItems =Item.get(newValue*$scope.itemsPerPage, $scope.itemsPerPage);
            //$scope.total = Item.total();
            if (typeof this.source === "string") {
                this.loadUrl(this.source, newValue);
            } else if (this.source instanceof CatalogContent) {
                this.loadContent(this.source)
            } else {
                console.info(this.source);
                throw Error("unexpected source: " + JSON.stringify(this.source));
            }

        });

        /**
         * Переход к указанной странице 
         * @param n номер страницы
         */
        $scope.setPage = function(n: number) {
            $timeout(() => $scope.currentPage = n, 300);
        };

        /**
         * Массив страниц, доступных в данный момент для перехода
         * @returns {Array}
         */
        $scope.range = function() {
            let rangeSize = 5;
            let ret = [];

            const total = $scope.pageCount() - 1;
            const currentPage = $scope.currentPage;
            rangeSize = Math.min(rangeSize, $scope.pageCount());
            const halfRange = Math.floor(rangeSize / 2);

            let start;

            if (currentPage > (total - halfRange - 1)) {
                start = total - rangeSize + 1;
            } else if (currentPage <= halfRange) {
                start = 0;
            } else {
                start = currentPage - halfRange;
            }

            for (let i = start; i < (start + rangeSize); i++) {
                if (i >= 0) {
                    ret.push(i);
                }
            }

            return ret;
        };

        // загружаем первую страницу
        // load(0); // todo: не нужно?
    }

    private loadContent(data: CatalogContent) {
        const catalog = data.catalog;
        if (catalog != null) {
            this.$scope.playlistOptions.title = catalog.title;

            // this.$scope.trial.terms = this.trialService.describe(catalog.source);

            // загружаем изображение более высокого разрешения

            if (catalog.id > 0 && !!this.$scope.$parent) { // отритцательные id - наши витруальные каталоги
                this.$scope.$parent.background.image(this.$scope.catalogImageUrl(catalog.id, 'large'));
            }
        }
        
        this.$scope.songs = this.playlistService.processExternalSongList(data.songs);
        this.$anchorScroll('top');
    }

    /**
     * Подгрузка песен по номеру страницы
     */
    public loadUrl(sourceUrl: string, pageNum: number) {

        const offset = (pageNum * this.$scope.itemsPerPage);
        const total = ((pageNum + 1 ) * this.$scope.itemsPerPage);

        this.$scope.pageLoading = true;

        // список песен в каталоге
        this.dataService.dataPlaylist(sourceUrl, {
            //url: url,
            params: {
                offset: offset,
                total: total
            }
        }).then((response: angular.IHttpResponse<CatalogContent>) => {

            this.loadContent(response.data)

            // всего песен...
            const total = response.data.catalogSize;
            if (total != null) {
                this.$scope.total = total;
            }

            this.$scope.pageLoading = false;

        }, () => {
            this.modalMessageService.alert(this.textUtilService.localizedText("performance.error.song")); // "Невозможно загрузить данные песен."
            this.$scope.error = true;
            this.$scope.pageLoading = false;
        });
    }
}
