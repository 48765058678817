import './PlayerSongTitleComponent.less';

export default {
    transclude: false,
    bindings: {
        song: "<"
    },
    template: `
<div class="media control-block">
    <div class="media-left">
        <img class="cover" data-ng-src="{{($ctrl.song && $ctrl.song.artists[0]) ? $ctrl.authorImageUrl($ctrl.song.artists[0].id) : ''}}"/>
    </div>
    <div class="media-body">
        <div class="artist">{{$ctrl.song.artists[0].name}}</div>
        <div class="song">{{$ctrl.song.title}}</div>
    </div>
</div>`,
    controller: PlayerSongTitleComponentController
};

function PlayerSongTitleComponentController($scope) {

    this.$onInit = function () {
        // todo: выделить в сервис
        $scope.$ctrl.authorImageUrl = $scope.$root.authorImageUrl;
    };
}

PlayerSongTitleComponentController.$inject = ["$scope"];