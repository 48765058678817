import './PlayerSongProgressComponent.less';
import {Component} from "../../../general/decorators";
import {TimeLine} from "../model/PlayerModel";

@Component({
    transclude: false,
    controllerAs: '$ctrl',
    bindings: {
        timeLine: "<",
        noTime: "<",
        handleRewind: "<"
    },
    template: `
    <div class="player-song-progress" data-ng-class="$ctrl.rewinderable() ? 'rewinderable' : ''">
        <div class="progress" 
            data-ng-mouseenter="$ctrl.showRewinder($event)"
            data-ng-mouseleave="$ctrl.showRewinder(null)"
            data-ng-mousemove="$ctrl.showRewinder($event)"
            data-ng-click="$ctrl.clickRewinder($event)"
        >
            <div class="progress-bar" role="progressbar"
                 ng-style="{ 'width': $ctrl.timeLine.progress + '%' }"
                 aria-valuenow="{{$ctrl.timeLine.progress}}" aria-valuemin="0" aria-valuemax="100">
                <span class="sr-only">{{$ctrl.timeLine.progress}}%</span>
            </div>
            <div class="rewinder" data-ng-style="{left: ($ctrl.rewinderLeft)}" data-ng-if="$ctrl.noTime"></div>
        </div>
    
        <div ng-hide="$ctrl.noTime" class="progress-begin">{{$ctrl.timeLine.current}}</div>
        <div ng-hide="$ctrl.noTime" class="progress-end">{{$ctrl.timeLine.end}}</div>
    </div>
`
})
export default class PlayerSongProgressComponent {

    private readonly $: JQueryStatic;

    public timeLine: TimeLine;
    public noTime: boolean;
    public handleRewind: (progress: number) => void;

    public rewinderLeft: string;

    constructor() {
        this.$ = (window as any).jQuery || (window as any).$;
    }

    public rewinderable() {
        return true;
    }

    public showRewinder(mouseEvent: MouseEvent = null) {
        if (this.rewinderable()) {
            if (!mouseEvent) {
                this.rewinderLeft = -10 + 'px';
            } else {
                const parentArray = this.$(mouseEvent.target).parents('.player-song-progress');
                if (parentArray.length > 0) {
                    const parent: JQuery<HTMLElement> = this.$(parentArray[0]) as JQuery<HTMLElement>;
                    const posX = mouseEvent.pageX - $(parent).offset().left;
                    this.rewinderLeft = posX + 'px';
                }
            }

        }
    }

    public clickRewinder(clickEvent: MouseEvent) {
        if (this.rewinderable()) {
            const parentArray = this.$(clickEvent.target).parents('.player-song-progress');
            if (parentArray.length > 0) {
                const parent: JQuery<HTMLElement> = this.$(parentArray[0]) as JQuery<HTMLElement>;
                const width = parent.width();
                const posX = clickEvent.pageX - $(parent).offset().left;

                const newTime = this.timeLine.duration / width * posX;
                console.info("newTime " + newTime);
                // this.engine.setCurrentTime(Math.max(0, newTime));

                if (!!this.handleRewind) {
                    console.info(this.handleRewind);
                    this.handleRewind(newTime);
                }
            }
        }

        clickEvent.stopPropagation();
        clickEvent.preventDefault();
        return false;
    }

}