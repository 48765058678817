import template from "./SongItemTemplate.html";
import "./SongItemComponent.less";
import {Component} from "../../../../general/decorators";
import Song from "../../../entity/Song";
import {karaoke} from "../../../karaoke";
import IRootScopeService = karaoke.IRootScopeService;
import BrandingService from "../../../../../branding/BrandingService";
// import template from "./PlayItemTemplate.html";

@Component({
    controllerAs: '$ctrl',
    transclude: false,
    bindings: {
        song: "<",
        playAction: "&",
        playlistAction: "&",
        hasPlaylistAction: "<"
    },
    template: template
})
export default class SongItemComponent {

    static $inject = ['$rootScope', 'BrandingService'];

    public song: Song;
    public playAction;
    public playlistAction;
    public hasPlaylistAction: boolean;

    constructor(
        private readonly $rootScope: IRootScopeService,
        private readonly brandingService: BrandingService
    ) {
    }

// song in $ctrl.songs"
    // song="song" play-action="$ctrl.playAction(song.id, $ctrl.entirePlaylist)"
    // playlist-action="$ctrl.addToPlaylistAction(song.id)"
    // has-playlist-action="$ctrl.user.paid && !$ctrl.hidePlaylist


    public hasCatalog(): boolean {
        const enabled = this.brandingService.operator === 'MegaFon';
        return enabled && !!this.song && !!this.song.catalog && this.song.catalog.length > 0;
    }

    public iconUrl() {
        return this.hasCatalog() ? this.$rootScope.catalogImageUrl(this.song.catalog[0].id, 'small') : null;
    }

}