// import "./SearchComponent.less";
import template from "./SearchTemplate.html";
import UserProfile from "../../services/entity/UserProfile";

export default {
    transclude: false,
    bindings: {
        value: "=",
        submit: "<",
        close: "<",
        clear: "<",
    },
    template: template,
    controller: SearchComponentController
};

function SearchComponentController($scope, $rootScope) {

    this.$onInit = () => {
        $scope.doSubmit = () => $scope.$ctrl.submit($scope.$ctrl.value);

        if (!!$scope.$ctrl.close) {
            $scope.doClose = ($event) => {
                $event.stopPropagation();
                $scope.$ctrl.close();
            };
        }


        $scope.$watch("$ctrl.value", (text) => {
            if (!$scope.$ctrl.close) {
                if (text == "") {
                    $scope.doClear = null;
                } else {
                    if (($rootScope.operator === "T2" || $rootScope.operator === "Tele2") && $scope.$ctrl.clear) {
                        $scope.doClear = ($event) => {
                            $event.stopPropagation();
                            $scope.$ctrl.clear();
                        };
                    }
                }
            }
        });
    };
}

SearchComponentController.$inject = ["$scope", "$rootScope"];