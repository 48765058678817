export enum BasicAuthResult {
    /**
     * Пользователь есть, подписан, оплачено (если задан пароль в запросе то он правильный)
     */
    OK,

    /**
     * Пользователь есть, но пароль не верный
     */
    WRONG_PASSWORD,

    /**
     * Пользователь есть, подписан, не оплачено
     */
    UNPAYED,

    /**
     * Пользователь есть, не подписан
     */
    UNSUBSCRIBED,

    /**
     * Пользователя нет в базе
     * 406 - не по доке, в ней этот код не описан. Фактически выдаётся при неизвестном системе номере телефона
     */
    NO_USER,

    /**
     * Любая другая ошибка
     */
    ERROR,

    /**
     * Внутренняя ошибка системы
     */
    INTERNAL_ERROR,

    /**
     * Коммуникационная ошибка
     */
    COMMUNICATION_ERROR
}