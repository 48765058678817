/**
 * Базовая инициализация караоке-приложений
 * @param $rootScope {IRootScopeService}
 * @param $compile
 * @param $location
 * @param $route
 * @param $timeout
 * @param $anchorScroll
 * @param BrandingService
 * @param DataService
 */
import Song from "../karaoke/entity/Song";
import {ExecutionContext, SongContext, TrialTransaction} from "../karaoke/services/entity/ActionContext";
import {SongAction} from "../karaoke/services/entity/SongAction";
import ModalMessageService from "../karaoke/services/ModalMessageService";
import BrandingService from "../../branding/BrandingService";
import DataService from "../karaoke/services/DataService";
import TextUtilService from "../karaoke/services/TextUtilService";

export default function karaokeRun(
    $rootScope,
    $compile,
    $location,
    $route,
    $timeout,
    $anchorScroll,
    brandingService: BrandingService,
    dataService: DataService,
    textUtilService: TextUtilService,
    modalMessageService: ModalMessageService
) {
    $rootScope.operator = brandingService.operator;
    $rootScope.brandingService = brandingService;

    $rootScope.showTerms = !brandingService.terms ? null : () => {
        const template = angular.element(brandingService.terms());
        const linkFn = $compile(template);
        const html = linkFn($rootScope);
        modalMessageService.dialogExt({
            message: html,
            className: 'large document',
            buttons: {
                ok: {
                    label: textUtilService.localizedText("core.close"),
                    className: "btn-success"
                }
            }
        });

        // bootbox.alert(BrandingService.terms());
    };

    // функция перехода по ссылке пути, добавляется задержка, отсутствующая при использвоании ng-touch
    $rootScope.go = function (path) {
        $timeout(() => {
            if (path === $location.path() && path === '/jukebox') {
                $route.reload();
            } else {
                $location.path(path);
            }
        }, 300);
    };

    $rootScope.authorImageUrl = function(id) {
        //return DataService.dataUrl('/author/' + id + '.jpeg');
        return dataService.dataUrl(`/author/${id}-200.jpeg`);
    };

    $rootScope.catalogImageUrl = function(id, size = 'small') {
        if (id > 0) {
            return dataService.dataUrl(`/catalog/${id}-${size}.jpeg`);
        } else {
            return dataService.dataUrl(`/default-catalog.jpeg`);
        }
    };

    /**
     *
     * @param song {Song}
     * @returns {string}
     */
    $rootScope.songImageUrl = function(song) {
        //return DataService.dataUrl('/author/' + id + '.jpeg');
        // return $rootScope.authorImageUrl(song.artists[0].id);
        const art = song.artists[0];
        const cat = song.catalog[0];
        if (!!cat) {
            return $rootScope.catalogImageUrl(cat.id);
        } else {
            return $rootScope.authorImageUrl(art.id);
        }
    };

    $rootScope.isViewLoading = false;
    $rootScope.$on("$locationChangeStart", function () {
        $rootScope.isViewLoading = true;
    });
    $rootScope.$on("$routeChangeSuccess", function () {
        $rootScope.isViewLoading = false;
        $anchorScroll();
    });
}

karaokeRun.$inject = [
    "$rootScope", "$compile", "$location", "$route", "$timeout", "$anchorScroll",
    "BrandingService", "DataService", "TextUtilService", "ModalMessageService"
];