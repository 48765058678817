import TextUtilService from '../application/karaoke/services/TextUtilService';
import {Language} from "./Language";
import {SongAction} from "../application/karaoke/services/entity/SongAction";
import StorageService from "../application/karaoke/services/StorageService";

export type CountersDict = { [key: string]: number };

export class Counters {

    /**
     * allowed free songs by vendor
     */
    public songs: CountersDict;

    constructor(songs: CountersDict) {
        this.songs = songs;
    }
}

export enum Currency {
    RUB = 'rub',
    UZS = 'uzs',
    TJS = 'tjs'
}

export enum TarifficationPeriod {
    DAY = 'day',
    DAY_H24 = 'day-h24',
    WEEK = 'week',
    MONTH = 'month'
}

export class Price {
    constructor(
        public readonly amount: Number,
        public readonly currency: Currency,
        public readonly tarifficationPeriod: TarifficationPeriod | null = null,
    ) {
    }
}

export interface TrialSettings {
    load(storageService: StorageService): void;
    isTrialAllowed(source:string): boolean;
    onBeginAction(source: string, storageService: StorageService): void;
    onSubmitAction(source: string, storageService: StorageService): void;
    onRollbackAction(source: string, storageService: StorageService): void;
    renderText(singleSource: string, utilService: TextUtilService): string;
}

const plusActionsCandidates = [SongAction.PERFORM_BATTLE, SongAction.PERFORM_CONCERT];

/**
 * Сервис, отвечающий за основные внешние отличия для различных операторов.
 */
export default class BrandingService {

    private readonly _operator: string;
    private readonly _serviceNames: ILangTpProp<string>;
    private readonly _servicePlusNames: ILangTpProp<string>;
    private readonly _trafficPrice: Price | null;
    private readonly _subscriptionPrice: Price | string;
    private readonly _subscriptionPlusPrice: Price | string | null;
    private readonly _singleTrackPrice: Price | string | null;
    private readonly _terms: string;
    private readonly _authForm: string;
    private readonly _background: string;
    private readonly _lang: Array<Language>;
    private readonly _plusAvailable: boolean;
    private readonly _coreActions: Array<SongAction>;
    private readonly _plusActions: Array<SongAction>;
    private readonly _trialSettings: TrialSettings;

    constructor(
        operator: string,
        serviceNames: ILangTpProp<string>,
        servicePlusNames: ILangTpProp<string | null>,
        trafficPrice: Price | null,
        subscriptionPrice: Price | string,
        subscriptionPlusPrice: Price | string | null,
        singleTrackPrice: Price | string | null,
        terms: string,
        authForm: string,
        background: string,
        lang: Array<Language>,
        plusAvailable: boolean,
        coreActions: Array<SongAction>,
        plusActions: Array<SongAction>,
        trialSettings: TrialSettings,
        public readonly plusIncludesRegular: boolean = false
    ) {

        if (!operator) {
            throw new Error("Operator can not be empty");
        } else if (!serviceNames) {
            throw new Error("ServiceName can not be empty");
        } else if (!subscriptionPrice) {
            throw new Error("SubscriptionPrice can not be empty");
        }

        this._operator = operator;
        this._serviceNames = serviceNames;
        this._servicePlusNames = servicePlusNames;
        this._trafficPrice = trafficPrice;
        this._subscriptionPrice = subscriptionPrice;
        this._subscriptionPlusPrice = subscriptionPlusPrice;
        this._singleTrackPrice = singleTrackPrice;
        this._terms = terms;
        this._authForm = authForm;
        this._background = background;
        this._lang = lang;
        this._plusAvailable = plusAvailable;
        this._coreActions = coreActions;
        this._plusActions = plusActions;
        this._trialSettings = trialSettings;
    }

    /**
     * Получение текущего оператора
     * @return {string}
     */
    get operator() : string {
        return this._operator;
    }

    /**
     * Название сервиса
     * @return {string}
     */
    public serviceName(lang: Language) : string {
        return this._serviceNames[lang];
    }

    public servicePlusName(lang: Language) : string | null {
        return !!this._servicePlusNames ? this._servicePlusNames[lang] : null;
    }

    /**
     * Текстовое представление стоимости трафика
     */
    public trafficText(textUtilService: TextUtilService): () => (string | null) {
        const raw = this._trafficPrice;
        const priceText: string | null = raw === null ? null :
            raw instanceof Price ? textUtilService.renderPrice(raw) : raw;
        const fullText: string | null = priceText === null ? null :
            textUtilService.localizedText("terms.price.traffic", {price: priceText});
        return () => fullText;
    }

    /**
     * Стоимость сервиса
     * @return {string}
     */
    public subscriptionPrice(textUtilService: TextUtilService): string {
        const raw = this._subscriptionPrice;
        return raw === null ? null : raw instanceof Price ? textUtilService.renderPrice(raw) : raw;
    }

    /**
     * Стоимость plus-севриса
     */
    public subscriptionPlusPrice(textUtilService: TextUtilService): string {
        const raw = this._subscriptionPlusPrice;
        return raw === null ? null : raw instanceof Price ? textUtilService.renderPrice(raw) : raw;
    }

    /**
     * Стоимость одного трека
     * @return {string}
     */
    public singleTrackPrice(textUtilService: TextUtilService): string {
        const raw = this._singleTrackPrice;
        return raw === null ? null : raw instanceof Price ? textUtilService.renderPrice(raw) : raw;
    }

    /**
     * Шаблон правил подписки
     * @return {function: string}
     */
    get terms() : () => string | null{
        return !!this._terms ? (() => this._terms) : null;
    }

    /**
     * Форма для basic-авторизации.
     * Должна содержать input[name='login'], input[name='password'] и %MESSAGE%, заменяемый на текст ошибки/оповещения
     */
    get authForm() : () => string | null {
        return !!this._authForm ? (() => this._authForm) : null;
    }

    /**
     * Фон по-умолчанию
     * @returns {string}
     */
    get background() {
        return this._background;
    }

    get availableLanguages() {
        return this._lang;
    }

    get trialSettings() {
        return this._trialSettings;
    }

    get plusAvailable() {
        return this._plusAvailable;
    }

    get coreActions(): Array<SongAction> {
        return this._coreActions;
    }

    get plusActions(): Array<SongAction> {
        return this._plusActions;
    }

    public available(action: SongAction): boolean {
        return this._coreActions.indexOf(action) !== -1 || (this._plusAvailable && this._plusActions.indexOf(action) !== -1);
    }

// operator: () => 'Okg';
    // serviceName: () => 'Караоке';
    // trafficText: () => true;
    // subscriptionPrice: () => '5 сомов в сутки';
    // singleTrackPrice: () => '1 рубль';
    // terms: () => require('./page-terms.html');
    // authForm: () => require('./page-auth.html');

    static create(operator: string): BrandingServiceBuilder {
        return new BrandingServiceBuilder(operator);
    }
}

export function renderTextImpl(apiDic: CountersDict, util: TextUtilService, fragmentProcessor: (provider: string, fragment: string) => string): string {
    if (!apiDic || Object.keys(apiDic).length === 0) {
        return null;
    } else {
        let acc = "";
        const keys = Object.keys(apiDic);
        for (let i = 0; i < keys.length; i++) {
            const count: number = apiDic[keys[i]];
            let fragment = util.localizedText("disclaimer.song", {count: count, interpolation: {escapeValue: false}});
            fragment = fragmentProcessor(keys[i], fragment);
            acc = i === 0 ? fragment : util.localizedText("disclaimer.concat", {a: acc, b: fragment, interpolation: {escapeValue: false}});
        }
        return util.localizedText("disclaimer.free", {content: acc, interpolation: {escapeValue: false}});
    }
}

export abstract class ProviderBasedTrialSettings implements TrialSettings {

    protected readonly storageKey = "trial";

    protected _counters: Counters;

    get counters(): Counters {
        return this._counters;
    }

    set counters(value: Counters) {
        this._counters = value;
    }

    isTrialAllowed(source: string): boolean {
        const keys = Object.keys(this._counters.songs);
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] === source) {
                return this._counters.songs[keys[i]] > 0;
            }
        }
        return false;
    }

    public dict(singleSource: string = null): CountersDict {
        let result = {};
        const keys = Object.keys(this.counters.songs);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const count = this.counters.songs[key];
            if (count > 0 && (!singleSource || singleSource === key)) {
                result[key] = count;
            }
        }
        return result;
    };

    load(storageService: StorageService): void {
        this._counters = storageService.get(this.storageKey, this._counters);
    }

    onBeginAction(source: string, storageService: StorageService): void {
        this._counters.songs[source]--;
    }

    onSubmitAction(source: string, storageService: StorageService): void {
        storageService.set(this.storageKey, this._counters, 1)
    }

    onRollbackAction(source: string, storageService: StorageService): void {
        this._counters.songs[source]++
    }

    abstract renderText(singleSource: string, utilService: TextUtilService): string;
}

export class Tele2TrialSettings extends ProviderBasedTrialSettings {

    constructor() {
        super()
        this._counters = new Counters({
            "API_KARAOKE_RU": 1
            // "API_OKS": 10
        });
    }

    renderText(singleSource: string, util: TextUtilService): string {
        const apiDic = this.dict(singleSource)
        return renderTextImpl(apiDic, util, (provider: string, fragment: string) => {
            switch (provider) {
                case 'API_KARAOKE_RU':
                    // fragment = util.localizedText("disclaimer.section-main", {songs: fragment, interpolation: {escapeValue: false}})
                    return fragment + " из основного каталога";
                case 'API_OKS':
                    const section = 'Поем дома';
                    return util.localizedText("disclaimer.section", {songs: fragment, album: section, interpolation: {escapeValue: false}})
                default:
                    return fragment;
            }
        })
    }
}

export class OksOnlyTrialSettings extends ProviderBasedTrialSettings {

    constructor() {
        super()
        this._counters = new Counters({
            "API_OKS": 1
        });
    }

    renderText(singleSource: string, util: TextUtilService): string {
        const apiDic = this.dict(singleSource)
        return renderTextImpl(apiDic, util, ((provider, fragment) => fragment));
    }
}

export class NoTrialSettings extends Tele2TrialSettings {
    constructor() {
        super()
        this._counters = new Counters({
            // "API_KARAOKE_RU": 1
            // "API_OKS": 10
        });
    }
}

// type ILangTpProp<R> = {[key in keyof typeof Language]: R };
type ILangTpProp<R> = Record<Language, R>;

export class BrandingServiceBuilder {

    private _operator: string;
    private _serviceNames: ILangTpProp<string>;
    private _servicePlusNames: ILangTpProp<string | null>;
    private _trafficPrice: Price | null;
    private _subscriptionPrice: Price | string;
    private _subscriptionPlusPrice: Price | string | null;
    private _singleTrackPrice: Price | string | null;
    private _terms: string;
    private _authForm: string;
    private _background: string;
    private _lang: Array<Language>;
    private _plusAvailable: boolean;
    private _coreActions: Array<SongAction>;
    private _plusActions: Array<SongAction>;
    private _trialSettings: TrialSettings;

    constructor(operator) {
        this._operator = operator;
        this._serviceNames = {
            RU: 'Мобильное Караоке',
            EN: 'Mobile karaoke',
            UZ: 'Mobil karaoke',
            RAW: "service name"
        };
        this._serviceNames = {
            RU: 'Мобильное Караоке+',
            EN: 'Mobile karaoke+',
            UZ: 'Mobil karaoke+',
            RAW: "service+ name"
        };
        this._trafficPrice = null;
        this._subscriptionPrice = null;
        this._subscriptionPlusPrice = null;
        this._singleTrackPrice = null;
        this._terms = null;
        this._authForm = null;
        this._background = './image/backgrounds/default.jpg';
        this._lang = [Language.RU];
        this._coreActions = [SongAction.PERFORM, SongAction.PERFORM_RECORD/*, SongAction.PERFORM_BATTLE*/]
        this._plusAvailable = false;
        this._trialSettings = new OksOnlyTrialSettings();
    }

    /**
     * @return {BrandingService}
     */
    build(): BrandingService {
        return new BrandingService(
            this._operator,
            this._serviceNames,
            this._servicePlusNames,
            this._trafficPrice,
            this._subscriptionPrice,
            this._subscriptionPlusPrice,
            this._singleTrackPrice,
            this._terms,
            this._authForm,
            this._background,
            this._lang,
            this._plusAvailable,
            this._coreActions,
            this._plusActions,
            this._trialSettings
        );
    }

    /**
     * @param name
     * @return {BrandingServiceBuilder}
     */
    withName(name: string) {
        this.withNames({
            RU: name,
            EN: name,
            UZ: name,
            RAW: name
        });
        /*this.withPlusNames({
            RU: name + '+',
            EN: name + '+',
            UZ: name + '+',
            RAW: name
        });*/
        return this;
    }

    withNames(nameMap: ILangTpProp<string>) {
        this._serviceNames = nameMap;
        return this;
    }

    withCoreActions(coreActions: Array<SongAction>) {
        this._coreActions = coreActions;
        return this;
    }

    withPlusName(plusName: string) {
        return this.withPlusNames({
            RU: plusName,
            EN: plusName,
            UZ: plusName,
            RAW: plusName,
        });
    }

    withPlusNames(nameMap: ILangTpProp<string>) {
        this._servicePlusNames = nameMap;
        this._plusActions = this._coreActions.filter((value) => plusActionsCandidates.indexOf(value) !== -1)
        this._coreActions = this._coreActions.filter((value) => plusActionsCandidates.indexOf(value) === -1);
        return this;
    }

    /**
     * @param subscriptionPrice {string}
     * @return {BrandingServiceBuilder}
     */
    withSubscriptionPrice(subscriptionPrice: string | Price) {
        this._subscriptionPrice = subscriptionPrice;
        return this;
    }

    withSubscriptionPlusPrice(subscriptionPlusPrice: string | Price | null) {
        this._plusAvailable = true;
        this._subscriptionPlusPrice = subscriptionPlusPrice;
        return this;
    }

    /**
     * @param singleTrackPrice {string}
     * @return {BrandingServiceBuilder}
     */
    withSingleTrackPrice(singleTrackPrice: string | Price | null) {
        this._singleTrackPrice = singleTrackPrice;
        return this;
    }

    /**
     * @return {BrandingServiceBuilder}
     */
    withTrafficPrice(value: Price | null) {
        this._trafficPrice = value
        return this;
    }

    /**
     * @param terms
     * @return {BrandingServiceBuilder}
     */
    hasTerms(terms: string) {
        this._terms = terms;
        return this;
    }

    /**
     * @param authForm
     * @return {BrandingServiceBuilder}
     */
    hasBasicAuth(authForm: string) {
        this._authForm = authForm;
        return this;
    }

    /**
     * @param background
     * @return {BrandingServiceBuilder}
     */
    withBackground(background: string) {
        this._background = background;
        return this;
    }

    withKaraokePlusMode(subscriptionPlusPrice: string = 'n/a') {
        this._plusAvailable = true;
        this._subscriptionPlusPrice = subscriptionPlusPrice;
        return this;
    }

    withTrialSettings(trialSettings: TrialSettings) {
        this._trialSettings = trialSettings;
        return this;
    }

    withLanguages(lang: Array<Language>) {
        this._lang = lang;
        return this;
    }
}
