export default class ErrorController {

    constructor($scope, $rootScope, TextUtilService) {
        $.extend(this, $controller('SimplePageController', {$scope: $scope}));

        // 'Недостаточно прав для просмотра данной страницы.'
        $scope.message = TextUtilService.localizedText("core.denied");
        $rootScope.$on("i18nextLanguageChange", () => {
            $scope.message = TextUtilService.localizedText("core.denied");
        });

        // TextUtilService.assignLocalizedText($scope, "message", $rootScope, "core.denied", {});
    }
}

ErrorController.$inject = ['$scope', '$rootScope', 'TextUtilService'];
