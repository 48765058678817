import BrandingService from "../../../branding/BrandingService";
import {Language} from "../../../branding/Language";
import { IQService, IPromise, IDeferred } from "angular";
import Catalog from "../entity/Catalog";
import {SongContext} from "./entity/ActionContext";
import DataService, {CatalogContent, EventContent} from "./DataService";
import Song from "../entity/Song";

export type EventHandler = (eventId: string) => void

export default class EventService {

    static $inject = ['$q', 'DataService', 'BrandingService'];
    private readonly operator: string;
    private readonly _promoCatalogs: IDeferred<Array<EventContent>>;

    constructor(
        private readonly $q: IQService,
        private readonly dataService: DataService,
        private readonly brandingService: BrandingService
    ) {
        this.operator = brandingService.operator;
        this._promoCatalogs = $q.defer()

        this.dataService.requestEventsCatalogs().then(response => {
            this._promoCatalogs.resolve(response.data);
        }).catch(reason => this._promoCatalogs.reject(reason));
    }

    public onActiveEvent(handler: EventHandler): IPromise<boolean> {
        const defResult = this.$q.defer<boolean>();
        const now = new Date();
        if (!!handler) {
            this._promoCatalogs.promise.then(promos => {
                // проверка оператора идёт на бэекнде
                const active: EventContent[] = promos.filter(promo => (promo.start <= now && now <= promo.end));
                if (active.length > 0) {
                    active.forEach(event => {
                        handler(event.eventId);
                        defResult.resolve(true);
                    });
                } else {
                    defResult.resolve(false);
                }
            })
        } else {
            defResult.resolve(false);
        }
        return defResult.promise;
    }

    public promoCatalogs(): IPromise<Array<EventContent>> {
        return this._promoCatalogs.promise;
    }

    public promoData(song: Song): IPromise<Array<string>> {
        const result = this.$q.defer<Array<string>>();
        const now = new Date();
        this._promoCatalogs.promise.then(promos =>
            result.resolve(
                promos
                    .filter(promo => promo.start <= now && now <= promo.end)
                    .filter(promo => promo.songs.filter(s => s.id === song.id).length > 0)
                    .map(promo => promo.eventId)
                    // .map(promo => promo.catalog.id == -4 ? 'ny' : `${promo.catalog.id}`) // todo: different id
            )
        ).catch(reason => result.reject(reason));
        return result.promise;
    }

    public eventId(event: EventContent): string {
        return event.eventId;
        // return event.catalog.id == -4 ? 'ny': 'na';
    }

    public titleKey(event: EventContent): string {
        const eventCode = this.eventId(event);
        return `playlist.event.${eventCode}.title`;
    }
}
