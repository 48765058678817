import DataService from "./DataService";
import Song from "../entity/Song";

declare type Songs = Song[] | null;
declare type SongsId = number[] | null;

export default class SongListCompressorService {

    static $inject = ['DataService'];

    private readonly dataService: DataService;

    constructor(DataService: DataService) {
        this.dataService = DataService;
    }

    public compress(songs: Songs, resultProcessorFunc: (SongsId) => void = () => {}): SongsId {
        let result;
        if (!!songs) {
            result = new Array<Number>();

            songs.forEach(function (song) {
                if (song != null) {
                    result.push(song.id);
                }
            });
            //console.info("songs " + songs + " to " + result);
            //console.info(songs);

        } else {
            result = null;
        }

        if (resultProcessorFunc != null) {
            resultProcessorFunc(result);
        }
        
        return result;
    };

    public deCompress(songsIds: SongsId, resultProcessorFunc: (Songs) => void): void {

        if (resultProcessorFunc == null) {
            return;
        }

        if (!songsIds) {
            resultProcessorFunc(null);

        } else {
            this.dataService.songs(songsIds).then(function (response) {
                //console.info("decompress " + songsIds + " to ");
                //console.info(response.data);
                resultProcessorFunc(response.data);
            }, function () {
                //console.info("decompress " + songsIds + " failed");
                resultProcessorFunc(null);
            });
        }
    };
}