import template from "./PictureItemTemplate.html";
import "./PictureItemComponent.less";

export default {
    transclude: true,
    bindings: {
        name: "<",
        description: "<",
        action: "&",
        index: "<",
        imageUrl: "<"
    },
    template: template,
    controller: PictureItemComponent
};

function PictureItemComponent($scope) {
}

PictureItemComponent.$inject = ["$scope"];