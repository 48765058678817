import "./PerformanceResponseSelectorComponent.less";
import template from "./PerformanceResponseSelectorTemplate.html";
import {Component} from "../../../general/decorators";
import {karaoke} from "../../karaoke";
import AuthService from "../../services/AuthService";
import TrialService from "../../services/TrialService";
import TextUtilService from "../../services/TextUtilService";
import { ICompileService } from "angular";
import Battle from "../Battle";

@Component({
    controllerAs: '$ctrl',
    bindings: {
        end: '<',
        availableBattles: '<',
        selectedResponse: '<',
        onClear: '&',
        onSelect: '&'
    },
    template: template
})
export default class PerformanceResponseSelectorComponent implements ng.IOnInit {

    static $inject = ['$rootScope','$scope', '$sce', '$compile', 'AuthService', 'TrialService', 'TextUtilService'];

    public end: boolean;
    public availableBattles: Array<Battle>;
    public selectedResponse: Battle | null;
    public onClear: () => void;
    public onSelect: (battle: Battle) => void;

    constructor(
        private readonly $rootScope: karaoke.IRootScopeService,
        private readonly $scope: any,
        private readonly $sce: any,
        private readonly $compile: ICompileService,
        private readonly authService: AuthService,
        private readonly trialService: TrialService,
        private readonly textUtilService: TextUtilService
    ) {
    }

    $onInit(): void {

    };
}
