import {ActionContext, ExecutionContext, SongContext, TrialTransaction} from "../services/entity/ActionContext";
import {SongAction} from "../services/entity/SongAction";
import {karaoke} from "../karaoke";
import IRootScopeService = karaoke.IRootScopeService;
import AuthService from "../services/AuthService";
import PlayerService from "../services/PlayerService";
import { IPromise } from "angular";
import TextUtilService from "../services/TextUtilService";
import Song from "../entity/Song";

export default class BaseController {

    static $inject = [
        '$scope', '$rootScope', '$route',
        'AuthService', 'PlayerService', 'TextUtilService'
    ];

    /**
     *
     * @param _$scope
     * @param _$rootScope
     * @param _$route
     * @param _authService
     * @param _playerService
     * @param _textUtilService
     */
    constructor(
        private readonly _$scope,
        private readonly _$rootScope: IRootScopeService,
        private readonly _$route: ng.route.IRouteService,
        private readonly _authService: AuthService,
        private readonly _playerService: PlayerService,
        private readonly _textUtilService: TextUtilService
    ) {
        // фон по-умолчанию
        _$scope.$parent.background.image(null);
        /*if (!$scope.$parent.background.image) {
            const background = brandingService.background;
            if (!!background) {
                $scope.$parent.background.image = background;
            }
        }*/

        // запрос текущего статуса пользователя
        this._authService.updateUserData(null);

        _$scope.authorize = () => {
            console.info("authorize");
            _authService.doAuthorize(() => this._$route.reload());
        };

        _$scope.attemptAction = (action: ActionContext): IPromise<ExecutionContext> => _authService.submitAction(action);

        _$scope.playAction = function playAction(
            song: Song,
            playlistTitleCode: string,
            backPath: string,
            songsPlaylist: Song[],
            sourceId: string
        ): void {
            //runWatchDog($rootScope, $timeout);

            const playlistTitle = _textUtilService.localizedText(playlistTitleCode);

            _playerService.load(
                !!playlistTitle ? playlistTitle : song.title,
                backPath,
                !!songsPlaylist ? songsPlaylist : [song],
                song,
                sourceId
            );

            _$scope.attemptAction(new SongContext(song, SongAction.PERFORM)).then((context: ExecutionContext) => {
                console.info(context);
                if (context instanceof TrialTransaction) {
                    if (!!context.rollbackAction) {
                        context.rollbackAction();
                    }
                    // подписались или try-доступ, переходим к плееру
                    // $location.path("/jukebox");
                }
                _$rootScope.go("/jukebox");
            });
        };


        _$scope.playPromoAction = (song: Song) => {
            const playlistBlock = (!!_$scope.user && _$scope.user.paymentProfile.subMain && _$scope.playlist.songs && _$scope.playlist.songs.length > 0);

            if (playlistBlock) {
                _$scope.playAction(song, _$scope.promoTitle, '/playlist', _$scope.playlist.songs, 'banner');
            } else {
                _$scope.playAction(song, _$scope.promoTitle, '/playlist/popular', null, 'banner');
            }
        };
    }

    /**
     * просто авторизация
     */
    // public authorize() {
    //     console.info("authorize");
    //     this.authService.doAuthorize(() => this.$route.reload());
    // }

    // public attemptAction(action: ActionContext): IPromise<ExecutionContext> {
    //     return this.authService.submitAction(action);
    // };

    // /**
    //  * Проигрывание песни
    //  *
    //  * @param song {Song}
    //  * @param playlistTitleCode {string}
    //  * @param backPath {string}
    //  * @param songsPlaylist {Song[]}
    //  * @param sourceId {string}
    //  */
    // public playAction(song, playlistTitleCode, backPath, songsPlaylist, sourceId) {
    //     //runWatchDog($rootScope, $timeout);
    //
    //     console.info("!!!!");
    //     console.info(this);
    //
    //     const playlistTitle = this.textUtilService.localizedText(playlistTitleCode);
    //
    //     this.playerService.load(
    //         !!playlistTitle ? playlistTitle : song.title,
    //         backPath,
    //         songsPlaylist ? songsPlaylist : [song],
    //         song,
    //         sourceId
    //     );
    //
    //     this.attemptAction(new SongContext(song, SongAction.PERFORM)).then((context: ExecutionContext) => {
    //         console.info(context);
    //         if (context instanceof TrialTransaction) {
    //             if (!!context.rollbackAction) {
    //                 context.rollbackAction();
    //             }
    //             // подписались или try-доступ, переходим к плееру
    //             // $location.path("/jukebox");
    //         }
    //         this.$rootScope.go("/jukebox");
    //     });
    // };
}