import "./PlayerButtonComponent.less";
import buttonTemplate from "./PlayerButtonComponentTemplate.html";
import {Component} from "../../../general/decorators";
import playerTemplate from "./KarplayerTemplate.html";

/*
export default {
    transclude: true,
    controllerAs: '$ctrl',
    bindings: {
        action: "&",
        state: "<",
        showLabel: "<"
    },
    template: buttonTemplate,
};*/

@Component({
    transclude: true,
    controllerAs: '$ctrl',
    bindings: {
        action: "&",
        state: "<",
        showLabel: "<",
    },
    template: buttonTemplate
})
export default class PlayerButtonComponent {
    public action: () => void;

    public doAction() {
        this.action();
    }
}