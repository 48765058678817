export default function AuthInterceptor($httpProvider, $provide) {
    $provide.factory('AuthInterceptor', ['$q', '$injector', function ($q, $injector) {
        return {
            // On request success
            request: function (config) {
                return config || $q.when(config);
            },

            // On request failure
            requestError: function (rejection) {
                return $q.reject(rejection);
            },

            // On response success
            response: function (response) {
                // Return the response or promise.
                return response || $q.when(response);
            },

            // On response failure
            responseError: function (rejection) {
                // Return the promise rejection.
                if (!!rejection) {
                    /**
                     * @type {AuthService}
                     */
                    const AuthService = $injector.get('AuthService');
                    AuthService.handleHttpResponseError(rejection.data, rejection.status, rejection.headers, rejection.config);
                }
                return $q.reject(rejection);
            }
        };
    }]);

    // Add the interceptor to the $httpProvider.
    $httpProvider.interceptors.push("AuthInterceptor");
}

AuthInterceptor.$inject = ["$httpProvider", "$provide"];