/**
 * Playlist новинок
 */
export default class NewPlaylistController {

    constructor($scope, $controller) {
        angular.extend(this, $controller('SimplePlaylistController', {
            $scope: $scope,
            source: '/latest.json',
            backUrl: "/",
            playlistOptions: {
                title: "playlist.new.title",
                play: true, // по-умолчанию показываем проигрывание плейлиста
                playlist: true // отображаем функцию добавления в плейлист
            },
            sourceId: 'latest'
        }));
    }
}

NewPlaylistController.$inject = ['$scope', '$controller'];