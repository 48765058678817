import Song from "../entity/Song";
import DataService from "../services/DataService";
import PlaylistService from "../services/PlaylistService";
import DecoratorService from "../services/DecoratorService";
import TextUtilService from "../services/TextUtilService";
import EventService from "../services/EventService";
import {karaoke} from "../karaoke";
import IRootScopeService = karaoke.IRootScopeService;
import SettingsService from "../services/SettingsService";
import ModalMessageService from "../services/ModalMessageService";

export default class MainPageController {

    static $inject = [
        '$scope', '$rootScope', '$controller', '$location', '$route',
        'DataService', 'PlaylistService', 'DecoratorService', 'TextUtilService', 'EventService', 'SettingsService', 'ModalMessageService'
    ];

    constructor(
        protected readonly $scope,
        protected readonly $rootScope: IRootScopeService,
        protected readonly $controller: ng.IControllerService,
        protected readonly $location: ng.ILocationService,
        protected readonly $route: ng.route.IRouteService,
        protected readonly dataService: DataService,
        protected readonly playlistService: PlaylistService,
        protected readonly decoratorService: DecoratorService,
        protected readonly textUtilService: TextUtilService,
        protected readonly eventService: EventService,
        protected readonly settingService: SettingsService,
        protected readonly modalMessageService: ModalMessageService,
    ) {
        angular.extend(this, $controller('BaseController', {$scope: $scope}));

        eventService.onActiveEvent((eventId) => {
            const targetRoute = `/event/${eventId}/main`;
            const pageExist = $route.routes.hasOwnProperty(targetRoute);
            if (pageExist && $location.path() !== targetRoute) {
                $location.path(targetRoute);
            }
        });

        $scope.pageControllerName = ($route.current as any).$$route.controller; // todo
        $scope.showBackButton = $scope.pageControllerName !== 'MainPageController'

        $scope.popularSongs = [];
        $scope.newSongs = [];
        $scope.promoPage = null;
        $scope.promoSongs = [];

        $scope.carActionPromo = (song:Song) => $scope.playPromoAction(song);
        $scope.carActionPopular = (song:Song) => $scope.playAction(song, 'Популярное', '/playlist/popular', null, 'carousel');
        $scope.carActionNew = (song:Song) => $scope.playAction(song, 'Избранное', '/playlist/new', null, 'carousel');

        // todo: to BaseController
        $scope.carTitle = (song:Song) => song.title;
        $scope.carAuthor = (song:Song) => song.artists[0].name;

        // список песен в каталоге
        dataService.requestTop().then(function (response) {
            const songs = playlistService.processExternalSongList(response.data.songs);

            $scope.popularSongs = songs;
            $scope.selectedPopularSong = $scope.popularSongs[0];

            $scope.promoPopularItems = decoratorService.promoSongsItems(songs,
                (song) => $scope.playAction(song, 'playlist.popular.title', '/playlist/popular', null, 'catalogs_list'));

        }, () => {
            this.modalMessageService.alert(textUtilService.localizedText("playlist.popular.error")); // "Невозможно загрузить популярные песни."
            $scope.error = true;
        });

        // список песен в каталоге
        dataService.requestLatest().then(function (response) {
            const songs = playlistService.processExternalSongList(response.data.songs);

            $scope.newSongs = songs;

            $scope.promoNewItems = decoratorService.promoSongsItems(songs,
                (song) => $scope.playAction(song, 'playlist.new.title', '/playlist/new', null, 'catalogs_list'));

        }, () => {
            this.modalMessageService.alert(textUtilService.localizedText("playlist.new.error")); // "Невозможно загрузить новые песни."
            $scope.error = true;
        });


        $scope.selectedPopularSong = null;
        $scope.selectPopularSong = function(song : Song | number, event: Event) {
            const target = $(event.target);
            target.parents('.promo-triple').find(".selected").removeClass('selected');
            (target.hasClass("item") ? target : target.parents('.item')).addClass('selected');
            $scope.selectedPopularSong = song;
        };

        $scope.playlistSongs = playlistService.songs;
        $scope.selectedPlaylistSong = $scope.playlistSongs[0];

        $scope.$on('playlist:updated', function (event, songs) {
            $scope.playlistSongs = songs;
            $scope.selectedPlaylistSong = $scope.playlistSongs[0];
        });

        $scope.selectPlaylistSong = function(song : Song | number, event: Event) {
            const target = $(event.target);
            target.parents('.promo-triple').find(".selected").removeClass('selected');
            (target.hasClass("item") ? target : target.parents('.item')).addClass('selected');
            $scope.selectedPlaylistSong = song;
        };

        //$scope.selectedPlaylistSong = $scope.newSongs[0];

        /*if ($rootScope.operator == 'Tele2' || $rootScope.operator == 'T2') {
            DataService.dataHttp('/top.json').
            // DataService.dataHttp('/catalog/343.json').
            then(function (response) {
                var songs = PlaylistService.processExternalSongList(response.data.songs);

                $scope.promoPage = '/playlist/343/';
                $scope.promoTitle = 'С Новым Годом!';
                $scope.promoSongs = songs;


            }, function () {
                console.warn("Невозможно загрузить промо-песни.");
                $scope.error = true;
            });
        }*/

        // $scope.promoCatalogItems = DecoratorService.promoCatalogItems();
        $scope.promoCatalogItems = decoratorService.promoCatalogContentItems(
            (song, catalog) => $scope.playAction(song, catalog.title, ('/playlist/' + catalog.id), null, 'catalogs_list'));

        $scope.isFeedbackAllowed = settingService.isFeedbackAllowed()
    }
}