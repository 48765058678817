/**
 * Playlist бесплатных
 */
export default class FreePlaylistController {

    constructor($scope, $routeParams, $controller) {
        angular.extend(this, $controller('SimplePlaylistController', {
            $scope: $scope,
            source : '/free.json',
            backUrl: "/",
            playlistOptions: {
                title: "playlist.free.title",
                play: true, // по-умолчанию показываем проигрывание плейлиста
                playlist: false // не отображаем функцию добавления в плейлист
            },
            sourceId: 'free'
        }));

        $scope.hideDisclaimer = true;
    }
}

FreePlaylistController.$inject = ['$scope', '$routeParams', '$controller'];