import { IAngularStatic, IHttpResponse } from "angular";
import DataService from "../services/DataService";
import TextUtilService from "../services/TextUtilService";
import {karaoke} from "../karaoke";
import IRootScopeService = karaoke.IRootScopeService;
import AuthService from "../services/AuthService";
import StorageService from "../services/StorageService";
import Either from "../services/entity/Either";
import ModalMessageService from "../services/ModalMessageService";

const STORAGE_PROMO_CODE = "PROMO_CODE";

export default class PromoCodeController {

    static $inject = [
        '$scope', '$rootScope', '$location', '$route', '$routeParams', '$controller',
        'AuthService', 'DataService', 'StorageService', 'TextUtilService', 'ModalMessageService'
    ];

    private readonly angular: IAngularStatic;

    constructor(
        protected readonly $scope,
        protected readonly $rootScope: IRootScopeService,
        protected readonly $location: ng.ILocationService,
        protected readonly $route: ng.route.IRouteService,
        protected readonly $routeParams: ng.route.IRouteParamsService,
        protected readonly $controller: ng.IControllerService,
        protected readonly authService: AuthService,
        protected readonly dataService: DataService,
        protected readonly storageService: StorageService,
        protected readonly textUtilService: TextUtilService,
        protected readonly modalMessageService: ModalMessageService
    ) {

        angular.extend(this, $controller('BaseController', {$scope: $scope}));

        this.angular = window.angular; // todo

        $scope.model = {}

        if (!!$routeParams.code) {
            storageService.set(STORAGE_PROMO_CODE, $routeParams.code, 1)
            $scope.model.promoCode = $routeParams.code;
        } else {
            $scope.model.promoCode = storageService.get(STORAGE_PROMO_CODE, "");
        }

        $scope.shouldAuthorize = !authService.isAuthenticated();

        $scope.active = false;
        $scope.message = null;

        $scope.$watchCollection('[model.promoCode]', function(newValues, oldValues){
            $scope.active = $scope.model.promoCode.length > 0;
        });

        this.$rootScope.$on("contextLoaded", () => {
            $scope.shouldAuthorize = !authService.isAuthenticated();
        })

        function errorPromoCodes(defaultEvent:string = null, errorCode:string = "ERROR"): Array<string> {
            let errorLocalCodes: Array<string> = new Array<string>();
            if (defaultEvent != null){
                errorLocalCodes.push("promo-code." + defaultEvent + ".error." + errorCode)
            }
            errorLocalCodes.push("promo-code.error." + errorCode)
            console.info(errorLocalCodes);
            return errorLocalCodes
        }

        $scope.doSend = () => {
            $scope.message = null;
            dataService.serviceHttp('/promo-code/activate', {
                method: 'POST',
                params: {
                    'promo-code': $scope.model.promoCode,
                    '_csrf': $scope.user.csrf
                }
            }).then((r: IHttpResponse<Either>) => {

                // todo: продумать как интегрировть несколько событий - дефолтное?
                let defaultEvent = null
                if ($rootScope.operator === "Tele2") {
                    defaultEvent = "t2-ny-2022"
                }

                if (!!r.data.right || r.data.left == 'ALREADY_ACTIVATED_BY_YOU') {
                    // success
                    const alertTitle = textUtilService.localizedTextFirst([`promo-code.${defaultEvent}.success`, "promo-code.success"])
                    modalMessageService.alert(alertTitle, () => { // "Невозможно активировать промокод."
                        $location.path("/");
                        $route.reload();
                    });
                } else {
                    $scope.message = textUtilService.localizedTextFirst(errorPromoCodes(defaultEvent, r.data.left));
                }
            }, () => {
                $scope.message = textUtilService.localizedTextFirst(errorPromoCodes()); // "Невозможно активировать промокод.";
            });
        };
    }
}

