import { IAngularStatic } from "angular";
import EventService from "../services/EventService";
import PlaylistService from "../services/PlaylistService";
import DataService from "../services/DataService";
import DecoratorService from "../services/DecoratorService";
import MainPageController from "./MainPageController";
import TextUtilService from "../services/TextUtilService";
import {karaoke} from "../karaoke";
import IRootScopeService = karaoke.IRootScopeService;
import SettingsService from "../services/SettingsService";
import Song from "../entity/Song";
import ModalMessageService from "../services/ModalMessageService";

export default class MainPromoPageController extends MainPageController {

    static $inject = [
        '$scope', '$rootScope', '$controller', '$location', '$route',
        'DataService', 'PlaylistService', 'DecoratorService', 'TextUtilService', 'EventService', 'SettingsService', 'ModalMessageService'
    ];

    private readonly angular: IAngularStatic;

    constructor(
        protected readonly $scope,
        protected readonly $rootScope: IRootScopeService,
        protected readonly $controller: ng.IControllerService,
        protected readonly $location: ng.ILocationService,
        protected readonly $route: ng.route.IRouteService,
        protected readonly dataService: DataService,
        protected readonly playlistService: PlaylistService,
        protected readonly decoratorService: DecoratorService,
        protected readonly textUtilService: TextUtilService,
        protected readonly eventService: EventService,
        protected readonly settingService: SettingsService,
        protected readonly messageService: ModalMessageService,
    ) {
        super($scope, $rootScope, $controller, $location, $route, dataService, playlistService, decoratorService, textUtilService, eventService, settingService, messageService);

        this.angular = window.angular; // todo;
        // angular.extend(this, $controller('MainPageController', {$scope: $scope}));

        eventService.promoCatalogs().then(response => {
            response.find((promo => {
                const songs = playlistService.processExternalSongList(promo.songs);

                $scope.eventTitle = songs;
                $scope.eventSongs = songs;
                $scope.carActionEventNY = (song: Song) => $scope.playAction(song, 'Новый год', '/event/ny/playlist', null, 'carousel');
                
                const eventId = eventService.eventId(promo);
                const titleKey = eventService.titleKey(promo);
                const pagePath = `/event/${eventId}/playlist`;

                $scope.promoEventItems = decoratorService.promoSongsItems(songs,
                    (song) => $scope.playAction(song, titleKey, pagePath, null, 'catalogs_list'));
            }))
        }, function () {
            //messageService.alert(TextUtilService.localizedText("playlist.popular.error")); // "Невозможно загрузить популярные песни."
            $scope.error = true;
        });

    }
}