import DataService from "../services/DataService";
import TextUtilService from "../services/TextUtilService";
import {karaoke} from "../karaoke";
import IRootScopeService = karaoke.IRootScopeService;
import ModalMessageService from "../services/ModalMessageService";
import BaseController from "./BaseController";
import AuthService from "../services/AuthService";
import PlayerService from "../services/PlayerService";

export default class FeedbackController extends BaseController {

    static $inject = [
        '$scope', '$rootScope', '$location', '$route', '$controller',
        'AuthService', 'PlayerService', 'DataService', 'TextUtilService', 'ModalMessageService'
    ];

    constructor(
        protected readonly $scope,
        private readonly $rootScope: IRootScopeService,
        protected readonly $location: ng.ILocationService,
        protected readonly $route: ng.route.IRouteService,
        protected readonly $controller: ng.IControllerService,
        protected readonly authService: AuthService,
        protected readonly playerService: PlayerService,
        protected readonly dataService: DataService,
        protected readonly textUtilService: TextUtilService,
        protected readonly modalMessageService: ModalMessageService
    ) {
        super($scope, $rootScope, $route, authService, playerService, textUtilService)

        $scope.responseText = "";

        $scope.active = false;
        $scope.message = null;

        $scope.$watchCollection('[responseText]', function(newValues, oldValues){
            $scope.active = $scope.responseText.length > 0;
        });

        $scope.doSend = () => {
            $scope.message = null;
            dataService.serviceHttp('/feedback.json', {
                method: 'POST',
                params: {
                    'responseText': $scope.responseText,
                    '_csrf': $scope.user.csrf
                }
            }).then(() => {
                modalMessageService.alert(textUtilService.localizedText("feedback.done"), () => { // "Спасибо! Ваше сообщение успешно получено."
                    $location.path("/");
                    $route.reload();
                });
            }, () => {
                $scope.message = textUtilService.localizedText("feedback.error"); // "Невозможно отправить отзыв, попробуйте позже.";
            });
        };
    }
}