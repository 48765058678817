export default class BattleController {

    constructor($scope, $route, $location, $controller, $routeParams) {
        angular.extend(this, $controller('BaseController', {$scope: $scope}));

        if ($routeParams.battleId != null) {
            /*$scope.searchModel.value = $routeParams.query;
            $scope._doSearch();*/
            // console.info('[BC] load battle ' + $routeParams.battleId);
            $scope.battleId = $routeParams.battleId;
        } else {
            // console.info('[BC] load all');
            $scope.battleId = null;
        }
    }
}

BattleController.$inject = ['$scope', '$route', '$location', '$controller', '$routeParams'];