// import {Component, Inject. OnInit} from "angular-ts-decorators";
import {Component} from "../../../general/decorators";
import "./BattleStatusComponent.less";
import {karaoke} from "../../karaoke";
import template from "./BattleStatusComponent.html";
import Battle, {BattleStates} from "../Battle";
import PerformanceService, {ErrorReason} from "../../player/pages/PerformanceService";
import Song from "../../entity/Song";
import AuthService from "../../services/AuthService";
import {ExecutionContext, ResponseBattleSongContext} from "../../services/entity/ActionContext";
import PlayerService from "../../services/PlayerService";
import UserProfile from "../../services/entity/UserProfile";

@Component({
    // selector: 'battlePage',
    controllerAs: '$ctrl',
    bindings: {
        id: '<'
    },
    template: template
})
export default class BattleStatusComponent implements ng.IOnInit/*, ng.IComponentOptions*/ {

    static $inject = ['$timeout', '$rootScope', '$route', 'PerformanceService', 'PlayerService', 'AuthService']

    public bindings: any;

    private _loading: boolean;
    private _error: boolean;

    public battle: Battle;
    private songsDic: Map<number, Song> = new Map<number, Song>();
    public id: number | null;
    private _phone: string = '';
    private _anySong: boolean = true;
    private _valid: 'ok' | 'fail' | 'unknown' | null = null;
    private invite: null | 'friend' | 'sent' = null;
    private _inviteError: string | null = null;
    private _inviteData: 'valid' | 'same' | 'none' | 'invalid' = 'invalid';
    public completeMessage: string = null;

    constructor(
        /*@Inject('$timeout')*/ private readonly $timeout: ng.ITimeoutService,
        /*@Inject('$rootScope')*/ private readonly $rootScope: karaoke.IRootScopeService,
        /*@Inject('$rootScope')*/ private readonly $route: ng.route.IRouteService,
        /*@Inject('PerformanceService')*/ private readonly performanceService : PerformanceService,
        /*@Inject('PlayerService')*/ private readonly playerService : PlayerService,
        /*@Inject('PlayerService')*/ private readonly authService : AuthService
    ) {
        /*this.bindings = {
            id: '<'
        };*/
        // this.controller = SomeComponentController;
        // this.templateUrl = 'some-component.html';
        // this.template = template;
        this._loading = true;
    }

    $onInit(): void {
        // throw new Error("Method not implemented.");
        console.info(`BattleStatusComponent inited, battleId ${this.id}`);

        const _this = this;
        const battleId = this.id;

        this.$rootScope.$on("contextLoaded", () => {
            this.performanceService.battle(battleId)
                .then((battle) => {
                    this.battle = battle;
                    this._loading = false;

                    if (this.battle !== null) {
                        if (this.battle.inviteRandomUser === true) {
                            this._inviteData = this.battle.sameSong ? "same" : "valid";
                        } else if (
                            this.battle.id == this.$rootScope.user.inviteBattleId
                            && !!this.$rootScope.user.inviteBattleId
                            && !!this.$rootScope.user.inviteBattleCode
                        ) {
                            this._inviteData = this.battle.sameSong ? "same" : "valid";
                            this.performanceService.battleVerifyCode(
                                this.$rootScope.user.inviteBattleId,
                                this.$rootScope.user.inviteBattleCode)
                                .then((valid) => {
                                    this._inviteData = valid ? this._inviteData : "invalid"
                                })
                                .catch(() => {
                                    this._inviteData = "invalid"
                                });
                        }
                    } else {
                        this._inviteData = "none";
                    }


                    console.info('-----------------------------------');
                    console.info(battle);
                    if (battle !== null) {
                        let songsIds: Set<number> = new Set<number>();
                        if (!!battle.opponentSongId) {
                            songsIds.add(battle.opponentSongId);
                        }
                        if (!!battle.ownerSongId) {
                            songsIds.add(battle.ownerSongId);
                        }
                        this.songsDic = this.performanceService.songDic(Array.from(songsIds.values()));

                        this.completeMessage = this.performanceService.battleResult(battle);
                    }
                })
                .catch((onReject) => {
                    console.warn(onReject);
                    this._error = true;
                    this._loading = false;
                    this._inviteData = "invalid";
                });
        });

        this.$rootScope.$watch("user", (user:UserProfile) => {
            if (user == null || !(user.msisdn)) {
                _this._valid = null;
            } else if (user.msisdn.length >= 11) {
                this.performanceService.verifyMsisdn(user.msisdn).then((result) => {
                    if (result === null) {
                        _this._valid = "unknown";
                    } else {
                        if (result) {
                            _this._valid = "ok"
                        } else {
                            _this._valid = "fail"
                        }
                    }

                    const battleId = this.id;
                    if (user.battles.find((b) => b.id == battleId)) {
                        this._inviteData = "valid";
                    }

                }).catch(() => {
                    _this._valid = "unknown";
                });
            } else {
                _this._valid = null;
            }
        })
    }

    public name(songId: number): string {
        const song = this.songsDic.get(songId);
        return !!song ? song.title : `[${songId}]`;
    }

    public go(path: string): void {
        this.$rootScope.go(path);
    }

    public reload(): void {
        this.$route.reload();
    }

    public status(statuses: string[], owner: boolean = null): boolean {
        return !!this.battle && statuses.indexOf(this.battle.status) > -1
            && (owner == null || this.battle.owner === owner);
    }

    public inviteRandom() {
        this.performanceService.inviteRandom(this.battle.id, !this._anySong).then((message) => {
            this._error = false;
            if (!message.error) {
                this.invite = "sent";
                this.reload();
            } else {
                this.handleInviteError(message.errorCode);
            }
        }).catch((err) => {
            this._error = true;
            console.error(err);
        })
    }

    public inviteFriend() {
        this.performanceService.invite(this.battle.id, !this._anySong, this._phone)
            .then((message) => {
                this._error = false;
                if (!message.error) {
                    this.performanceService.sms(this._phone, message.body);
                    this.invite = "friend";
                    this.reload();
                } else if (message.errorCode === ErrorReason.UNABLE_VERIFY_MSISDN) {
                    // не можем проверить msisdn - всё равно отправляем
                    this.performanceService.sms(this._phone, message.body);
                    this.invite = "friend";
                    this.reload();
                } else {
                    this.handleInviteError(message.errorCode);
                }
            })
            .catch((err) => {
                this._error = true;
                this._inviteError = ErrorReason.ILLEGAL_STATE;
                console.error(err);
            });
    }

    private handleInviteError(errorCode: ErrorReason): void {
        this._inviteError = errorCode.toString();
        /*switch (errorCode) {
            case ErrorReason.NO_USER_TO_INVITE:
                this._inviteError = "Отсутствуют кандидаты для приглашения на батл";
                break;
            case ErrorReason.FOREIGN_MSISDN:
                this._inviteError = "Некорректный оператор";
                break;
            case ErrorReason.UNABLE_VERIFY_MSISDN:
                this._inviteError = "Невозможно идентифицировать принадлежность номера телефона";
                break;
            case ErrorReason.ILLEGAL_STATE:
                this._inviteError = "Некорректное действие";
                break;
            default:
                this._inviteError = "Неизвестная ошибка";
        }*/
    }

    public sameSongForBattle(): void {
        const song = this.songsDic.get(this.battle.ownerSongId);
        if (song != null) {
            const ctx = new ResponseBattleSongContext(song, this.battle.id);
            this.authService.submitAction(ctx).then((exc: ExecutionContext) => {
                this.playerService.load('Батл', '/battle/', [ctx], ctx, 'battle');
                this.$rootScope.go("/jukebox");
            })
        }
    }

    get loading(): boolean {
        return this._loading;
    }

    get error(): boolean {
        return this._error;
    }

    get phone(): string {
        return this._phone;
    }

    set phone(value: string) {
        this._phone = value;
    }

    get anySong(): boolean {
        return this._anySong;
    }

    set anySong(value: boolean) {
        this._anySong = value;
    }

    get valid(): "ok" | "fail" | "unknown" | null {
        return this._valid;
    }

    get inviteError(): string | null {
        return this._inviteError;
    }

    set inviteError(value: string | null) {
        this._inviteError = value;
    }

    get inviteData(): "valid" | "same" | "none" | "invalid" {
        return this._inviteData;
    }
}