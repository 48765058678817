import {TrialSettings} from "../../BrandingService";
import TextUtilService from "../../../application/karaoke/services/TextUtilService";
import StorageService from "../../../application/karaoke/services/StorageService";

const LIMIT = 10;

export default class EventNY2022Trial implements TrialSettings {

    protected readonly storageKey = "trial.t2-ny-2022";
    private _counter = {
        cnt: LIMIT
    };

    constructor() {
    }

    renderText(singleSource: String, util: TextUtilService): string {
        const fragment = util.localizedText("disclaimer.song", {count: this._counter.cnt, interpolation: {escapeValue: false}});
        const defaultText = util.localizedText("disclaimer.free", {content: fragment, interpolation: {escapeValue: false}});
        if (!defaultText || this._counter.cnt == 0) {
            return "Вы поёте прекрасно и много! Лимит в " + LIMIT + " песен на сегодня исчерпан!";
        } else {
            return defaultText;
        }
    }

    isTrialAllowed(source: string): boolean {
        return this._counter.cnt > 0;
    }

    load(storageService: StorageService): void {
        this._counter = storageService.get(this.storageKey, this._counter);
    }

    onBeginAction(source: string, storageService: StorageService): void {
        this._counter.cnt--;
    }

    onSubmitAction(source: string, storageService: StorageService): void {
        storageService.set(this.storageKey, this._counter, 1) // todo: 24h -> 1 cal day
    }

    onRollbackAction(source: string, storageService: StorageService): void {
        this._counter.cnt++
    }

}