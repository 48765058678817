// import recordrtc from  "recordrtc/RecordRTC";
// import RecordRTC from "recordrtc";
import * as RecordRTC from "recordrtc";
// import(/* webpackPrefetch: true */ 'recordrtc');

import "./RecorderComponent.less";
import {Component} from "../../../general/decorators";
import {karaoke} from "../../karaoke";
import IScope = karaoke.IScope;
import DataService from "../../services/DataService";
import TextUtilService from "../../services/TextUtilService";
import Song from "../../entity/Song";
import SettingsService from "../../services/SettingsService";
import { IOnChangesObject } from "angular";

@Component({
    transclude: true,
    controllerAs: '$ctrl',
    bindings: {
        song: "<",
        enabled: "<",
        onRecordUnavailable: '&',
        onPermissionDenied: '&',
        onRecordReady: '&',
        onRecordComplete: '&'
    },
})
export default class RecorderComponentController implements ng.IOnInit, ng.IOnDestroy, ng.IOnChanges {

    static $inject = ['$element', '$scope', '$rootScope', '$window', '$q', 'SettingsService'];

    private song: Song;
    private enabled: boolean;
    private onRecordUnavailable: () => void;
    private onPermissionDenied: (error: any) => void;
    // private onRecordReady: (deferred: ng.IDeferred<boolean>) => void;
    private onRecordReady: (data: any) => void;
    // private onRecordComplete: (data: FormData | Blob | any, songId: number, eventId: number, onSuccess: () => void) => void;
    private onRecordComplete: (data: any) => void;

    private recorder: RecordRTC = null;
    private playerState: string;
    private songId: number
    private saveData: boolean = false;

    constructor(
        public readonly $element: JQuery,
        public readonly $scope: IScope,
        public readonly $rootScope: karaoke.IRootScopeService,
        public readonly $window: ng.IWindowService,
        public readonly $q: ng.IQService,
        public readonly settingsService: SettingsService
    ) {
    }

    private initRecording(): void {
        if (!!this.recorder) {
            console.info("[RC] init - no recorder" );
            return;
        }
        navigator.mediaDevices.getUserMedia({
            video: false,
            audio: true
        }).then(stream => {
            // _this.stream = stream;
            // _this.recorder = RecordRTC(stream, {
            //     type: 'audio/webm'// audio/wav
            // });
            const recordRTC = (RecordRTC as any);
            const StereoAudioRecorder = recordRTC.StereoAudioRecorder;
            // const MediaStreamRecorder = RecordRTC.MediaStreamRecorder;
            // const WhammyRecorder = RecordRTC.WhammyRecorder;

            this.recorder = recordRTC.default(stream, {
                type: 'audio',
                recorderType: StereoAudioRecorder,

                mimeType: 'audio/x-wav',
                // mimeType: 'audio/wav',
                // mimeType: 'audio/ogg',
                // mimeType: 'audio/webm;codecs=pcm',

                // used by StereoAudioRecorder - the range 22050 to 96000.
                // sampleRate: 48000 /*96000*/,

                // used by StereoAudioRecorder - let us force 16khz recording:
                // desiredSampRate: 16000,

                // used by StereoAudioRecorder - Legal values are (256, 512, 1024, 2048, 4096, 8192, 16384).
                // bufferSize: 8192 /*16384*/,

                disableLogs: false,

                // used by StereoAudioRecorder - 1 or 2
                numberOfAudioChannels: 1,

                // recorderType: MediaStreamRecorder
                // timeSlice: 1000,
                /*ondataavailable: (blob: Blob) => {
                    console.info("ondataavailable2 = " + blob);
                    this.recordBlob = blob
                }*/
            });
            // _this.recorder.startRecording();
            // _this.recorder.pauseRecording();

            this.$scope.$on('player:initPlayerData', function (event, playerData) {
                console.info('[RC] initPlayerData event');
            });

            this.$scope.$on('player:state', (event, playerState: string) => {
                const oldState = this.playerState;
                const newState = playerState;

                console.info("[RC] got player:state " + playerState);

                if (!!this.song && !!this.song.id) {
                    this.songId = this.song.id;
                }


                try {
                    if (newState === 'end') {
                        this.stopRecording(this.songId);
                    } else if (newState === 'play' && oldState === 'pause') {
                        this.resumeRecording();
                    } else if (newState === 'play') {
                        this.startRecording();
                        // resumeRecording();
                    } else if (newState === 'pause') {
                        this.pauseRecording();
                        // todo: test
                        if (this.settingsService.isStopRecordingOnPause()) {
                            (this.$scope.$parent as any).notifyFinish();
                        }
                    }
                } catch (e) {
                    console.error(e);
                }

                this.playerState = newState;
            });

        }).catch(fail => {
            console.warn("Recording failed: " + fail.message);
            this.onPermissionDenied(fail);
        });
    }

    public startRecording(): void {
        this.recorder.startRecording();

        // const sleep = m => new Promise(r => setTimeout(r, m));
        // await sleep(1000);
    }

    public pauseRecording(): void {
        if (!!this.recorder) {
            this.recorder.pauseRecording();
        }
    }

    public resumeRecording(): void {
        if (!!this.recorder) {
            this.recorder.resumeRecording();
        }
    }

    /**
     * @param {number} songId идентификатор песни
     */
    public stopRecording(songId: number): void {
        // console.info("[RC] stop recording song " + songId);
        if (!!this.recorder) {
            const buttonClick: ng.IDeferred<boolean> = this.$q.defer();
            const recordStop: ng.IDeferred<Blob> = this.$q.defer();

            this.$q.all([buttonClick.promise, recordStop.promise]).then((results) => {
                if (!!this.saveData) {
                    // данные о сохранённой на сервере записи уже есть - игнорируем
                } else {
                    // файл на сервер ещё не загружен – загружеам
                    let blob = results[1];
                    /*if (window.testing != null &&  window.testing.recorderdAudio != null) {
                        blob = window.testing.recorderdAudio;
                    }*/
                    const notifyPlayEvent = (this.$scope.$parent as any).notifyPlayEvent;
                    const eventId = !!notifyPlayEvent ? notifyPlayEvent.id : null;
                    // console.info('_this.onRecordComplete');
                    // console.info(this.onRecordComplete);
                    /*this.onRecordComplete(
                        blob,
                        songId,
                        eventId,
                        () => this.saveData = true
                    );*/
                    this.onRecordComplete({
                        data: blob,
                        songId: songId,
                        eventId: eventId,
                        onSuccess: () => {
                            this.saveData = true;
                        }
                    });
                    /*_this.onRecordComplete(blob, songId, eventId, () => {
                        this.saveData = true;
                    });*/
                }
            });

            // console.info("[RC] onRecordReadt");
            this.onRecordReady({buttonClick: buttonClick});
            // this.onRecordReady(buttonClick);

            // console.info(this.recorder.state);

            this.recorder.stopRecording(() => {
                const blob = this.recorder.getBlob()
                // console.info("this.recorder.getBlob() = " + blob);
                recordStop.resolve(blob)
            });

        }
    }

    $onChanges(binding: IOnChangesObject): void {
        // console.info("RecorderComponent $onChanges");
        // console.info(binding);
        if (window.angular.isDefined(binding)) {
            if (!!binding.currentValue) {
                console.log({
                    currentValue: binding.currentValue.currentValue,
                    isFirstChange: binding.currentValue.isFirstChange
                });
            }
            // eventBus
        } else {
            console.error("angular.isDefined(binding) is false");
        }
    }

    $onInit(): void {
        // console.info("RecorderComponent $onInit");

        if (!this.enabled) {
            // и не инициализируем ничего
        } else if (!navigator || !navigator.mediaDevices) {
            // нет разрешения - нет записи
            console.info("record not allowed");
            this.onRecordUnavailable();
        } else {
            // console.info("record allowed");
            this.initRecording();
        }
    };

    $onDestroy(): void {
        // console.info("RecorderComponent $onDestroy");
    };
}