import "angularjs-slider/dist/rzslider.js";
import './PlayerVolumeSliderComponent.less';
import {Component} from "../../../general/decorators";

@Component({
    controllerAs: '$ctrl',
    transclude: false,
    bindings: {
        graduation: "=",
        handler: "<",
        value: "<"
    },
    template: `
        <rzslider
         rz-slider-model="$ctrl.rzLevel"
         rz-slider-options="$ctrl.rzOptions"></rzslider>`
})
export default class PlayerVolumeComponentController implements ng.IOnInit, ng.IOnChanges {

    public graduation: number;
    public handler: (value: number) => void;
    public value: number;

    public rzLevel: number = 0.5;
    public rzOptions: ng.RzSlider.RzOptions;

    $onInit(): void {
        this.rzLevel = !!this.value ? this.value : 0.5;
        this.rzOptions = {
            floor: 0,
            ceil: 1,
            step: 1 / this.graduation,
            precision: 1,
            hidePointerLabels: true,
            hideLimitLabels: true,
            onChange: (id: string, modelValue: number, highValue: number, pointerType: "min" | "max") => this.handler(this.rzLevel),
            showSelectionBar: true
        };
    }

    $onChanges(changes: ng.IOnChangesObject): void {
        if (!!changes.value) {
            this.rzLevel = Number(changes.value);
        }
    }
}
