import PlayerStatus from "./PlayerStatus"
import PlayerEngine from "./PlayerEngine";
import {AnalyzerData, ErrorState, PlayerError, SongSource} from "./IPlayerEngine";

type Listener = () => void;

export default class PlayerEngineAudioApi extends PlayerEngine {

    private audioNode: AudioBufferSourceNode = null;
    private gainNode: GainNode = null;
    private buffer: AudioBuffer = null;
    private analyserNode: AnalyserNode = null;

    private startedAt: number = 0;
    private pausedAt: number | null = null;
    private playing: boolean = false;
    private volume: number = 1.0;

    private readonly playListener: Listener = null;
    private readonly pauseListener: Listener = null;
    private readonly timeUpdateListener: Listener = null;
    private readonly seekedListener: Listener = null;
    private readonly rateChangeListener: Listener = null;
    private readonly loadedMetadataListener: Listener = null;

    constructor(songSource: SongSource, audioContext: AudioContext = null) {
        super(songSource, audioContext);

        this.loadedMetadataListener = () => this.fireLoadedData(this.status());
        this.playListener = () => this.firePlay(this.status());
        this.pauseListener = () => this.firePause(this.status());
        this.seekedListener = () => this.fireSeeked(this.status());
        this.rateChangeListener = () => this.fireRateChange(this.status());
        this.timeUpdateListener = () => this.fireTimeUpdate(this.status());
    }

    public init(): PlayerEngineAudioApi {
        // this.postInit(false);
        this.loadAudio(this.songSource.mp3);
        return this;
    }

    private loadAudio(url: string) {
        const self = this;
        const request = new XMLHttpRequest();
        request.open('GET', url, true);
        request.responseType = 'arraybuffer';

        const errorCallback: DecodeErrorCallback = (error: DOMException) => {
            console.error(error);
            this.fireError(new PlayerError(ErrorState.URL, JSON.stringify(error)));
        }

        // Decode asynchronously
        request.onload = function () {
            if (!self.audioContext) {
                console.warn("Unable to decode an audio without a audioContext");
                return;
            }
            self.audioContext.decodeAudioData(request.response, function (buffer) {
                // self.audioNode = self.audioContext.createBufferSource(); // creates a sound source
                self.buffer = buffer;

                // self.audioNode.buffer = buffer; // tell the source which sound to play
                // self.audioNode.connect(self.audioContext.destination); // connect the source to the context's destination (the speakers)
                //
                // self.gainNode = self.audioContext.createGain(); // Connect the source to the gain node.
                // self.audioNode.connect(self.gainNode); // Connect the gain node to the destination.
                // self.gainNode.connect(self.audioContext.destination);

                // self.audio.start(0);// play the source now

                self.pausedAt = 0;
                const status = self.status();
                self.fireLoadedData(status);
                self.fireTimeUpdate(status);

            }, errorCallback);
        }
        try {
            request.send();
        } catch (error) {
            console.error(error);
            this.fireError(new PlayerError(ErrorState.URL, JSON.stringify(error)));
        }
    }

    public status(): PlayerStatus {
        return new PlayerStatus(!this.playing, this.getCurrentTime(), this.getDuration());
    }

    public play() {
        if (!this.audioContext) {
            console.warn("Unable to play without a audioContext");
            return;
        }

        if (this.playing === true) {
            return;
        }

        const offset = this.pausedAt === null ? 0 : this.pausedAt;
        const anew = this.audioNode === null;

        this.audioNode = this.audioContext.createBufferSource();
        // this.audioNode.connect(this.audioContext.destination);
        this.audioNode.buffer = this.buffer;

        this.gainNode = this.audioContext.createGain(); // Connect the source to the gain node.
        this.audioNode.connect(this.gainNode); // Connect the gain node to the destination.
        this.gainNode.connect(this.audioContext.destination);

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        try {
            //Create analyser node
            const analyserNode = this.audioContext.createAnalyser();
            analyserNode.fftSize = 256;

            //Set up audio node network
            this.audioNode.connect(analyserNode);
            analyserNode.connect(this.audioContext.destination);

            this.analyserNode = analyserNode;
        } catch (e) {
            console.warn(e);
            this.analyserNode = null;
        }

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        this.audioNode.start(0, offset);
        this.setVolume(this.volume)

        this.startedAt = this.audioContext.currentTime - offset;
        this.pausedAt = null;
        this.playing = true;

        setTimeout(() => {
            const status = this.status();
            this.firePlay(status);
            this.tickTimer(status);
        }, 10);
    }

    private tickTimer(_status: PlayerStatus = null) {
        const status = _status !== null ? _status : this.status();
        this.fireTimeUpdate(status);
        const analyzed = this.doAnalyze(status);
        if (analyzed !== null && !status.paused) {
            this.fireAnalyzerUpdateEvents(analyzed);
        }
        if (!status.paused) {
            if (status.currentTime >= status.duration) {
                this.fireEnded(status);
            }
            setTimeout(() => this.tickTimer(), 1000 / 30);
        }
    }

    public doAnalyze(status: PlayerStatus): AnalyzerData {
        if (!!this.analyserNode/* && (Math.floor(status.currentTime) % 2) === 1*/) {
            const analyserBufferLength = this.analyserNode.frequencyBinCount;
            const analyserNodeDataArray = new Float32Array(analyserBufferLength);
            this.analyserNode.getFloatFrequencyData(analyserNodeDataArray);
            return new AnalyzerData(analyserBufferLength, analyserNodeDataArray)
        } else {
            return null;
        }
    }

    public pause() {
        if (!this.audioContext) {
            console.warn("Unable to pause without a audioContext");
            return;
        }
        const elapsed = this.audioContext.currentTime - this.startedAt;

        this.stop();

        /*if (this.audioNode) {
            // this.audioNode.disconnect();
            this.audioNode.stop(0);
            // this.audioNode = null;

            // this.gainNode.disconnect();
        }
        // this.pausedAt = 0;
        this.startedAt = 0;
        this.playing = false;*/

        this.pausedAt = elapsed;

        setTimeout(() => {
            const status = this.status();
            this.firePause(status);
            this.fireTimeUpdate(status);
        }, 1);

    }

    private stop() {
        if (this.audioNode) {
            this.audioNode.disconnect();
            this.audioNode.stop(0);
            this.audioNode = null;

            this.gainNode.disconnect();
        }
        this.pausedAt = 0;
        this.startedAt = 0;
        this.playing = false;
    };

    public setVolume(value: number) {
        this.volume = value;
        if (!!this.gainNode) {
            this.gainNode.gain.value = value * 2 - 1;
        }
    }

    public getVolume(): number {
        return this.volume;
    }

    private getCurrentTime() : number {
        if (this.pausedAt !== null) {
            return this.pausedAt;
        } else if (this.startedAt !== null && !!this.audioContext) {
            return this.audioContext.currentTime - this.startedAt;
        } else {
            return 0;
        }
    };

    private getDuration() {
        return !!this.buffer ? this.buffer.duration : 0;
    };

    public setCurrentTime(currentTime: number): void {
        if (this.playing) {
            this.stop();
            this.pausedAt = currentTime;
            this.play();
        } else {
            this.pausedAt = currentTime;
            this.play();
        }
        // if (!!this.audioNode && !!this.audioContext) {
        //     this.audioNode.start(this.audioContext.currentTime, currentTime);
        // }
    }

    public addCurrentTime(offsetTime: number): void {
        if (!!this.audioNode) {
            this.setCurrentTime(this.getCurrentTime() + offsetTime)
        }
    }

    public createMediaElementSource(): AudioNode {
        return this.audioNode;
    }

    public destroy(): PlayerStatus {
        this.stop();
        return this.status();
    }
}