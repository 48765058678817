import './PlayerVolumeComponent.less';

export default {
    transclude: true,
    bindings: {
        graduation: "=",
        ic: "=",
        handler: "<",
        value: "<"
    },
    template: `
        <div data-ng-repeat="band in $ctrl.bands" class="vol"
            data-ng-class="{active: band <= $ctrl.level}" 
            data-ng-click="$ctrl.select(band);"
            data-ng-style="$ctrl.est(band)"></div>`,
    controller: PlayerVolumeComponentController
};

function PlayerVolumeComponentController($scope) {

    this.$onInit = function () {
        const graduation = $scope.$ctrl.graduation;
        if (!!$scope.$ctrl.value) {
            $scope.$ctrl.level = Math.round($scope.$ctrl.value * $scope.$ctrl.graduation);
        } else {
            $scope.$ctrl.level = Math.round(graduation / 2);
        }
        $scope.$ctrl.bands = Array(graduation).fill().map((e, i) => i);
    };

    this.$onChanges = function (changes) {
        if (!!changes.value) {
            $scope.$ctrl.level = Math.round(changes.value * $scope.$ctrl.graduation);
        }
    };

    this.select = (value) => {
        $scope.$ctrl.level = value;
        $scope.$ctrl.handler(value / ($scope.$ctrl.graduation - 1));
    };

    this.est = (value) => {
        const percent = (value / ($scope.$ctrl.graduation - 1) * 100);
        return {
            height: percent + "%"
        };
    };
}

PlayerVolumeComponentController.$inject = ["$scope"];