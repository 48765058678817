import {
    ErrorHandler,
    EventHandler,
    IPlayerEngine,
    PlayerError,
    SongSource,
    AnalyzerEventHandler,
    AnalyzerData
} from "./IPlayerEngine";
import PlayerStatus from "./PlayerStatus";

export default abstract class PlayerEngine implements IPlayerEngine {

    protected readonly songSource: SongSource;
    private readonly _audioContext: AudioContext;

    private loadedDataEvents: Array<EventHandler> = [];
    private playEvents: Array<EventHandler> = [];
    private pauseEvents: Array<EventHandler> = [];
    private timeUpdateEvents: Array<EventHandler> = [];
    private analyzerUpdateEvents: Array<AnalyzerEventHandler> = [];
    private endedEvents: Array<EventHandler> = [];
    private seekedEvents: Array<EventHandler> = [];
    private rateChangeEvents: Array<EventHandler> = [];
    private errorHandlerEvents: Array<ErrorHandler> = [];

    public static createAudioContext() {
        window.AudioContext = window.AudioContext || (window as any).webkitAudioContext;
        return new AudioContext();
    }

    protected constructor(songSource: SongSource, audioContext: AudioContext) {
        this.songSource = songSource;
        this._audioContext = !audioContext ? PlayerEngine.createAudioContext() : audioContext;
    }

    abstract init(): IPlayerEngine;

    abstract destroy(): PlayerStatus;

    abstract pause(): void;

    abstract play(): void;

    abstract status(): PlayerStatus;

    abstract setCurrentTime(currentTime: number): void;

    abstract setVolume(value: number): void;

    abstract getVolume(): number;

    abstract addCurrentTime(offsetTime: number): void;

    public get audioContext(): AudioContext {
        return this._audioContext;
    }

    abstract createMediaElementSource(): AudioNode;

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    protected fireLoadedData(status: PlayerStatus): void {
        this.loadedDataEvents.forEach(event => event(status));
    }

    protected firePlay(status: PlayerStatus): void {
        this.playEvents.forEach(event => event(status));
    }

    protected firePause(status: PlayerStatus): void {
        this.pauseEvents.forEach(event => event(status));
    }

    protected fireTimeUpdate(status: PlayerStatus): void {
        this.timeUpdateEvents.forEach(event => event(status));
    }

    protected fireAnalyzerUpdateEvents(analyzerData: AnalyzerData): void {
        this.analyzerUpdateEvents.forEach(event => event(analyzerData));
    }

    protected fireEnded(status: PlayerStatus): void {
        this.endedEvents.forEach(event => event(status));
    }

    protected fireSeeked(status: PlayerStatus): void {
        this.seekedEvents.forEach(event => event(status));
    }

    protected fireRateChange(status: PlayerStatus): void {
        this.rateChangeEvents.forEach(event => event(status));
    }

    protected fireError(error: PlayerError): void {
        this.errorHandlerEvents.forEach(event => event(error));
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    public addOnLoadedData(func: EventHandler): void {
        this.loadedDataEvents.push(func);
    }

    public addOnPlay(func: EventHandler): void {
        this.playEvents.push(func);
    }

    public addOnPause(func: EventHandler): void {
        this.pauseEvents.push(func);
    }

    public addOnTimeUpdate(func: EventHandler): void {
        this.timeUpdateEvents.push(func);
    }

    public addOnAnalyzerUpdate(func: AnalyzerEventHandler): void {
        this.analyzerUpdateEvents.push(func);
    }

    public addOnEnded(func: EventHandler): void {
        this.endedEvents.push(func);
    }

    public addOnSeeked(func: EventHandler): void {
        this.seekedEvents.push(func);
    }

    public addOnRateChange(func: EventHandler): void {
        this.rateChangeEvents.push(func);
    }

    public addOnError(func: ErrorHandler): void {
        this.errorHandlerEvents.push(func);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    protected static remove<T>(array: Array<T>, value: T): boolean {
        const index = array.indexOf(value, 0);
        if (index > -1) {
            array.splice(index, 1);
            return true;
        } else {
            return false;
        }
    }

    public removeOnLoadedData(func: EventHandler): void {
        PlayerEngine.remove(this.loadedDataEvents, func);
    }

    public removeOnPlay(func: EventHandler): void {
        PlayerEngine.remove(this.playEvents, func);
    }

    public removeOnPause(func: EventHandler): void {
        PlayerEngine.remove(this.pauseEvents, func);
    }

    public removeOnTimeUpdate(func: EventHandler): void {
        PlayerEngine.remove(this.timeUpdateEvents, func);
    }

    public removeAnalyzerUpdate(func: AnalyzerEventHandler): void {
        PlayerEngine.remove(this.analyzerUpdateEvents, func);
    }

    public removeOnEnded(func: EventHandler): void {
        PlayerEngine.remove(this.endedEvents, func);
    }

    public removeOnSeeked(func: EventHandler): void {
        PlayerEngine.remove(this.seekedEvents, func);
    }

    public removeOnRateChange(func: EventHandler): void {
        PlayerEngine.remove(this.rateChangeEvents, func);
    }

    public removeOnError(func: ErrorHandler): void {
        PlayerEngine.remove(this.errorHandlerEvents, func);
    }
}