import "./SongsListComponent.less";
import template from "./SongsListTemplate.html";

export default {
    transclude: false,
    bindings: {
        songs: "<",
        user: "<",
        hidePlaylist: "<",
        entirePlaylist: "<",
        total: "<",
        currentPage: "<",

        pageCount: "&",
        range: "&",
        setPage: "<",

        prevPageDisabled: "<",
        prevPage: "<",
        nextPageDisabled: "<",
        nextPage: "<",

        playAction: "<",
        addToPlaylistAction: "<",
        tile: "<"
    },
    template: template,
    controller: SongsListComponent
};

function SongsListComponent($scope, $rootScope) {

    this.$onInit = () => {
        $scope.$ctrl.songImageUrl = $rootScope.songImageUrl;
    };

    this.$onChanges = (changes) => {
        if (changes.total || changes.pageCount) {
            $scope.hasPagination = !!$scope.$ctrl.total && $scope.$ctrl.pageCount() > 1;
        }
    };
}

SongsListComponent.$inject = ["$scope", "$rootScope"];