import UserPaymentProfile from "./entity/UserPaymentProfile";
import {IAngularStatic, ICompileService, IDeferred, IHttpResponse, IHttpService, IPromise, IHttpHeadersGetter, IRequestConfig, ITemplateLinkingFunction} from "angular";
import DataService from "./DataService";
import BrandingService from "../../../branding/BrandingService";
import TrialService from "./TrialService";
import StorageService from "./StorageService";
import {karaoke} from "../karaoke";
import UserProfile from "./entity/UserProfile";
import {
    ActionContext,
    DefaultContext,
    ExecutionContext,
    PayActionContext,
    ResponseBattleSongContext,
    SongContext,
    SubMainContext,
    SubMainLandingContext,
    SubPlusContext,
    SuccessPaymentContext, TrialTransaction
} from "./entity/ActionContext";

import "reflect-metadata";
import {Type, deserialize, serialize} from "class-transformer";
import PlayerService from "./PlayerService";
import {SongAction} from "./entity/SongAction";
import Battle, {BattleStates} from "../performance/Battle";
import IRootScopeService = karaoke.IRootScopeService;
import TextUtilService from "./TextUtilService";
import BasicAuthData from "./entity/BasicAuthData";
import EventNY2022Trial from "../../../branding/tele2/event/EventNY2022Trial";
import ModalMessageService from "./ModalMessageService";

/**
 * Ключ авторизационного заголовка в хранилище
 * @type {string}
 */
const AUTH_HEADER_STORAGE_KEY = "authHeader";
const TARGET_ACTION_STORAGE_KEY = "storedAction";

class ActionContextSerializerWrapper {

    constructor(content: PayActionContext | SubMainContext | SubPlusContext | SongContext | ResponseBattleSongContext) {
        this.content = content;
    }

    @Type(() => ActionContext, {
        discriminator: {
            property: "__type",
            subTypes: [
                {value: PayActionContext, name: "PayActionContext"},
                {value: SubMainContext, name: "SubMainContext"},
                {value: SubPlusContext, name: "SubPlusContext"},
                {value: SongContext, name: "SongContext"},
                {value: ResponseBattleSongContext, name: "ResponseBattleSongContext"}
            ]
        }
    })
    content: PayActionContext | SubMainContext | SubPlusContext | SongContext | ResponseBattleSongContext;
}

enum ActionAvailability {
    AVAILABLE,
    AVAILABLE_PAYED,
    AVAILABLE_TRIAL,
    NOT_AVAILABLE
}

export default class AuthService {

    static $inject = ['$http','$rootScope', '$compile', '$q', '$route', '$location',
        'DataService', 'TrialService', 'StorageService', 'BrandingService', 'PlayerService', 'TextUtilService', 'ModalMessageService'
    ];

    private readonly angular: IAngularStatic;

    /**
     * Данные пользователя
     */
    private _userData: UserProfile;

    // todo: более универсальный метод
    private eventNY2022Trial: EventNY2022Trial = null;

    // todo: параллельные запросы, пересмотреть и перейти на RX
    private userInfoLoading = false;

    constructor(
        private readonly $http: IHttpService,
        private readonly $rootScope: IRootScopeService,
        private readonly $compile: ICompileService,
        private readonly $q: ng.IQService,
        private readonly $route: ng.route.IRouteService,
        private readonly $location: angular.ILocationService,
        private readonly dataService: DataService,
        private readonly trialService: TrialService,
        private readonly storageService: StorageService,
        private readonly brandingService: BrandingService,
        private readonly playerService: PlayerService,
        private readonly textUtilService: TextUtilService,
        private readonly modalMessageService: ModalMessageService,
    ) {

        this.angular = window.angular; // todo;

        this._userData = new UserProfile(this.brandingService);
    }


    /**
     * Перезагузка страницы с целью авторизации.
     * Если пользователь первоначально пришёл через небезопасный канал, а потом переключился (WiFi -> Cellular), он будет авторизован.
     * todo: в каких-то ситуациях фрагмент anchor может раскопироваться
     */
    public reloadPage(): void {
        const
            base = window.location.href.split("?")[0].split("#")[0],
            anchor = window.location.href.split("#")[1],
            newLocation = base + "#" + anchor;

        if (newLocation !== window.location.href) {
            console.info("go to '" + newLocation + "'");
            window.location.href = newLocation;
        } else {
            console.info("refresh page");
            location.reload();
            //location.reload(true);
        }
    }

    /**
     * Обновление контекста
     */
    private updateUserDataImpl(
        onSuccess: (string, number, IHttpHeadersGetter, IRequestConfig) => void | null,
        onFail: (string, number, IHttpHeadersGetter, IRequestConfig) => void | null = null,
        angularHttpParams: Object = {}
    ): void {
        const _this = this;
        if (_this.userInfoLoading === true) {
            return
        } else {
            _this.userInfoLoading = true
        }
        this.dataService.serviceHttp('/user-info', angularHttpParams)
            .then(function (response: IHttpResponse<any>) {
                _this.userInfoLoading = false
                let dat = response.data as any;
                dat.csrf = !!dat.csrf ? dat.csrf : dat._csrf; // hack
                _this.storeUserData(dat);
                if (!!onSuccess) {
                    onSuccess(response.data, response.status, response.headers, response.config);
                }
            }, function (response) {
                _this.userInfoLoading = false
                console.error("Невозможно получить данные пользователя");
                _this._userData = new UserProfile(_this.brandingService);
                if (!!onFail) {
                    onFail(response.data, response.status, response.headers, response.config);
                }
            });
    }

    /**
     * Сохранение данных пользователя в контекст
     * @param data
     */
    private storeUserData(data: UserProfile) {
        console.info("Данные пользователя: " + JSON.stringify(data));

        this._userData = data;

        const Raven = (window as any).Raven as any;
        if (typeof Raven !== "undefined" && Raven.isSetup()) {
            const
                location = window.location.href,
                i = location.indexOf("#"),
                path = !i ? location : location.substring(i + 1);

            Raven.setExtraContext(data);
            Raven.setTagsContext({
                path: path,
                operator: this.brandingService.operator
            });
            Raven.setUserContext({
                id: data.msisdn
            });
        }
    }


    /**
     * Обработка сетевых ошибок, 403 – синал о превышении количества одновременных сессий.
     * @param data
     * @param {Number} status
     */
    public handleHttpResponseError(data, status/*, headers, config*/) {
        if (status === 403) {
            // Очистка сохранённых авторизационных данных
            this.storageService.remove(AUTH_HEADER_STORAGE_KEY);

            // перезагрузка, поскольку ошибка может быть получена от любой страницы
            this.reloadPage();
        }
    };

    /**
     * Изменяем настройки сервисов перед тем как в систему будет разослан $broadcast contextLoaded
     */
    private applyPromoHacks(promos: Array<string>): void {
        if (!!promos && promos.indexOf("t2-ny-2022") >= 0) {
            // todo: переместить в this.brandingService.applyPromo(promo, angular.module) ??

            if (this.eventNY2022Trial === null) {
                this.eventNY2022Trial = new EventNY2022Trial()
            }

            this.trialService.override(this.eventNY2022Trial);
        } else {
            this.trialService.default();
        }
    }

    /**
     * Обновление данных пользователя при запросе страницы
     * @param overrideLocationUrl переопределение значения location, на базе которого строится статистика
     */
    public updateUserData(overrideLocationUrl: string = null): void {

        const _this = this;

        // на базе переданного location строится статистика
        const location = !!overrideLocationUrl ? overrideLocationUrl : this.$location.url()

        /**
         * Обновление данных пользователя в scope
         * @param rootScope {IRootScopeService} scope для установки объекта user (содержимое полученного user-info)
         */
        function updatePageScope(rootScope: IRootScopeService) {
            // для страниц

            // console.info("1: " + _this._userData.paymentProfile.subPlus);
            // console.info("2: " + _this.BrandingService.plusIncludesRegular);
            // console.info("3: " + _this._userData.paymentProfile.subMain);

            const paymentProfile = new UserPaymentProfile(
                _this.brandingService,
                _this._userData.paymentProfile.subMain,
                _this._userData.paymentProfile.subPlus
                    /*|| (_this.BrandingService.plusIncludesRegular && _this._userData.paymentProfile.subMain)*/,
                _this._userData.paymentProfile.songs,
                _this._userData.paymentProfile.plusAvailable,
            );

            rootScope.user = new UserProfile(
                _this.brandingService,
                _this.isAuthenticatedFull(),
                // tryAccess: _this.isTryAllowed(),
                paymentProfile,
                paymentProfile.subMain, // todo дубликат для более короткой записи в шаблонах
                _this._userData.msisdn,
                _this._userData.promos,
                _this._userData.nickName,
                _this._userData.profileId,
                _this._userData.battles,
                _this._userData.inviteBattleId,
                _this._userData.inviteBattleCode,
                _this._userData.campaign,
                _this._userData.csrf
            );

            _this.applyPromoHacks(_this._userData.promos);

            rootScope.$broadcast('contextLoaded');
        }

        /**
         * Обрабока данных пользователя с целью дополнительных действий (напирмер, по сохранённым флагам)
         * @param data данные пользователя
         * @param status статус
         */
        function handleSuccessResponse(data, status) {
            if (status === 401 || !data || !data.paymentProfile || data.paymentProfile.subMain === null) {
                console.info("user data seems empty, reload page (" + status + "/" + JSON.stringify(data) + ")");
                _this.reloadPage(); // для авторизации в MF перезагружаем страницу (последует редирект)
            }

            const
                restoredIntent = _this.restoreIntent(),
                msg = (message) => _this.modalMessageService.alert(message),
                msgRel = (message) => _this.modalMessageService.alert(message, () => _this.$route.reload());

            // чтобы понять рекурсию, надо понять рекурсию
            if (!!restoredIntent) {
                console.info('restoredIntent');
                console.info(restoredIntent);
                _this.submitAction(restoredIntent).then((context) => _this.defaultAction(restoredIntent, context));
            }

            // todo: remove
            /*if (false && (tryAuth || trySubscribe)) {
                _this.StorageService.remove('tryAuth');
                _this.StorageService.remove('trySubscribe');

                if (!_this.isAuthenticatedFull() && !_this.isAuthenticatedLite()) {

                    console.info("Still no msisdn");
                    // msg("Авторизация не удалась. Для доступа к сервису отключите, пожалуйста, Wi-Fi и используйте мобильный интернет.");
                    msg("Авторизация не удалась.");

                    /!*var message;

                     switch (BrandingService.operator) {
                     case 'MegaFon':
                     message = "Сервис доступен только для абонентов МегаФон. " +
                     "В случае, если если Вы являетесь абонентом МегаФон, для доступа к сервису отключите, " +
                     "пожалуйста, Wi-Fi и используйте мобильный интернет (трафик бесплатный).";
                     case 'Tele2':
                     message = "Сервис доступен только для абонентов Tele2. " +
                     "В случае, если если Вы являетесь абонентом Tele2, для доступа к сервису отключите, " +
                     "пожалуйста, Wi-Fi и используйте мобильный интернет (трафик бесплатный).";
                     default:
                     message = "Для доступа к сервису отключите, пожалуйста, Wi-Fi и используйте мобильный интернет.";
                     }

                     msg(message);*!/

                } else if (_this.isSubscribedMain()) {
                    // пользователь оказался оплачен - поздравляем с успешной аутентификацией
                    msgRel("Добро пожаловать в «" + _this.BrandingService.serviceName + "»! Вам доступен весь каталог песен!");

                } else if (!_this.isSubscribedMain() && _this.isAuthenticatedFull()) {

                    if (trySubscribe) {
                        // покажем AoC
                        _this.doSubscribe(
                            () => msgRel("Добро пожаловать в «" + _this.BrandingService.serviceName + "»! Вам доступен весь каталог песен!")
                            // если пользователь после авторизации отказался от подписки, ничего не показываем
                        );
                    }

                } else if (!_this.isSubscribedMain()){
                    // old: Вы можете выбрать и бесплатно исполнить одну песню. / Выбирайте и исполняйте любые песни!
                    const trialTerms = _this.TrialService.describe(null);
                    msg("Добро пожаловать в «" + _this.BrandingService.serviceName + "»!" + !!trialTerms ? ` ${trialTerms}` : "");
                }
            }*/
        }

        // обновление данных пользователя, результат будет записан в _this.userData
        _this.updateUserDataImpl((data, status) => {
                // если загрузка данных пользователя удалась – проверим не нужно ли что-то сделать дополнительно и обновим scope
                handleSuccessResponse(data, status);
                updatePageScope(_this.$rootScope);
            }, () => {
                // если загрузка неудачная - просто обновим scope
                // handleHttpResponseError специально не обрабатываем, поскольку он вызывется в AuthInterceptor
                updatePageScope(_this.$rootScope);
            }, {
                params: {
                    location: location
                }
            }
        );
    };

    /**
     * Аутентифицирован ли пользователь, каким-либо способом
     * @return {boolean}
     */
    public isAuthenticated(): boolean {
        return !!this._userData && !!this._userData.msisdn;
    }

    /**
     * Пользователь авторизован надёжным способом, возможна подписка
     * @return {boolean}
     */
    public isAuthenticatedFull(): boolean {
        return this.isAuthenticated() && this._userData['reliable-auth'] === true;
    }

    /**
     * Пользователь авторизован ненадёжным способом, подписка невозможна
     * @return {boolean}
     */
    public isAuthenticatedLite() : boolean {
        return this.isAuthenticated() && this._userData['reliable-auth'] === false;
    }

    public isSubscribedMain() : boolean {
        return this.isAuthenticated() && this._userData.paymentProfile.subMain === true;
    }

    public isSubscribedPlus() : boolean {
        return this.isAuthenticated() && this._userData.paymentProfile.subPlus === true;
    }

    /**
     * Получение MSISDN аутентифицированного пользователя
     * @returns {String}
     * @deprecated not in use
     */
    public getMsisdn(): String | null {
        return !!this._userData ? this._userData.msisdn : null;
    }

    public battles(): Battle[] {
        let battles: Battle[] = [];
        if (this._userData.battles!) {
            this._userData.battles.forEach((b) => battles.push(b));
        }

        // батл по инвайту по ссылке - тогда нужно возвращать промис
        /*const battleId = this.userData.inviteBattleId;
        if (!!battleId) {
            console.info('yyyyyyyyyy');
            this.DataService.serviceHttp<Battle>(`/performance/battle/load?battleId=${battleId}`)
                .then((httpResponse) => {
                    battles.push(httpResponse.data)
                })
                .catch((onReject) => {
                    console.warn(`unable to load invite battle ${battleId}`)
                    // do nothing
                });
        }*/
        return battles;
    }

    get userData(): UserProfile {
        return this._userData;
    }

    /**
     * Осуществление подписки
     *
     * @param postAction действие, которое будет произведено после осуществления подписки
     * @param cancelAction действие, выполняемое при отказе от платежа
     * @param plusSub подписка обычая (false) или plus (true)
     */
    private doSubscribeImpl(postAction: () => void, cancelAction: () => void = () => {}, plusSub = false) {

        const _this = this;

        // todo: тип подписки!!
        if (_this.isAuthenticatedFull()) {
            _this.dataService.serviceHttp('/subscribe/', {
                method: 'POST',
                params: {
                    'type': plusSub ? 'SUB_PLUS' : 'SUB_MAIN',
                    '_csrf': !!_this._userData ? _this._userData.csrf : null
                },
                headers: {
                    'X-Requested-With': 'AUTH'
                }
            }).
            then((response) => {

                if (response.data === null || response.data === "") {
                    // пустой результат, возможно, требуется повторная авторизация
                    console.info("empty data");

                    //this.modalMessageService.alert("Время действия сессии истекло. Пожалуйста, повторите действие после перезагрузки страницы.", function() {
                    //this.modalMessageService.alert("Сервис доступен только для абонентов МегаФон. В случае, если Вы являетесь абонентом МегаФон, но видите это сообщение, проверьте, пожалуйста, настройки Мобильного Интернета.", function() {
                    _this.reloadPage();
                    //});

                    return false;
                }

                const container = $('<div></div>');
                container.html(response.data as string); // todo

                const result = container.find("#subscribe-status").text();
                console.info("result: " + result);
                if (result === "ALREADY") {
                    // по-умолчанию подписка не требуется
                    console.info("subscribe not needed");
                    // ??? $location.path("jukebox");

                    _this.updateUserDataImpl(() => {
                        if (plusSub ? _this._userData.paymentProfile.subPlus : _this._userData.paymentProfile.subMain) {
                            if (!!postAction) {
                                postAction(); // go
                            }
                        } else {
                            const msg = _this.textUtilService.localizedText("payment.error.general-retry");
                            this.modalMessageService.alert(msg); // "Подписка не удалась, попробуйте позже."
                        }
                    });

                } else if (result === "OK") {
                    // по-умолчанию подписка не требуется
                    console.info("subscribe success");
                    // ??? $location.path("jukebox");

                    _this.updateUserDataImpl(() => {
                        if (plusSub ? _this._userData.paymentProfile.subPlus : _this._userData.paymentProfile.subMain) {
                            if (!!postAction) {
                                // console.info("pppppost action");
                                postAction(); // go
                            }
                        } else {
                            const msg = _this.textUtilService.localizedText("payment.error.general-retry");
                            this.modalMessageService.alert(msg); // "Подписка не удалась, попробуйте позже."
                        }
                    });


                } else if (result === "FAIL") {
                    console.info("subscribe fail");
                    //this.modalMessageService.alert("Невозможно осуществить подписку, пожалуйста, проверьте что вы используете сервис через мобильный интернет.");
                    // Отключаем выдачу сообщения о том что нужно переключиться с wi-fi на мобильный интернет
                    //this.modalMessageService.alert("Сервис доступен только для абонентов МегаФон. В случае, если если Вы являетесь абонентом МегаФон, для доступа к сервису отключите, пожалуйста, Wi-Fi и используйте мобильный интернет (трафик бесплатный).");

                    // не смогли подисаться - отправляем на авторизацию todo: такой сценарий?
                    _this.doAuthorize(postAction, cancelAction);
                    // this.modalMessageService.alert("Подписка не удалась, попробуйте позже.");

                } else if (result === "SUBSCRIBE-FORWARD") {
                    console.info("forward to subscribe");
                    let link = container.find("#forward-url a").attr("href");
                    console.info("link: " + link);

                    if (!link) {
                        const txt = container.find('div').text();
                        if (!!txt && txt.indexOf('/subscribe/?ussd_auth=true') > -1) {
                            link = 'http://funcontent.ru/waptele2/wap/sso/login/';
                        }
                    }

                    if (!link) {
                        console.info("wrong response: " + response.data);
                        const msg = _this.textUtilService.localizedText("payment.error.wrong-response");
                        _this.modalMessageService.alert(msg); // "Некорректный ответ от сервера."
                    } else if (link.indexOf('login.tele2.ru/ssotele2') > -1) {
                        // SSO
                        console.info("SSO");
                        window.location.href = link;
                    } else {
                        // SUBSCRIBE
                        console.info("try subscribe");
                        _this.$http({
                            method: 'GET',
                            cache: false,
                            url: link
                        }).then((response) => {
                            // подписались
                            console.info("subscribe success");

                            _this.updateUserDataImpl(function () {
                                if (plusSub ? _this._userData.paymentProfile.subPlus : _this._userData.paymentProfile.subMain) {
                                    postAction(); // go
                                } else {
                                    const msg = _this.textUtilService.localizedText("payment.error.general-retry");
                                    _this.modalMessageService.alert(msg); //"Подписка не удалась, попробуйте позже."
                                }
                            });
                        }).catch((errorResponse) => {
                            const msg = _this.textUtilService.localizedText("payment.error.general");
                            _this.modalMessageService.alert(msg); // "Невозможно осуществить подписку."
                        });
                    }

                } else {
                    const msg = _this.textUtilService.localizedText("payment.error.wrong-status", {"status": result});
                    _this.modalMessageService.alert(msg); // "Некорректный статус подписки: '" + result + "'"
                }
            }, () => {
                //_this.modalMessageService.alert("Невозможно осуществить подписку.");
                console.info("unable to subscribe");
                _this.doAuthorize(postAction, cancelAction);
            });
        } else {
            _this.doAuthorize(postAction, cancelAction);
        }
    };

    public hasSubscribeOnAuth(): boolean {
        const url = this._userData['web-auth-url'];
        return url === 'karaoke://basic-auth';
    }

    /**
     * Конструирвоание авторизационного заголовока
     * @param {{login, password}} authData
     */
    public basicHeader = (authData : BasicAuthData) : string => {
        console.info(authData.login);
        console.info(authData.password);
        console.info('Basic ' + btoa(authData.login + ':' + authData.password));
        return 'Basic ' + btoa(authData.login + ':' + authData.password);
    };

    /**
     * Получение сообщения, которое следует показать пользователю
     * @param data
     * @param status
     * @param headers
     * @param config
     * @param {string} defaultMessage сообщение по-умолчанию
     */
    public authMessage = (data: string, status: number, headers: IHttpHeadersGetter, config: IRequestConfig, defaultMessage: string) : string => {
        if (!!headers) {
            let message = headers('WWW-Authenticate');
            if (!!message) {
                message = message.replace(/Basic realm="(.*)"/, "$1");
                message = decodeURIComponent(message.replace(/\+/g, " "));
                return message;
            }
        }
        return defaultMessage;
    };

    /**
     * Авторизационное обращение к серверу
     * @param {string} login логин
     * @param {string} password пароль
     * @param postAction действие при успешной авторизации postAction
     * @param onError действие при возникновении ошибки
     */
    public basicAuth = (
        login = '',
        password = '',
        postAction: () => void = () => {},
        onError: (data: string, status: number, headers: IHttpHeadersGetter, config: IRequestConfig, msg: string) => void = () => {}
    ) => {
        console.info('updateUserData');
        const authHeader = this.basicHeader(new BasicAuthData(login, password));
        this.updateUserDataImpl((data, status, headers, config) => {
            // success
            if (this._userData.paymentProfile.subMain) {
                // сохраним авторизационный заголовок
                this.storageService.set(AUTH_HEADER_STORAGE_KEY, authHeader);
                // и выполним целевое действие
                if (!!postAction) {
                    postAction();
                }
            } else {
                // в случае если подписка должна происходить одновременно вместе с авторизацией – ошибка
                // "Подписка не удалась, попробуйте позже."
                const msg = this.textUtilService.localizedText("payment.error.general-retry");
                onError(data, status, headers, config, msg)
            }
        }, (data, status, headers, config) => {
            // fail
            // "Авторизация не удалась, попробуйте позже."
            const msg = this.textUtilService.localizedText("auth.error.general-retry");
            onError(data, status, headers, config, msg);
        }, {
            headers: {"Authorization": authHeader}
        });
    };

    /**
     * Перенаправление на SSO с целью авторизации
     * @param postAction действие, которое требовалось выполнить, если ушли на авторизационный URL - пропало
     * @param cancelAction действие, выполняемое при отказе от перехода к авторизации
     * @param onByPass действие, выполняемое перед уходомна внешний ресурс
     */
    public doAuthorize(postAction, cancelAction: () => void = () => {}, onByPass: () => void = () => {}) {

        const url = this._userData['web-auth-url'];

        if (url === 'karaoke://basic-auth') {

            const rawAuthForm = !!this.brandingService.authForm ? this.brandingService.authForm() : null;

            /**
             * Форма имеет собственный контроллер, новый механизм, кнопки не нужны
             */
            const formWithOwnController = rawAuthForm != null && rawAuthForm.indexOf("ng-controller") > -1

            /**
             * Авторизационная форма
             * @type {string}
             */
            const authForm : string = !rawAuthForm ? null :
                (formWithOwnController ? rawAuthForm : `<form id='basicAuth' action=''>${rawAuthForm}</form>`);


            /**
             * Получаем авторизационные данные из формы
             * @param $form
             * @returns {{login, password}}
             */
            const extractAuthData = ($form: JQuery<HTMLFormElement>) : BasicAuthData => new BasicAuthData(
                $form.find("input[name='login']").val(),
                $form.find("input[name='password']").val()
            );

            /**
             * Отображение авторизационного окна
             * @param authForm
             * @param errorMessage сообщение об ошибке (если есть)
             * @param login предзаданный логин (если есть)
             * @param password предзаданный пароль (если есть)
             */
            const doAuth = (authForm = '👾', errorMessage = '', login = '', password = '') => {

                const template = this.angular.element(authForm
                    .replace(/%MESSAGE%/, errorMessage)
                    .replace(/%LOGIN%/, login)
                    .replace(/%PASSWORD%/, password)
                );
                const linkFn: ITemplateLinkingFunction = this.$compile(template);
                const modalScope = this.$rootScope.$new(true);
                modalScope["postAction"] = postAction;
                modalScope["cancelAction"] = cancelAction;
                const html = linkFn(modalScope); //

                // todo: создать контроллер и встроить его во все basic-авторизации

                if (formWithOwnController) {
                    // это сложная форма со своим контроллером
                    let dialog = this.modalMessageService.dialogExt({
                        className: 'bootbox-prompt-basic-auth',
                        message: html
                    });
                    modalScope.$on("close", () => {
                        dialog.modal('hide');
                    });
                } else {
                    this.modalMessageService.confirmExt({
                        className: 'bootbox-prompt-basic-auth',
                        message: html,
                        buttons: {
                            cancel: {
                                label: this.textUtilService.localizedText('core.cancel')
                            },
                            confirm: {
                                label: this.textUtilService.localizedText('core.enter')
                            }
                        },
                        callback: (result) => {
                            if (result) {
                                const authData = extractAuthData($('#basicAuth'));
                                if (!!authData.login && !!authData.password) {
                                    this.basicAuth(authData.login, authData.password,
                                        () => postAction(),
                                        (data, status, headers, config, msg) => {
                                            doAuth(authForm,
                                                this.authMessage(data, status, headers, config, msg),
                                                login,
                                                password
                                            );
                                        });
                                } else {
                                    // 'Пожалуйста, введите номер телефона и пароль'
                                    const msg = this.textUtilService.localizedText("auth.required-fields");
                                    doAuth(authForm, msg, authData.login, authData.password);
                                }
                            } else {
                                if (!!cancelAction) {
                                    cancelAction();
                                }
                            }
                        }
                    });
                }
            };

            // 🎯 начало авторизации
            const savedAuth = this.storageService.get(AUTH_HEADER_STORAGE_KEY);
            if (!authForm) {
                this.modalMessageService.alert(this.textUtilService.localizedText("auth.unavailable")); // "Авторизация недоступна."
            } else if (!!savedAuth) {
                // сперва попробуем авторизоваться автоматически
                this.updateUserDataImpl(() => {
                    // success
                    if (this._userData.paymentProfile.subMain) {
                        // авторизация удалась, пользователь подписан
                        // выполним целевое действие
                        postAction();
                    } else {
                        // в случае если подписка должна происходить одновременно вместе с авторизацией – ошибка
                        // но здесь покажем форму без ошибок
                        doAuth(authForm);
                    }
                }, () => {
                    // автоматическая авторизация не удалась совсем – показываем авторизационную форму
                    doAuth(authForm);
                }, {
                    headers: {"Authorization": savedAuth}
                });
            } else {
                // сохранённого авторизационного заголовка нет, переходим к обычной авторизации
                doAuth(authForm);
            }

        } else {

            const doForward = () => {
                // уходим на авторизацию

                if (!!url) {
                    // await onByPass
                    onByPass();
                    // const backurl = url + "&return_url=" + encodeURIComponent(window.location);
                    // console.info("go to url " + backurl);
                    // window.location = backurl;
                    window.location.href = url;
                } else {
                    this.modalMessageService.alert(this.textUtilService.localizedText("auth.error.general")); // "Невозможно осуществить авторизацию."
                }
            };

            if (this.isAuthenticatedFull()) {
                postAction();
            } else {
                doForward();
            }
        }
    };

    public submitActionDefault(intent: ActionContext) {
        this.submitAction(intent).then((context) => this.defaultAction(intent, context));
    }

    /**
     * Пытаемся выполнить действие, при необходимости отправляя на авторизацию или подписку
     * @param intent
     */
    public submitAction(intent: ActionContext): IPromise<ExecutionContext> {
        const deferredResult = this.$q.defer<ExecutionContext>();

        if (intent instanceof PayActionContext) {

            if (intent instanceof SubMainContext && this.isSubscribedMain()) {
                deferredResult.resolve(new DefaultContext());
            } else if (intent instanceof SubPlusContext && this.isSubscribedPlus()) {
                deferredResult.resolve(new DefaultContext());
            } else {
                // на авторизацию-подписку
                this.processPayment(intent, deferredResult);
            }

        } else if (intent instanceof SongContext) {

            type ECProv = () => ExecutionContext;
            const defaultContext: ECProv = () => new DefaultContext();

            const doMain = (intent: SongContext, resolver: (ECProv) => void,  dc: ECProv = defaultContext) => {
                if (this.isSubscribedMain()) {
                    resolver(dc);
                } else {
                    const trialTx = this.trialService.createTrialTransaction(intent);
                    if (trialTx !== null) {
                        trialTx.beginAction();
                        resolver(trialTx);
                    } else {
                        // на авторизацию-подписку
                        this.processPayment(intent, deferredResult);
                    }
                }
            }

            const doPlus = (dc: TrialTransaction | ECProv = defaultContext) => {
                if (this.isSubscribedPlus()) {
                    if (dc instanceof TrialTransaction) {
                        deferredResult.resolve(() => dc);
                    } else {
                        deferredResult.resolve(dc());
                    }
                } else {
                    // на авторизацию-подписку
                    this.processPayment(intent, deferredResult);
                }
            }

            if (this.brandingService.plusIncludesRegular && this.isSubscribedPlus()) {
                deferredResult.resolve(defaultContext());
            } else if (intent.isMainSongAction()) {
                doMain(intent, deferredResult.resolve)
            } else if (intent.isPlusSongAction()) {
                console.info("isPlusSongAction");
                doMain(new SongContext(intent.song, SongAction.PERFORM), doPlus); // подменяем на не-plus действие
            } else {
                throw new Error(`Unexpected song action: ${JSON.stringify(intent)}`);
            }

        } else {
            console.info((intent));
            throw new Error(`Unexpected intent: ${JSON.stringify(intent)}`);
        }

        return deferredResult.promise;
    }

    private processPayment(
        intent: PayActionContext,
        deferredResult: IDeferred<ExecutionContext>,
        successContext: () => ExecutionContext = () => new SuccessPaymentContext()
    ) {
        if (this.brandingService.authForm !== null) {
            const onSuccess = () => deferredResult.resolve(successContext());
            const onCancel = () => deferredResult.reject("cancel-auth");

            if (intent instanceof SubMainLandingContext) {
                this.doSubscribeImpl(onSuccess, onCancel, false)
            } else {
                this.extAuth(intent, onSuccess, onCancel);
            }

        } else if (!this.isAuthenticatedFull()) {

            /*
             сначала должны авторизовать

             аутентификация не надёжная ->
             показываем popup окно с предложением аутентифицироваться и отправляем в PSM на страницу "web"
             аутентификации, на которой абонент вводит номер телефона и капчу, получает sms с кодом, вводит
             этот код на странице PSM и после завершения процедуры перенаправляется обратно в karaoke

             в karaoke проверяем, подписан ли пользователь, если подписан -> даём доступ,
             если не подписан -> показываем popup окно с aoc для подписки и если соглашается, подписываем
             */
            this.dialogAuth(intent).then((choose) => {
                switch (choose) {
                    case 'btn-success':
                        // согласились
                        this.extAuth(intent,
                            () =>
                                this.extPay(intent,
                                    () => deferredResult.resolve(successContext()),
                                    () => deferredResult.reject("cancel-sub"),
                                ),
                            () => deferredResult.reject("cancel-auth"),
                        );
                        break;
                    case 'btn-close':
                    case 'onEscape':
                    default:
                        deferredResult.reject(choose);
                }
            });

        } else {
            // можем попытаться подписать
            // аутентификация надёжная -> показываем popup окно с AoC для подписки и если соглашается, подписываем
            this.extPay(intent,
                () => deferredResult.resolve(successContext()),
                () => deferredResult.reject("cancel-sub"),
            );
        }
    }

    private extAuth(intent: ActionContext, onSuccess: () => void, onCancel: () => void, onByPass: () => void = () => this.saveIntent(intent)) {
        this.doAuthorize(onSuccess, onCancel, onByPass);
    }

    private extPay(intent: ActionContext, onSuccess: () => void, onCancel: () => void, onByPass: () => void = () => this.saveIntent(intent)) {
        // рекурсивно / нерекурсивно main и plus
        this.dialogPay(intent).then((choose) => {
            switch (choose) {
                case 'btn-subscribe-main':
                    this.doSubscribeImpl(() => onSuccess(), () => onCancel(), false);
                    break;
                case 'btn-subscribe-plus':
                    this.doSubscribeImpl(() => onSuccess(), () => onCancel(), true);
                    break;
                case 'btn-buy':
                    // todo: протащить идентификтор песни?
                    throw new Error('not implemented');
                case 'btn-no':
                case 'onEscape':
                default:
                    console.info('cancel: ' + choose);
                    console.info(intent);
                    onCancel();
            }
        });
    }

    private defaultAction(restoredIntent: ActionContext, context: ExecutionContext) {
        const msgRel = (message) => this.modalMessageService.alert(message, () => this.$route.reload());
        const lang = this.textUtilService.currentLanguage;
        const srvName = this.brandingService.serviceName(lang);
        const srvPlusName = this.brandingService.servicePlusName(lang);
        const newPay = context instanceof SuccessPaymentContext;

        if (restoredIntent instanceof SubMainContext) {
            if (newPay) {
                // `Добро пожаловать в «${srvName}»! Вам доступен весь каталог песен!`
                msgRel(this.textUtilService.localizedText("payment.access.sub-main-done", {"srvName": srvName}));
            } else {
                // `Добро пожаловать в «${srvName}»! Вам уже доступен весь каталог песен!`
                msgRel(this.textUtilService.localizedText("payment.access.sub-main-exist", {"srvName": srvName}));
            }

        } else if (restoredIntent instanceof SubPlusContext) {
            if (newPay) {
                // `Добро пожаловать в «${srvPlusName}»! Вам доступны дополнительные функции!`
                msgRel(this.textUtilService.localizedText("payment.access.sub-main-done", {"srvName": srvPlusName}));
            } else {
                // `Добро пожаловать в «${srvPlusName}»! Вам уже доступны дополнительные функции!`
                msgRel(this.textUtilService.localizedText("payment.access.sub-main-exist", {"srvName": srvPlusName}));
            }

        } else if (restoredIntent instanceof SongContext) {
            // todo: анализировать тип действия
            // todo: честное затаривание плейлиста

            // msgRel(`Добро пожаловать в «${srvName}»! Вам доступен весь каталог песен!`);

            const msg = this.textUtilService.localizedText("payment.access.sub-main-done", {"srvName": srvName});
            this.modalMessageService.alert(msg, () => {
                if (this.playerService.loadContext(restoredIntent, '/', 'playback')) {
                    this.$rootScope.go("/jukebox");
                } else {
                    console.warn(`Intent without song ${JSON.stringify(restoredIntent)}`);
                    this.$rootScope.go("/");
                }

                /*
                const song = restoredIntent.song;
                if (!song) {
                    this.PlayerService.loadContext(restoredIntent
                    this.PlayerService.load(song.title, '/', [song], song, 'playback');
                    this.$rootScope.go("/jukebox");
                } else {
                    console.warn(`Intent without song ${JSON.stringify(restoredIntent)}`);
                    this.$rootScope.go("/");
                }*/
            });
        }
    }

    /*public actionAvailability(intent: ActionContext): boolean {
        if (intent instanceof )
    }*/
    
    private dialogAuth(intent: ActionContext) : IPromise<string> {
        // 'Для продолжения нам необходимо авторизовать Вас'
        const msg = this.textUtilService.localizedText("auth.required");
        const btnSuccess = this.textUtilService.localizedText("auth.button.continue"); // 'Продолжить'
        const btnClose = this.textUtilService.localizedText("auth.button.cancel"); // 'Отмена'
        return this.dialog(msg, 'modal-auth', {'btn-success': btnSuccess, 'btn-close': btnClose});
    }

    private dialogPay(intent: ActionContext) : IPromise<string> {
        const br = this.brandingService;

        let msg = "";
        let btn: { [key: string]: string } = {'btn-no': this.textUtilService.localizedText("auth.button.continue")}; // 'Продолжить'

        const lang = this.textUtilService.currentLanguage;
        const labelYes = this.textUtilService.localizedText("auth.button.yes"); // 'Да'
        const labelNo = this.textUtilService.localizedText("auth.button.no"); // 'Нет'
        const labelBuy = this.textUtilService.localizedText("auth.button.buy"); // 'Купить'

        console.info(`${intent instanceof SongContext} ${this.trialService.isTryAllowed(intent)} ${this.isSubscribedMain()}`);

        // todo: разрулить ситуацию, когда взаимосиключающая подписка
        if (
            (intent instanceof SubMainContext) ||
            (intent instanceof SongContext && intent.isMainSongAction()) ||
            (intent instanceof SongContext && intent.isPlusSongAction() && !this.isSubscribedMain() && !this.trialService.isTryMainAllowed(intent))
        ) {
            const trafficText = br.trafficText(this.textUtilService);
            const priceText = br.subscriptionPrice(this.textUtilService)
            // msg += `Абонентская плата за доступ к сервису «${br.serviceName}» составляет <br/><b>${priceText}</b>`;
            msg += this.textUtilService.localizedText("payment.dialog.access",
                {"srvName": br.serviceName(lang) , "price": `<br/><b>${priceText}</b>`, 'interpolation': {'escapeValue': false}})
            msg += !!trafficText ? `<br/><b>${trafficText()}</b>` : '';
            // msg += `<br/> Вы можете активировать подписку прямо сейчас.`;
            msg += "<br/> " + this.textUtilService.localizedText("payment.dialog.activate");

            const singleTrackPrice = br.singleTrackPrice(this.textUtilService);
            if (!!singleTrackPrice) {
                // msg += `<br/> Также вы можете купить доступ только к данному музыкальному треку на 24 часа за <b>${br.singleTrackPrice}</b>`;
                msg += this.textUtilService.localizedText("payment.dialog.buy-track",
                    {"singleTrackPrice": `<b>${singleTrackPrice}</b>`, 'interpolation': {'escapeValue': false}})
                btn = {'btn-subscribe-main': labelYes, 'btn-buy': labelBuy};
            } else {
                // msg += `<br/> Активировать?`;
                msg += "<br/> " + this.textUtilService.localizedText("payment.dialog.ask");
                btn = {'btn-subscribe-main': labelYes, 'btn-no': labelNo};
            }
        } else if (
            (intent instanceof SubPlusContext) ||
            (intent instanceof SongContext && intent.isPlusSongAction() && !this.isSubscribedPlus())
        ) {
            const subscriptionPlusPrice = br.subscriptionPlusPrice(this.textUtilService);
            // msg += `Абонентская плата за доступ к сервису «${br.servicePlusName}» составляет <br/><b>${subscriptionPlusPrice}</b>`;
            msg += this.textUtilService.localizedText("payment.dialog.access",
                {"srvName": br.servicePlusName(lang) , "price": `<br/><b>${subscriptionPlusPrice}</b>`, 'interpolation': {'escapeValue': false}})
            // msg += `<br/> Вы можете активировать подписку прямо сейчас.`;
            msg += "<br/> " + this.textUtilService.localizedText("payment.dialog.activate");
            // msg += `<br/> Активировать?`;
            msg += "<br/> " + this.textUtilService.localizedText("payment.dialog.ask");
            btn = {'btn-subscribe-plus': labelYes, 'btn-no': labelNo};
        }

        if (msg === '') {
            // error state
            console.error('no dialog');
            console.error(intent)
        }

        return this.dialog(msg === '' ? 'n/a' : msg, 'modal-subscribe-song', btn);
    }

    private dialog(message: string, className: string, buttonsClasses: { [key: string]: string }): IPromise<string> {
        const defer: IDeferred<string> = this.$q.defer<string>();
        const buttons = {};
        for (let cls in buttonsClasses) {
            let value = buttonsClasses[cls];
            buttons[cls.replace('btn-', '')] = {
                label: value,
                className: cls,
                callback: () => {
                    defer.resolve(cls)
                }
            };
        }
        const dialogRef = this.modalMessageService.dialogExt({
            className: className,
            message: message,
            //title: "Custom title",
            buttons: buttons,
            onEscape: function() {
                // отказались, aoc скрыли
                defer.resolve('onEscape');
            }
        });
        return defer.promise;
    }

    private saveIntent(intent: ActionContext): void {
        const serialized = serialize(new ActionContextSerializerWrapper(intent));
        this.storageService.set(TARGET_ACTION_STORAGE_KEY, serialized);
    }

    private restoreIntent(): ActionContext | null {
        const loaded = this.storageService.get(TARGET_ACTION_STORAGE_KEY);
        if (loaded === null) {
             return null;
        } else {
            const str = JSON.stringify(loaded);
            this.storageService.remove(TARGET_ACTION_STORAGE_KEY);
            const deserialized : ActionContextSerializerWrapper = deserialize(ActionContextSerializerWrapper, str);
            return deserialized.content;
        }
    }
}
