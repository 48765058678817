import "./ButtonRowComponent.less";
import buttonTemplate from "./ButtonRowTemplate.html";

export default {
    transclude: true,
    bindings: {
        action: "&",
        state: "@",
        title: "@",
        description: "@"
    },
    template: buttonTemplate
};