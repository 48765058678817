import template from "./PlayItemTemplate.html";

export default {
    transclude: false,
    bindings: {
        name: "<",
        author: "<",
        action: "&",
        playlistAction: "&",
        added: "<"
    },
    template: template,
    controller: PlayItemComponent
};

function PlayItemComponent($scope) {
}

PlayItemComponent.$inject = ["$scope"];