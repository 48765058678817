import {SongAction} from './SongAction';
import BrandingService from "../../../../branding/BrandingService";

export default class UserPaymentProfile {

    /**
     * @param brandingService
     * @param subMain {boolean}
     * @param subPlus {boolean}
     * @param songs {Array<string>}
     * @param plusAvailable {boolean} доступность plus-действий
     */
    constructor(
        private readonly brandingService: BrandingService,
        public readonly subMain: boolean = false,
        public readonly subPlus: boolean = false,
        public readonly songs: Array<string> = [],
        public readonly plusAvailable: boolean = false,
    ) {
    }

    /**
     * @returns {boolean}
     */
    public hasSong(songId: string): boolean {
        return this.songs.includes(songId);
    }

    /**
     * Проверка доступности действия
     * @param songId {String}
     * @param songAction {SongAction}
     * @returns {boolean}
     */
    public isSongAllowed(songId: string, songAction: SongAction): boolean {

        return !(this.subMain && this.brandingService.coreActions.indexOf(songAction))
            ? !!(this.subPlus && this.brandingService.plusActions.indexOf(songAction)) : true;

        /*switch (songAction) {
            case SongAction.PERFORM:
            case SongAction.PERFORM_RECORD:
                return this.subMain;

            case SongAction.PERFORM_BATTLE:
            case SongAction.PERFORM_CONCERT:
                return this.subPlus;

            default:
                return false;

        }*/
    }

    public isActionAvailable(songAction: SongAction): boolean {
        return !(!!this.brandingService.coreActions && this.brandingService.coreActions.indexOf(songAction) > -1)
            ? !!(this.subPlus && !!this.brandingService.plusActions && this.brandingService.plusActions.indexOf(songAction) > -1) : true;

        /*switch (songAction) {
            case SongAction.PERFORM:
            case SongAction.PERFORM_RECORD:
                return true;

            case SongAction.PERFORM_BATTLE:
            case SongAction.PERFORM_CONCERT:
                return this.subPlus;

            default:
                return false;

        }*/
    }
}