type BootboxDialogCommand = 'hide'

interface BootboxDialogRef extends JQuery {
    modal(command: BootboxDialogCommand);
}

export default class ModalMessageService {

    private bootbox: BootboxStatic

    constructor(
    ) {
        this.bootbox = (window as any).bootbox as any; // todo
    }

    public alert = (text: string, callback: () => void = () => {}) : void => { this.bootbox.alert(text, callback) }

    public confirm(message: string, callback: (result: boolean) => void) { this.bootbox.confirm(message, callback) }

    public confirmExt(options: BootboxConfirmOptions) { this.bootbox.confirm(options) }

    public dialogExt(options: BootboxDialogOptions<string>) : BootboxDialogRef { return this.bootbox.dialog(options) as BootboxDialogRef }

    public hideAll(): void { this.bootbox.hideAll() }
}