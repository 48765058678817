import "./ShareSocialComponent.less";
import template from "./ShareSocialTemplate.html";

export default {
    transclude: false,
    bindings: {
        title: "<",
        text: "<",
    },
    template: template,
    controller: ShareSocialComponentController
};

function ShareSocialComponentController($scope, $window, $location) {

    /* https://github.com/bradvin/social-share-urls */

    function share(url) {
        $window.open(url, 'share', 'toolbar=0,status=0,width=626,height=436');
    }

    $scope.shareVK = () => {
        console.info($scope.$ctrl);
        const title = encodeURIComponent($scope.$ctrl.title);
        const text = encodeURIComponent($scope.$ctrl.text);
        const url = encodeURIComponent($location.absUrl());
        const serviceUrl = `http://vk.com/share.php?noparse=true&title=${title}&comment=${text}&url=${url}`;
        share(serviceUrl);
    };

    $scope.shareFB = () => {
        const url = encodeURIComponent($location.absUrl());
        const serviceUrl = `https://www.facebook.com/sharer.php?u=${url}`;
        share(serviceUrl);
    };

    $scope.shareOK = () => {
        const url = encodeURIComponent($location.absUrl());
        const serviceUrl = `https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=${url}`;
        share(serviceUrl);
    };
}

ShareSocialComponentController.$inject = ["$scope", "$window", "$location"];