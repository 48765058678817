import "./PromoComponent.less";
import template from "./PromoTemplate.html";

export default {
    transclude: true,
    bindings: {
        header: "@",
        action: "&",
        images: "<",
        items: "<",
        playlistAction: "<"
    },
    template: template,
    controller: PromoComponent
};

function PromoComponent($scope) {
    $scope.items = [];
    // $scope.songs = [];
    this.$onChanges = function (changes) {
        if (changes.items) {
            if (!!changes.items.currentValue && !!changes.items.currentValue.then) {
                changes.items.currentValue.then((items) => {
                    $scope.items = items;
                    /*let songs = [];
                    $scope.items.forEach((tile) => {
                        if (!!tile.source) {
                            songs.push(tile.source);
                        }
                    });
                    $scope.songs = songs;*/
                });
            } else {
                /*console.info(changes.items.currentValue);*/
            }
        }
    };
}

PromoComponent.$inject = ["$scope"];