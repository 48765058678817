import {IPlayerEngine, SongSource, PlayerError} from "../components/IPlayerEngine";
import PlayerStatus from "../components/PlayerStatus";
import ConvertTime from "../components/ConvertTime";
import PlayerEngine from "../components/PlayerEngine";

export enum PlayerState {
    LOAD = 'load',
    PAUSE = 'pause',
    STOP = 'stop',
    PLAY = 'play'
}

export type SongSourceProvider = () => SongSource
export type PlayerDataHandler = (PlayerError, SongSource, number) => SongSource

class Content {

    private _state: PlayerState;
    private _timeline: Array<TimeLine>

    constructor(state: PlayerState, timeline: Array<TimeLine>) {
        this._state = state;
        this._timeline = timeline;
    }


    get state(): PlayerState {
        return this._state;
    }

    set state(value: PlayerState) {
        this._state = value;
    }

    get timeline(): Array<TimeLine> {
        return this._timeline;
    }

    set timeline(value: Array<TimeLine>) {
        this._timeline = value;
    }
}

export class TimeLine {

    private _progress: number;
    private _duration: number;
    private _current: string;
    private _end: string;

    constructor(progress: number, duration: number, current: string, end: string) {
        this._progress = progress;
        this._duration = duration;
        this._current = current;
        this._end = end;
    }

    get progress(): number {
        return this._progress;
    }

    get duration(): number {
        return this._duration;
    }

    set progress(value: number) {
        this._progress = value;
    }

    get current(): string {
        return this._current;
    }

    set current(value: string) {
        this._current = value;
    }

    get end(): string {
        return this._end;
    }

    set end(value: string) {
        this._end = value;
    }
}

export class PlayerDeck<S extends SongSource> {

    private _audioContext: AudioContext;
    private playerBuilder: (source: S, index: number) => IPlayerEngine;
    private content: Content;
    private convertTime: ConvertTime = new ConvertTime();

    private data: Array<S>;
    private engines: Array<IPlayerEngine>;
    private errorHandler: PlayerDataHandler;
    private timeUpdateListeners: Array<PlayerDataHandler>;
    private loadCount: number = 0;

    constructor(
        playerBuilder: (source: S, index: number) => IPlayerEngine,
        data: Array<S>,
        errorHandler: PlayerDataHandler
    ) {

        this.playerBuilder = playerBuilder;
        this.data = data;
        this.engines = new Array<IPlayerEngine>(data.length);
        this.errorHandler = errorHandler;
        this.timeUpdateListeners = [];
        this._audioContext = PlayerEngine.createAudioContext();

        const timeline = new Array<TimeLine>(data.length)
        data.forEach((value, index) => timeline[index] = new TimeLine(0, 0, '00:00', '00:00'));

        this.content = new Content(PlayerState.LOAD, timeline);
    }

    get audioContext(): AudioContext {
        return this._audioContext;
    }

    get state(): PlayerState {
        return this.content.state;
    }

    set state(newState: PlayerState) {
        this.content.state = newState;
    }

    public play(): void {
        if (this.allPlayersLoaded() && this.content.state !== PlayerState.PLAY) {
            this.engines.forEach((engine) => engine.play());
            this.content.state = PlayerState.PLAY;
        }
    }

    public pause(): void {
        if (this.allPlayersLoaded() && this.content.state !== PlayerState.PAUSE) {
            this.engines.forEach((engine) => engine.pause());
            this.content.state = PlayerState.PAUSE;
        }
    }

    /*set state(newState: PlayerState) {
        const _this =  this;
        _this.engines.forEach((engine) => {
            switch (newState) {
                case PlayerState.LOAD:
                    break;
                case PlayerState.PLAY:
                    engine.play()
                    break;
                case PlayerState.PAUSE:
                    engine.pause()
                    break;
                case PlayerState.STOP:
                    engine.pause()
                    break;
                default:
                    throw new Error(`Unexpected state: ${newState}`)
            }

        });
        _this.content.state = newState;
    }*/

    get timeLine(): TimeLine {
        return !!this.content ? this.content.timeline[0] : new TimeLine(0, 0, "", "");
    }

    init() {
        const _this =  this;
        this.data.forEach((source: S, index: number) => {
            const engine: IPlayerEngine = this.playerBuilder(source, index);
            // console.info(`create engine ${index}`);
            // console.info(engine);
            // todo: error
            _this.addPlayer(index, engine.init());


            /*source.$player().jPlayer({
                ready: function () {
                    console.info("PlayerModel ready");
                    $(this).jPlayer("setMedia", source.urlSet())/!*.jPlayer("pause")*!/;
                },
                error: (event) => {
                    console.info("PlayerModel error: " + event);
                    if (!!_this.errorHandler && typeof _this.errorHandler === "function") {
                        _this.errorHandler(event, source, index);
                    }
                },
                loadeddata: function (event) {
                    // todo analyze index
                    _this.addPlayer(index, $(this));
                    _this.state = 'stop';
                },
            })*/
        });
    }

    protected addPlayer(index: number, engine: IPlayerEngine): void {
        const _this =  this;
        engine.addOnTimeUpdate((status: PlayerStatus) => _this.timeUpdate(index, status));
        engine.addOnEnded(() => _this.content.state = PlayerState.STOP);
        engine.addOnLoadedData(() => {
            _this.loadCount++;
            if (_this.allPlayersLoaded()) {
                _this.content.state = PlayerState.STOP
            }
        });

        this.engines[index] = engine;
    }

    private allPlayersLoaded() {
        return this.loadCount === this.engines.length;
    }

    getPlayer(index): IPlayerEngine {
        return this.engines[index];
    }

    public timeUpdate(index: number, status: PlayerStatus): void {
        const _this =  this;
        // todo PlayerStatus ?
        _this.content.timeline[index] = new TimeLine(status.progress, status.duration, _this.convertTime.time(status.currentTime), _this.convertTime.time(status.duration));
        _this.timeUpdateListeners?.forEach(listener => listener(status, _this.data[index], index));
    }

    public addOnTimeUpdate(listener: PlayerDataHandler): void {
        this.timeUpdateListeners.push(listener);
    }

    public setVolume(index: number, value: number): void {
        // console.info(`setVolume ${index}, ${value} for ${this.engines[index]}`);
        // this.pause()
        this.engines[index].setVolume(value);
        // this.play()
    }

    public getVolume(index: number): number {
        return this.engines[index].getVolume();
    }

    public setCurrentTime(currentTime: number) {
        this.engines.forEach((engine) => engine.setCurrentTime(currentTime));
    }

    public destroy(): void {
        // console.info("DESTROY");
        if (!!this.engines) {
            this.engines.forEach(engine => engine.destroy());
        }
    }
}