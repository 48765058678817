// костыль для загрузки dev-стилей на Android 2.3
import "./polyfill/bind-polyfill";

//import "./karaoke-thirdparty.js"
import nprogesss from "./general/angular-nprogress"; // прогресс-бар при асинхронной загрузке данных

// angular-carousel
import "./polyfill/requestAnimationFrame-polyfill";

// ngImago
// import "./polyfill/matchMedia-polyfill";

import routes from "./routes.js";

import AuthInterceptor from "./karaoke/AuthInterceptor";

import karaokeConfig from "./general/karaoke-config";
import karaokeRun from "./general/karaoke-run";

/**
 *
 * @param angular: angular.IAngularStatic
 * @param init
 * @param opRes
 * @returns {*}
 */
const loadAngular = function (angular, init, opRes) {

    const pagesContext = require.context('./pages/', true, /.html$/);

    /**
     * Поиск стилей
     * @param opRes
     * @param prefix
     * @returns {null|*}
     */
    function resource(opRes, prefix) {
        let result = [];
        if (!!opRes) {
            opRes.keys().forEach(function (key) {
                if (key.indexOf(prefix) > -1) {
                    const value = opRes(key);
                    result.push(value);
                }
            });
        }
        if (result.length === 0) {
            return null;
        } else if (result.length === 1) {
            return result[0];
        } else {
            console.warn("multiple resources found for " + prefix);
            console.warn(result);
            return result[0];
        }

    }

    function noDirectoryComponentName(name, typePostfix) {
        const rx = new RegExp("^(.+)" + typePostfix + "$");
        if (name.match(rx)) {
            return name
                .replace(/.*\//, ""); // удаляем поддиректории
        } else {
            return name;
        }
    }

    function camelComponentName(name, typePostfix) {
        const rx = new RegExp("^(.+)" + typePostfix + "$");
        if (name.match(rx)) {
            return noDirectoryComponentName(name, typePostfix)
                .replace(rx, "$1") // получаем основное название
                .replace(/^\w/, chr => chr.toLowerCase()); // первый символ - строчный
        } else {
            return name;
        }
    }

    function dashComponentName(name, typePostfix) {
        const rx = new RegExp("^(.+)" + typePostfix + "$");
        if (name.match(rx)) {
            return camelComponentName(name, typePostfix)
                .replace(/\.?([A-Z]+)/g, (x, y) => "-" + y.toLowerCase())  // преобразуем в dash-style
                .replace(/^-/, "");
        } else {
            return name;
        }
    }

    /**
     * Функция групповой инициализации данных
     * @param ngMethod конфигурациионный метод ангуляра, 1 параметр - имя модуля, 2 - функция/объект
     * @param components список компонент для инициализации
     * @param typePostfix тип компоненты, соотвествующий постфиксу
     */
    function populate(ngMethod, components, typePostfix) {
        components.keys().forEach(function (key) {
            const controllerName = key.replace(/\.\/(.*)\.(\w+)/g, "$1");
            const module = components(key);
            const controller = module.default;

            if (!!controller) {
                // var name = controller.name ? controller.name : controllerName; // при обфускации теряется имя
                let name = controllerName;
                switch (typePostfix) {
                    case 'Directive':
                    case 'Component':
                        const originalName = name;
                        const styleName = dashComponentName(originalName, typePostfix);
                        const componentName = camelComponentName(originalName, typePostfix);
                        const styles = resource(opRes, originalName);

                        // console.info(`originalName: ${originalName}`);
                        // console.info(`componentName: ${componentName}`);
                        // console.info(`styleName: ${styleName}`);
                        // console.info(`Component tpl: ${controller.template}`);

                        if (styles !== null && !!controller.template) {
                            const className = !!styles[styleName] ? styles[styleName] : (!!styles.default ? styles.default[styleName] : null);
                            // const className = styles[styleName];
                            if (!!className) {
                                // console.info(`inject into ${componentName} component: supply '${styleName}' with '${className}'`);

                                // controller.template = controller.template.replace('___' , className); //

                                const attrMatcher = /(?:class *= *['"]{0,1})((?:[\w -](?!\w+=|\/))+)['"]*/i;
                                // const classMatcher = /[^\s]+_(?: +|$)/g;
                                const classMatcher = new RegExp(styleName, 'g');
                                controller.template = controller.template.replace(attrMatcher, (full, capture) =>
                                    "class=\"" + capture.replace(classMatcher, `${styleName} ${className}`) + "\""); // оригинальное + новое

                            } else {
                                console.warn(`className ${styleName} not found in given resource`);
                                console.warn(styles);
                            }
                        }

                        ngMethod(componentName, controller);
                        break;
                    case 'Controller':
                    case 'Service':
                        ngMethod(noDirectoryComponentName(name, typePostfix), controller);
                        break;
                    default:
                        console.error(`Unknown type: ${typePostfix}`);
                }
            } else {
                console.warn("Component '" + controllerName + "' are empty");
                console.warn(module);
            }
        });
    }

    // инициализация
    // const services = angular.module("karaokeServices", []);
    // populate(services.service, require.context("./karaoke/", true, /Service\.[tj]s$/), 'Service');

    const app = angular.module("karaokeApp", [
        // "karaokeServices",
        "ngRoute", "ngCookies", "ngResource", "angular-carousel", "ipCookie", "ngImago", "rzSlider", "ngSanitize", "jm.i18next"/*, "cfp.hotkeys"*/
    ]);

    /*app.directive("ngTouch", [function () {
        return function (scope, elem, attrs) {
            elem.bind("touchstart click", function (e) {
                e.preventDefault();
                e.stopPropagation();

                console.info("event: ");
                console.info(e);

                scope.$apply(attrs["ngTouch"]);
            });
        };
    }]);*/

    populate(app.component, require.context("./karaoke/", true, /Component\.[tj]s$/), 'Component');
    populate(app.controller, require.context("./karaoke/", true, /Controller\.[tj]s$/), 'Controller');
    populate(app.directive, require.context("./karaoke/", true, /Directive\.[tj]s$/), 'Directive');
    populate(app.service, require.context("./karaoke/", true, /Service\.[tj]s$/), 'Service');

    // app.component('player', PlayerComponent);

    // populate(app.component, require.context("./karaoke/", true, /Component\.ts$/), 'Component');

    app.config(AuthInterceptor)
        .config(nprogesss)
        .config(karaokeConfig)
        .config(["ngImagoProvider", (ngImagoProvider) => {
            ngImagoProvider.defaultsSettings({avoid_cache: false});
        }])
        /*.config(["HotkeyProvider", (hotkeysProvider) => {
            hotkeysProvider.includeCheatSheet = false;
        }])*/
        .config(["$routeProvider", function ($routeProvider) {

                /**
                 * Конфигурация путей находитсяResolverPlugin в resources/webapp/routes.js
                 * и подставляется на этапе сборки
                 * @type Array
                 */
                if (!!routes && routes.length > 0) {

                    for (let i = 0; i < routes.length; i++) {
                        const rule = routes[i];

                        if (rule.path) {
                            $routeProvider.when(rule.path, rule.route);
                        } else {
                            $routeProvider.otherwise(rule.route);
                        }

                        if (!!pagesContext) {
                            const path = rule.route.templateUrl.replace("./pages/", "./");
                            const template = pagesContext(path).default;
                            if (!!template) {
                                delete rule.route.templateUrl;
                                rule.route.template = template;
                            } else {
                                console.warn(`unable to load template from context ${rule.route.templateUrl}`);
                            }
                        }

                        /*import(
                            /!* webpackChunkName: "page-[request]" *!/
                            /!* webpackMode: "eager" *!/
                            `./pages/${path}`).then(templateModule => {
                            rule.route.template = templateModule.default;
                        })*/
                    }
                } else {
                    throw new Error("Routes not found");
                }
        }])

        .run(["$templateCache", ($templateCache) => {
            // заполнение $templateCache элементами, использующимися в ng-include
            // в качестве первого параметра дб строка, не переменная, это важно (магия webpack)
            // const elements = require.context('./pages/elements/', true, /.html$/);
            pagesContext.keys()
                .filter(key => key.startsWith('./elements/'))
                .forEach(key => $templateCache.put(key.replace('./elements/', './pages/elements/'), pagesContext(key).default));
        }])
        .run(karaokeRun);

    if (typeof init === "function") {
        init(app, angular);
    }
    
    return app;
};

export default {
    // run: (init) => require(['./general/angular-starter'], (starter) => starter.run(() => loadAngular(angular, init)))
    run: (init, opRes) => import('./general/angular-starter').then(starter => starter.run(() => loadAngular(angular, init, opRes)))
};

