export default class PromoWideController {

    constructor($scope, TextUtilService) {

        $scope.$watchGroup(['user', 'playlist', 'popularSongs'], function(newValues, oldValues, scope) {

            const playlistBlock = (!!$scope.user && $scope.user.paymentProfile.subMain && $scope.playlist.songs && $scope.playlist.songs.length > 0);

            $scope.promoSongs = playlistBlock ? $scope.playlist.songs : $scope.popularSongs;
            // $scope.promoTitle = playlistBlock ? 'playlist.my.title' : 'playlist.popular.title';
            $scope.promoTitle = playlistBlock ? TextUtilService.localizedText('playlist.my.title') : null; // уже показываем раздел популярного
            $scope.goPromo = () => playlistBlock ? $scope.go('playlist/') : $scope.go('playlist/popular');

            $scope.playPromoAction = (song) => playlistBlock ?
                    $scope.playAction(song, $scope.promoTitle, '/playlist', $scope.playlist.songs, 'banner') :
                    $scope.playAction(song, $scope.promoTitle, '/playlist/popular', null, 'banner');

        });
    }
}

PromoWideController.$inject = ['$scope', 'TextUtilService'];