export class Block {
    constructor(
        public timeStart: number,
        public timeEnd: number,
        public data: Array<Syllable> = new Array<Syllable>()
    ) {
    }
}

export class Syllable {
    constructor(
        public letters: string,
        public line: number,
        public timeStart: number,
        public timeEnd: number,
        public len: number,
        public fragment: number,
        public note: number
    ) {
    }
}

export class Fragment {
    constructor(
        public letters: string,
        public line: number,
        public len: number,
        public note: number
    ) {
    }
}

export class IPoint {
    public type: string;
}

export class Couplet implements IPoint {

    private _type = 'Couplet';

    constructor() {
    }

    get type(): string {
        return this._type;
    }
}

export class FragmentPoint implements IPoint {
    constructor(
        public type: 'startFragment' | 'endFragment',
        public time: number,
        public line: number,
        public fragment: number
    ) {
    }
}

export default class SubtitleService {

    constructor() {
    }

    public parse(data: string): Array<Block> {

        let result = Array<Block>();
        let curBlock: Block = null;

        /*if (_this.fragments && _this.points) return;*/

        let fragments: Array<Fragment> = new Array<Fragment>();
        let points: Array<IPoint> = new Array<IPoint>();
        points.push(new Couplet());

        let curLine = 1;

        //var begin = true;
        let lastTimeEnd = null;

        data.split("\n").map((lines) => {
                if (!lines.length) {
                    /** формируем метку нового куплета */
                    points.push(new Couplet());
                    curLine = 0;
                } else if (lines.length > 1) {
                    lines.split("|").map(function (point) {
                        /** Добавляем фрагмент и формируем метки его начала и конца */
                        const fragment_num = fragments.length;
                        const timeStart = Math.round(parseFloat(point.split('^')[0]) * 1000);
                        const timeEnd = Math.round(parseFloat(point.split('^')[1]) * 1000);
                        const pointArr = point.split('^');
                        const letters = pointArr.length >= 4 ? pointArr[3].replace(/\s/g, '&nbsp;') : '';
                        const note = pointArr.length >= 4 ? parseInt(pointArr[2], 10) : 0;

                        fragments.push(new Fragment(letters, curLine, timeEnd - timeStart, note));
                        points.push(new FragmentPoint('startFragment', timeStart, curLine, fragment_num));
                        points.push(new FragmentPoint('endFragment', timeEnd, curLine, fragment_num));

                        if (curBlock == null) {
                            curBlock = new Block(timeStart, timeEnd);

                            // fix для того чтобы не подсвечивался сразу же первый блок
                            if (lastTimeEnd == null) {
                                curBlock.timeStart = 0;

                                curBlock.data.push(new Syllable('',
                                    0,
                                    0,
                                    timeStart,
                                    timeStart,
                                    0,
                                    note));

                                lastTimeEnd = 0;
                            } else if (timeStart != lastTimeEnd) {
                                // чтобы не было пустых моментов
                                curBlock.timeStart = lastTimeEnd;

                                curBlock.data.push(new Syllable(
                                    '',
                                    0,
                                    lastTimeEnd,
                                    timeStart,
                                    timeEnd - timeStart,
                                    0,
                                    note));
                            }

                        } else {
                            curBlock.timeEnd = timeEnd;

                            lastTimeEnd = timeEnd;
                        }

                        curBlock.data.push({
                            letters: letters,
                            line: curLine,
                            timeStart: timeStart,
                            timeEnd: timeEnd,
                            len: timeEnd - timeStart,
                            fragment: fragment_num,
                            note: note
                        });
                    });
                } else {
                    return;
                }
                //curLine = (curLine == 1) ? 2 : 1;  // чередование строк 1/2

                if (curLine % 2 == 0) {
                    result.push(curBlock);
                    curBlock = null;
                } else {
                    curBlock.data.push(null); // break
                }

                curLine++;
            }
        );
        return result;
    };
}