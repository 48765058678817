import Song from "../entity/Song";
import {karaoke} from "../karaoke";
import StorageService from "./StorageService";
import SongListCompressorService from "./SongListCompressorService";
import {ActionContext, SongContext} from "./entity/ActionContext";
import {SongAction} from "./entity/SongAction";
import IRootScopeService = karaoke.IRootScopeService;

// todo: do not export
export class InternalPlayerData {
    public context: InternalPlayerContext ;
    public playlist: InternalPlayerPlaylist;
    public controls: InternalPlayerControls;

    constructor(
        context: InternalPlayerContext,
        playlist: InternalPlayerPlaylist = new InternalPlayerPlaylist(),
        controls: InternalPlayerControls = new InternalPlayerControls()
    ) {
        this.context = context;
        this.playlist = playlist;
        this.controls = controls;
    }
}

class InternalPlayerContext {
    public title: string;
    public url: string;
    public source: string;

    constructor(title: string, url: string, source: string) {
        this.title = title;
        this.url = url;
        this.source = source;
    }
}

class InternalPlayerPlaylist {
    public trackList: Song[] | SongContext[];
    public index: number;

    constructor(trackList: Song[] | SongContext[] = [], index: number = 0) {
        this.trackList = trackList;
        this.index = index;
    }
}

class InternalPlayerPlaylistCompressed {
    public trackList: number[] = [];
    public index: number = 0;

    constructor(trackList: number[] = [], index: number = 0) {
        this.trackList = trackList;
        this.index = index;
    }
}

class InternalPlayerControls {
    public loop: boolean;

    constructor(loop: boolean = false) {
        this.loop = loop;
    }
}

export default class PlayerService {

    static $inject = ['$rootScope', 'StorageService', 'SongListCompressorService']

    private static COOKIE_KEY = "t2k_Player";
    public playerData: InternalPlayerData; // todo: private

    constructor(
        private readonly $rootScope: IRootScopeService,
        private readonly StorageService: StorageService,
        private readonly SongListCompressorService: SongListCompressorService
    ) {
        // this.playerData = this.defaultPlayerData();

        // загрузка сохранённого состояния и оповещение о текущем треке
        const that = this;
        const cookieValue = StorageService.get(PlayerService.COOKIE_KEY);
        if (cookieValue == null) {
            // создаём пустой плейлист
            that.playerData = PlayerService.defaultPlayerData();
        } else {
            try {
                SongListCompressorService.deCompress(cookieValue.playlist.trackList, function (loadedTracks) {
                    cookieValue.playlist.trackList = loadedTracks == null ? [] : loadedTracks;
                    if (cookieValue.playlist.index >= cookieValue.playlist.trackList.length) {
                        // изменилась длина плейлиста так что индекс вышел за пределы, сбрасываем id
                        cookieValue.playlist.index = 0;
                    }

                    that.playerData = cookieValue;

                    $rootScope.$broadcast('player:initPlayerData', that.playerData);
                    //$rootScope.$broadcast('player:currentTrack', that.playerData.playlist[that.playerData.index]);
                });
            } catch (e) {
                console.warn("wrong value in cookie, init with default data");
                console.info(PlayerService.COOKIE_KEY);
                that.playerData = PlayerService.defaultPlayerData();
            }
        }
    }

    private static defaultPlayerData() : InternalPlayerData {
        return new InternalPlayerData(new InternalPlayerContext('Контекст', null, 'catalog'));
    }

    private save(playerData: InternalPlayerData): void {
        if (!!playerData == null && !!playerData.playlist && !!playerData.playlist.trackList) {
            let songsToSerialize = [];
            playerData.playlist.trackList.forEach((item) => {
                if (item instanceof Song) {
                    songsToSerialize.push(item);
                } else if (item instanceof SongContext) {
                songsToSerialize.push(item.song);
                } else {
                    console.error(`unexpected item: ${JSON.stringify(item)}`);
                    console.error(item);
                    songsToSerialize.push(this.hackSong(item));
                }
            })

            this.StorageService.set(PlayerService.COOKIE_KEY, {
                context: playerData.context,
                playlist: new InternalPlayerPlaylistCompressed(
                    this.SongListCompressorService.compress(songsToSerialize),
                    playerData.playlist.index
                ),
                controls: playerData.controls
            });
        } else {
            this.StorageService.remove(PlayerService.COOKIE_KEY);
        }
    };

    /**
     * @deprecated
     */
    /*public currentSong(): Song {
        return this.playerData.playlist.trackList[this.playerData.playlist.index];
    };*/

    public currentContext(): SongContext | null {
        if (this.playerData.playlist.trackList.length === 0) {
            return null;
        } else {
            const item = this.playerData.playlist.trackList[this.playerData.playlist.index];

            if (item instanceof Song) {
                return new SongContext(item, this.getDefaultAction())
            } else if (item instanceof SongContext) {
                return item;
            } else {
                console.error(`unexpected item: ${JSON.stringify(item)}`);
                console.error(item);
                return new SongContext(this.hackSong(item), this.getDefaultAction());
                // return null;
            }
        }
    };

    public updateContextAction(action: SongAction) : void {
        if (this.playerData.playlist.trackList.length === 0) {
            return;
        } else {
            const item = this.playerData.playlist.trackList[this.playerData.playlist.index];

            if (item instanceof Song) {
                this.playerData.playlist.trackList[this.playerData.playlist.index] = new SongContext(item, action)
            } else if (item instanceof SongContext) {
                this.playerData.playlist.trackList[this.playerData.playlist.index] = new SongContext(item.song, action)
            } else {
                console.error(`unexpected item: ${JSON.stringify(item)}`);
                console.error(item);
                this.playerData.playlist.trackList[this.playerData.playlist.index] = new SongContext(this.hackSong(item), action)
                // return;
            }
        }
    }

    private hackSong(item: any): Song | null {
        const tsj = item as any;
        if (tsj.title) {
            const ts: Song = new Song(
                tsj.id,
                tsj.source,
                tsj.title,
                tsj.description,
                tsj.authors,
                tsj.authorsText,
                tsj.authorsMusic,
                tsj.artists,
                tsj.catalog,
                tsj.copyrights
            );
            return ts;
        } else {
            return null;
        }
    }

    public asText(action: SongAction): string {
        switch (action) {
            case SongAction.PERFORM:
                return 'off';
            case SongAction.PERFORM_RECORD:
                return 'on';
            case SongAction.PERFORM_BATTLE:
                return 'battle';
            default:
                return 'off';
        }
    }

    public asAction(action: string): SongAction {
        switch (action) {
            case 'off':
                return SongAction.PERFORM;
            case 'on':
                return SongAction.PERFORM_RECORD;
            case 'battle':
                return SongAction.PERFORM_BATTLE;
            default:
                return SongAction.PERFORM;
        }
    }

    public setDefaultAction(action: SongAction): void {
        this.StorageService.set("RECORD_MODE", this.asText(action));
    }

    public getDefaultAction(): SongAction {
        return this.asAction(this.StorageService.get("RECORD_MODE"));
    }

    public nextTrackId(): number | null {
        if (this.playerData == null) {
            return null;
        } else {
            if ((this.playerData.playlist.trackList.length - 1) == this.playerData.playlist.index) {
                return this.playerData.controls.loop ? 0 : null;
            } else {
                return this.playerData.playlist.index + 1;
            }
        }
    };

    public hasNext(): boolean {
        return this.nextTrackId() != null;
    };

    public loadContext(context: SongContext, backPath: string, sourceId: string) : boolean {
        if (!context || !context.song) {
            return false;
        } else {
            const song = context.song;
            this.load(song.title, backPath, [song], song, sourceId);
            return true;
        }
    }

    /**
     * Загрузка плейлиста в плеер
     *
     * @param playlistTitle заголовок проигрываемого плейлиста
     * @param backPath адрес страницы, на которую будет отправлен пользователь по окончании проигрывания
     * @param tracksList загружаемый плейлист
     * @param currentTrack текущий проигрываемый трек
     * @param sourceId место, откуда загружается плейлитст (передаётся в статистику)
     */
    public load(
        playlistTitle: string,
        backPath: string,
        tracksList: Song[] | SongContext[],
        currentTrack: Song | SongContext,
        sourceId: string
    ) {

        const playerData: InternalPlayerData = {
            context: {
                title: playlistTitle,
                url: backPath,
                source: sourceId
            },
            playlist: {
                trackList: tracksList,
                index: 0
            },
            controls: {
                loop: false
            }
        };

        // вычисляем индекс песни в плейлисте
        let currentSongIndex = null;
        for (let i = 0; i < tracksList.length; i++) {
            const curr = tracksList[i];
            if ((curr instanceof Song) && currentTrack instanceof Song) {
                if (curr.id === currentTrack.id) {
                    currentSongIndex = i;
                }
            } else if ((curr instanceof SongContext) && currentTrack instanceof SongContext) {
                if (curr.song.id === currentTrack.song.id) { // todo: compare actions?
                    currentSongIndex = i;
                }
            }
        }

        if (currentSongIndex != null) {
            playerData.playlist = new InternalPlayerPlaylist(tracksList, currentSongIndex);
        } else {
            const defaultTrack = tracksList[0];
            const defaultTrackList = [];
            defaultTrackList.push(defaultTrack);

            console.warn("passed track not from playlist");
            playerData.playlist = new InternalPlayerPlaylist(defaultTrackList, 0)
        }

        this.playerData = playerData;
        this.save(this.playerData);
    };

    public clear() {
        this.save(null);
    }

    public next(): boolean {
        let nextTrackId = this.nextTrackId();
        if (nextTrackId == null) {
            this.playerData.playlist = {
                trackList: [],
                index: 0
            };
            this.clear();
            return false;
        } else {
            this.playerData.playlist.index = nextTrackId;
            // this.playerData.currentSong = this.currentSong(); //å ???
            this.save(this.playerData);
            return true;
        }
    };


}
