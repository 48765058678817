import {Language} from "../../../branding/Language";
import StorageService from "./StorageService";
import { IQService, IPromise } from "angular";
import {karaoke, TextCode} from "../karaoke";
import {Price} from "../../../branding/BrandingService";

import { i18n } from "i18next";

export default class TextUtilService {

    static $inject = ['$q', '$rootScope', 'StorageService', '$i18next'];

    private _currentLanguage: Language;
    private static readonly STORAGE_LANG_KEY = "TextUtilService.lang";

    constructor(
        private readonly $q: IQService,
        private readonly $rootScope: karaoke.IRootScopeService,
        private readonly storageService: StorageService,
        private readonly $i18next: i18n /*angular.i18next.I18nextProvider*/
    ) {
        this._currentLanguage = Language.RU;
        const storedLanguage = storageService.get(TextUtilService.STORAGE_LANG_KEY, Language.RU);
        try {
            this._currentLanguage = Language[storedLanguage];
        } catch (e) {
            console.info(`Wrong default language: ${storedLanguage}`);
        }
        console.info(`default language are ${this._currentLanguage}`);

        // const language = 'ru';
        /*const language = this._currentLanguage.toLowerCase();
        i18next
            .use(i18nextHttpBackend)
            .init({
                debug: true,
                lng: language,
                preload: [language],
                fallbackLng: [language],
                lowerCaseLng: true,
                backend: {
                    loadPath: '/locales/{{lng}}/{{ns}}.json'
                },
                saveMissing: false,
                /!*missingKeyHandler: function(lng, ns, key, fallbackValue) {
                    console.info("zzz missingKeyHandler: " + key);
                },*!/
                useCookie: false,
                useLocalStorage: false
            }, (err, t) => {
                console.log('language resources loaded');
                this.setLanguage(this._currentLanguage);
            });*/

        this.setLanguage(this._currentLanguage);
    }

    public pluralize(oneTwoFew: Array<string>): ((number: number) => string) {
        return function (number: number) {
            const cases = [2, 0, 1, 1, 1, 2];
            return oneTwoFew[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
        }
    }

    /*public pluralizeSongs(songs: number, single: string, noSongs: string, language: Language = this._currentLanguage): string {
        return !songs ? noSongs : `${songs} ${this.pluralize([single, 'песни', 'песен'])(songs)}`;
    }*/

    /*public pluralizeScores(scores: number, language: Language = this._currentLanguage): string {
        return this.pluralize(['балл', 'балла', 'баллов'])(scores);
    }*/

    /*public songsTextLength(songs: Array<any>, language: Language = this._currentLanguage): string {
        const self = !!this.pluralizeSongs ? this : TextUtilService.prototype; // hack: в this оказывается $scope
        return self.pluralizeSongs(songs?.length, "песня", "Нет песен");
    }*/

    get currentLanguage(): Language {
        return this._currentLanguage;
    }

    public localizedText(textCode: TextCode, options: any = {}, defaultValue: string = textCode): string {
        const translated: string = this.$i18next.t<string>(textCode, options);
        return translated === textCode ? defaultValue : translated;
    }

    public localizedTextFirst(textCodes: Array<string>, options: any = {}, defaultValue: string = textCodes[0]): string {
        for (const nextTextCode of textCodes) {
            const translated: string = this.$i18next.t<string>(nextTextCode, options);
            if (translated !== nextTextCode) {
                return translated;
            }
        }
        return defaultValue;
    }

    public setLanguage(lang: string): IPromise<Language> {
        const defResult = this.$q.defer<Language>();
        const langLow = lang.toLowerCase();
        const langValue: Language = Language[lang.toUpperCase()];
        if (!langValue) {
            const errorMessage = `Wrong language: ${lang}`;
            defResult.notify(errorMessage);
            throw new Error(errorMessage);
        } else {
            this._currentLanguage = langValue;
            this.storageService.set(TextUtilService.STORAGE_LANG_KEY, langValue);
            // this.$i18next.options.lng = langLow
            this.$i18next.changeLanguage(langLow);
            this.$rootScope.language = langLow; // для использования в классах
            this.$rootScope.$on("i18nextLanguageChange", () => {
                setTimeout(() => {
                    this.$rootScope.$apply();
                    defResult.resolve(langValue);
                }, 10);
            });

            /*this.$i18next.changeLanguage(langLow, (err, t) => {
                if (!!err) {
                    const errorMessage = `Unable to set new language ${langValue}: ${err}`;
                    console.error(errorMessage, t);
                    defResult.reject(errorMessage);
                } else {
                    console.info(`new language ${langValue}`);
                    this.$i18next.changeLanguage(langLow);
                    this.$i18next.options.lng = langLow;
                    defResult.notify(langValue);
                }
            })*/
            // console.info(`test new language ${this.$i18next.t('profile.login')}`);
        }
        return defResult.promise;
    }

    public renderPrice(price: Price): string {
        const currencyKey = `currency.${price.currency.toLowerCase()}`;
        const amount = this.localizedText(currencyKey, {count: price.amount});
        const tarifficationKey = price.tarifficationPeriod == null ? null : `tariffication-period.${price.tarifficationPeriod.toLowerCase()}`;
        return tarifficationKey == null ? amount : this.localizedText(tarifficationKey, {price: amount});
    }
}
