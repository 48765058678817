/**
 * Playlist популярного
 */
export default class PopularPlaylistController {

    constructor($scope, $controller) {
        angular.extend(this, $controller('SimplePlaylistController', {
            $scope: $scope,
            source: '/top.json',
            backUrl: "/",
            playlistOptions: {
                title: "playlist.popular.title",
                play: true, // по-умолчанию показываем проигрывание плейлиста
                playlist: true // отображаем функцию добавления в плейлист
            },
            sourceId: 'top'
        }));
    }
}

PopularPlaylistController.$inject = ['$scope', '$controller'];