import {PlayerDeck, PlayerState} from '../model/PlayerModel';
// import SiriWave from "../components/siriwave/siriwave";
// import SiriWave from 'siriwave';
import SiriWave from '../components/wave/index';
import {ErrorState, PlayerError, SongSource} from "../components/IPlayerEngine";
// import PlayerEngineAudioTag from "../components/PlayerEngineAudioTag";
// import PlayerEngineJplayer from "../components/PlayerEngineJplayer";
import PlayerEngineAudioApi from "../components/PlayerEngineAudioApi";
import {karaoke} from "../../karaoke";
import DataService from "../../services/DataService";
import DecoratorService from "../../services/DecoratorService";
import SettingsService from "../../services/SettingsService";
import TextUtilService from "../../services/TextUtilService";
import IRootScopeService = karaoke.IRootScopeService;
import PerformanceService from "./PerformanceService";
import Song from "../../entity/Song";
import BaseController from "../../controllers/BaseController";
import AuthService from "../../services/AuthService";
import PlayerService from "../../services/PlayerService";
import ModalMessageService from "../../services/ModalMessageService";

const rgb2hex = (rgb) => `#${rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/).slice(1).map(n => parseInt(n, 10).toString(16).padStart(2, '0')).join('')}`

export default class PlaybackToneController extends BaseController {

    static $inject = [
        '$scope', '$rootScope', '$controller', '$routeParams', '$location', '$route', '$q',
        'AuthService', 'PlayerService',
        'DataService', 'DecoratorService', 'SettingsService', 'TextUtilService', 'PerformanceService', 'ModalMessageService'
    ];

    private minValueMs: number = 0;
    private maxValueMs: number = 0;

    constructor(
        private readonly $scope,
        private readonly $rootScope: IRootScopeService,
        private readonly $controller: ng.IControllerService,
        private readonly $routeParams: ng.route.IRouteParamsService,
        private readonly $location: ng.ILocationService,
        private readonly $route: ng.route.IRouteService,
        private readonly $q: ng.IQService,
        private readonly authService: AuthService,
        private readonly playerService: PlayerService,
        private readonly dataService: DataService,
        private readonly decoratorService: DecoratorService,
        private readonly settingsService: SettingsService,
        private readonly textUtilService: TextUtilService,
        private readonly performanceService: PerformanceService,
        private readonly modalMessageService: ModalMessageService,
    ) {
        super($scope, $rootScope, $route, authService, playerService, textUtilService)

        $scope.state = PlayerState.LOAD;

        const playbackId: string = $routeParams.playbackId;
        const deferredInit = $q.defer();
        const deferredSong = $q.defer();
        const loadFail = (data) => {
            if (!!data) {
                deferredSong.reject(data);
            } else {
                deferredSong.reject();
            }
            modalMessageService.alert(textUtilService.localizedText("performance.listen.error.not-found"), () => { // "Запись не найдена. Она была удалена, либо истёк срок хранения."
                $location.path("/");
                $route.reload();
            });
        };

        $scope.song = {};

        $scope.player = new PlayerDeck(
            (source, index) => {
                return new PlayerEngineAudioApi(source, $scope.player.audioContext);
                // return new PlayerEngineAudioTag(source);
                // return new PlayerEngineJplayer($, index === 0 ? $('#jplayer_playback_voice') : $('#jplayer_playback_minus'), source);
            }, [
                new SongSource(() => dataService.dataUrl("/song/data/" + $scope.song.id + "." + 'mp3'), null),
                new SongSource(() => dataService.serviceUrl(`/performance/obtainAudioRecord?sid=${playbackId}`), null)
            ], (error: PlayerError, source: SongSource, index: number): SongSource => {
                console.warn(`Error at player with index ${index}`);
                console.warn(status);
                if (error.state === ErrorState.URL) {
                    loadFail(null);
                }
                // console.warn(event.jPlayer.error.type);
                // todo
                /*if (event.jPlayer.error.type === 'e_url') {
                    loadFail();
                }*/

                return null;
            });

        $scope.fragmentHandler = (minValueMs: number, maxValueMs: number) => {
            this.minValueMs = minValueMs;
            this.maxValueMs = maxValueMs;
            $scope.player.setCurrentTime(Math.max(0, minValueMs));
        }

        $scope.doPublish = () => {
            console.info(`PUBLISH ${this.minValueMs} - ${this.maxValueMs}`);

            const song: Song = $scope.song;
            const originalRecordId: string = playbackId;

            if (!!song && !!originalRecordId) {
                const pd: PlayerDeck<SongSource> = $scope.player;
                const volumeMinus:number = pd.getVolume(0); // phone
                const volumeVocal:number = pd.getVolume(1); // user
                console.info("volumeMinus: " + volumeMinus);
                console.info("volumeVocal: " + volumeVocal);
                console.info("minValueMs: " + this.minValueMs);
                console.info("maxValueMs: " + this.maxValueMs);
                this.performanceService.publishRingTone(
                    song,
                    originalRecordId,
                    !!volumeMinus ? volumeMinus : 0,
                    !!volumeVocal ? volumeVocal : 0,
                    this.minValueMs,
                    this.maxValueMs,
                    !!$scope.user ? $scope.user.csrf : null
                );
            } else {
                console.warn("no song to download");
            }
        };

        $scope.handleRewind = (currentTime: number) => {
            $scope.player.setCurrentTime(Math.max(0, currentTime));
        };

        $q.all([deferredSong.promise, deferredInit.promise]).then(() => {
            $scope.player.init();
            // $scope.player.state = PlayerState.STOP;
            $scope.initWave();

            $scope.player.addOnTimeUpdate((index, playerStatus) => {
                $scope.$apply(() => {
                    $scope.timeLine = $scope.player.timeLine;
                    $scope.state = $scope.player.state;
                });
            });

            try {
                $scope.player.addOnTimeUpdate((index, playerStatus) => {
                    if (!!$scope.analyserNode && !!$scope.wave) {
                        $scope.analyserNode.getFloatFrequencyData($scope.analyserNodeDataArray);

                        // var freqs = audioUtil.frequencies()
                        var freqs = $scope.analyserNodeDataArray;
                        var minHz = 40;
                        var maxHz = 100;
                        // var avg = average(analyser, freqs, minHz, maxHz);
                        // var radius = Math.min(width, height) / 4 * avg

                        let avg = 0;
                        for (var i = 0; i < $scope.analyserBufferLength; i++) {
                            avg += $scope.analyserNodeDataArray[i] / 128.0;
                        }
                        avg = avg / $scope.analyserBufferLength;

                        let wv = Math.max(0, Math.min(1, Math.abs(avg)));
                        wv = avg < -1000 ? 0.01 : Math.max(0.1, (wv - 0.5));
                        // console.info(`${avg} - ${wv}`);

                        $scope.wave.setAmplitude(wv);
                    }
                });
            } catch (e) {
                console.warn(e);
                $scope.analyserNode = null;
            }
        });

        $scope.$on('contextLoaded', (event, args) => deferredInit.resolve(event));
        $scope.$on('$destroy', () => $scope.player.destroy());

        $scope.initWave = () => {
            if (!$scope.wave && settingsService.isWaveEnabled()) {
                const selector = $('.siri-container')
                const waveContainer = selector[0];
                const color = selector.css("color");
                $scope.wave = new SiriWave({
                    container: waveContainer,
                    width: 640,
                    height: 320,
                    color: !!color ? rgb2hex(color) : '#FFF',
                    cover: true
                });
                $scope.wave.setAmplitude(0.01);
                $scope.wave.setSpeed(0.2);
                $scope.wave.start();

                $scope.player.getPlayer(0).addOnAnalyzerUpdate(/** @param data {AnalyzerData} */ (data) => {
                    $scope.wave.setAmplitude(data.amplitude);
                });
            }
        };

        $scope.doListen = () => {
            // console.info("doListen");
            // console.info($scope.player.state);
            
            $scope.state = $scope.player.state;
            switch ($scope.player.state) {
                case PlayerState.LOAD:
                    break;
                case PlayerState.PAUSE:
                case PlayerState.STOP:
                    $scope.player.play();
                    if (!!$scope.wave) {
                        $scope.wave.setAmplitude(0.01);
                    }
                    break;
                case PlayerState.PLAY:
                    $scope.player.pause();
                    if (!!$scope.wave) {
                        $scope.wave.setAmplitude(0.01);
                    }
                    break;
                default:
                    console.warn(`unknown state: ${$scope.player.state}`);
            }
        };

        $scope.doForwardToKaraokeSong = () => {
            const song = $scope.song;
            if (!!song) {
                // todo: различать переход со стороны
                $scope.playAction(song, 'performance.repeat.title.repeat', '/', null, 'playback');
            }
        };

        dataService.perfSong(playbackId)
            .then((response) => {
                $scope.song = response.data;
                deferredSong.resolve(response.data);
            }, (response) => loadFail(response.data));

        $scope.promoCatalogItems = decoratorService.promoCatalogContentItems((song) => $scope.playAction(song));

    }
}
