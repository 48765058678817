export default class Tile {

    private readonly _name: string;
    private readonly _description: string;
    private readonly _imageUrl: string;
    private readonly _action: () => void;
    private readonly _source: Object;

    /**
     * Создание промо-объекта
     * @param {String} name
     * @param {String} description
     * @param {String} imageUrl
     * @param {Function} action
     * @param {Object} source
     */
    constructor(name: string, description: string, imageUrl: string, action: () => void, source: Object) {
        this._name = name;
        this._description = description;
        this._imageUrl = imageUrl;
        this._action = action;
        this._source = source;
    }

    get name() {
        return this._name;
    }

    get description() {
        return this._description;
    }

    get imageUrl() {
        return this._imageUrl;
    }

    get action() {
        return this._action;
    }

    get source() {
        return this._source;
    }
}