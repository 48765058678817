/**
 * Playlist пользователя
 */
export default class MyPlaylistController {

    constructor($scope, $controller, PlaylistService) {

        angular.extend(this, $controller('BasePlaylistController', {
            $scope: $scope,
            playlistOptions: {
                title: "playlist.my.title",
                play: true, // по-умолчанию показываем проигрывание плейлиста
                playlist: false // не отображаем функцию добавления в плейлист
            },
            songs: PlaylistService.songs,
            sourceId: 'playlist'
        }));

        $scope.$on('playlist:updated', function (event, songs) {
            $scope.songs = songs;
        });

        $scope.hidePlaylist = true;
        $scope.entirePlaylist = true;

        $scope.doClear = function () {
            PlaylistService.clear();
        };
    }
}

MyPlaylistController.$inject = ['$scope', '$controller', 'PlaylistService'];