// import {Component, Inject. OnInit} from "angular-ts-decorators";
import {Component} from "../../../general/decorators";
import "./BattlePageComponent.less";
import {karaoke} from "../../karaoke";
import template from "./BattlePageComponent.html";
import Battle from "../Battle";
import PerformanceService from "../../player/pages/PerformanceService";
import Song from "../../entity/Song";
import AuthService from "../../services/AuthService";
import {ResponseBattleSongContext, SongContext} from "../../services/entity/ActionContext";
import {SongAction} from "../../services/entity/SongAction";

@Component({
    // selector: 'battlePage',
    controllerAs: '$ctrl',
    bindings: {
        // textBinding: '@',
        // dataBinding: '<',
        // functionBinding: '&'
        goop: '&',
        on: '&'
    },
    template: template
})
export default class BattlePageComponent implements ng.IOnInit {

    static $inject = ['$timeout', '$rootScope', 'PerformanceService', 'AuthService'];

    // public bindings:any;

    public loading: boolean;
    public error: boolean;
    
    private _battles: Array<Battle> = new Array<Battle>();
    private songsDic: Map<number, Song> = new Map<number, Song>();

    constructor(
        /*@Inject('$timeout')*/ private readonly $timeout: ng.ITimeoutService,
        /*@Inject('$rootScope')*/ private readonly $rootScope: karaoke.IRootScopeService,
        /*@Inject('PerformanceService')*/ private readonly performanceService : PerformanceService,
        private readonly authService : AuthService
    ) {
        /*this.bindings = {
            textBinding: '@',
            dataBinding: '<',
            functionBinding: '&'
        };*/
        // this.controller = SomeComponentController;
        // this.templateUrl = 'some-component.html';
        // this.template = "Hello world!";
        this.loading = true;
    }

    $onInit(): void {
        // throw new Error("Method not implemented.");
        console.info('BattlePageComponent inited');

        this.$rootScope.$on("contextLoaded", () => {
            this.performanceService.battles()
                .then((data) => {
                    this._battles = data;
                    this.loading = false;

                    if (!!data) {
                        let songsIds: Set<number> = new Set<number>();

                        data.forEach((battle) => {
                            if (!!battle.opponentSongId) {
                                songsIds.add(battle.opponentSongId);
                            }
                            if (!!battle.ownerSongId) {
                                songsIds.add(battle.ownerSongId);
                            }
                        })
                        this.songsDic = this.performanceService.songDic(Array.from(songsIds.values()));
                    }
                })
                .catch((onReject) => {
                    console.warn(onReject);
                    this.error = true;
                    this.loading = false;
                });
        });
    }

    public name(songId): string {
        const song = this.songsDic.get(songId);
        return !!song ? song.title : `[songId]`;
    }

    get battles(): Array<Battle> {
        return this._battles;
    }

    public tba(battle: Battle): boolean {
        return true;
    }

    public invitesIn(battle: Battle): boolean {
        return true;
    }

    public invitesOut(battle: Battle): boolean {
        return true;
    }

    public complete(battle: Battle): boolean {
        return true;
    }

    public sendInvite(battle: Battle): void {
        this.$rootScope.go(`/battle/${battle.id}/`);
    }

    public doSameSong(battle: Battle): void {
        this.$rootScope.go("/");
        const battleId = battle.id;
        const songId = battle.ownerSongId;
        const song = this.songsDic.get(songId);
        // todo: another context?
        this.authService.submitActionDefault(new ResponseBattleSongContext(song, battleId));
    }

    public listenSelf(battle: Battle): void  {
        const recId = battle.owner ? battle.ownerRecord : battle.opponentRecord;
        if (recId !== null) {
            this.$rootScope.go(`/playback/${recId}`);
        }
    }

    public listenOther(battle: Battle): void {
        const recId = battle.owner ? battle.opponentRecord : battle.ownerRecord;
        if (recId !== null) {
            this.$rootScope.go(`/playback/${recId}`);
        }
    }

    public go(path: string) {
        this.$rootScope.go(path);
    }

    public status(battle: Battle, statuses: string[], owner: boolean = null): boolean {
        return !!battle && statuses.indexOf(battle.status) > -1
            && (owner == null || battle.owner === owner);
    }

    public completeMessage(battle: Battle): string {
        return this.performanceService.battleResult(battle)
    }

    public count(battle: Battle): string {
        if (!battle.ownerScore || !battle.opponentScore) {
            return '-';
        } else {
            return battle.owner
                ? `${Math.round(100 * battle.ownerScore)} : ${Math.round(100 * battle.opponentScore)}`
                : `${Math.round(100 * battle.opponentScore)} : ${Math.round(100 * battle.ownerScore)}`;
        }
    }
}