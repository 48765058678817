import "./MenuComponent.less";
import {Component} from "../../../general/decorators";
import template from "./MenuTemplate.html";
import BrandingService from "../../../../branding/BrandingService";
import {karaoke} from "../../karaoke";
import AuthService from "../../services/AuthService";
import UserProfile from "../../services/entity/UserProfile";
import DataService from "../../services/DataService";
import CatalogAndSong from "../../entity/CatalogAndSong";
import {QuickSearch} from "../Search";
import TextUtilService from "../../services/TextUtilService";
import {Language} from "../../../../branding/Language";
import SettingsService from "../../services/SettingsService";
import {SongAction} from "../../services/entity/SongAction";

@Component({
    controllerAs: '$ctrl',
    bindings: {
        quickSearch: "=",
        toggle: "<",
        close: "<"
    },
    template: template
})
export default class MenuComponent implements ng.IOnInit {

    static $inject = ['$rootScope', 'AuthService', 'BrandingService', 'DataService', 'TextUtilService', 'SettingsService'];

    public catalogOpen: boolean = false;
    public quickSearch: QuickSearch;
    public toggle: () => void;
    public close: () => void;

    public battleAvailable: boolean;
    public userPaid: boolean;
    public catalogs: CatalogAndSong[] = [];

    public closeMenu: () => void;
    public doSearch: () => void;
    public catalogPageOpen: boolean = false;

    constructor(
        private readonly $rootScope: karaoke.IRootScopeService,
        private readonly authService: AuthService,
        private readonly brandingService: BrandingService,
        private readonly dataService: DataService,
        private readonly textUtilService: TextUtilService,
        private readonly settingsService: SettingsService
    ) {

        // console.info("-----");
        // console.info(this.brandingService);
        // console.info(this.brandingService.available(SongAction.PERFORM_BATTLE));

        this.battleAvailable = this.brandingService.available(SongAction.PERFORM_BATTLE);
        this.userPaid = this.authService.userData?.paymentProfile?.subMain;

        this.closeMenu = () => {
            this.close();
            this.catalogOpen = false;
        };

        this.doSearch = () => {
            this.closeMenu();
            this.quickSearch.doSearch();
        };
    }

    $onInit(): void {
        this.$rootScope.$watch("user", (user:UserProfile) => {
            if (!!user) {
                this.battleAvailable = this.brandingService.available(SongAction.PERFORM_BATTLE);
                this.userPaid = user.paymentProfile.subMain;
            }
        });

        // todo: cache catalogs or RX
        this.dataService.requestCatalogs().then((data) => {
            // this.$rootScope.$apply(() => {
            this.catalogs = data;
            // console.info("!!!!!!");
            // console.info(this.catalogs);
            // });
        });
    };

    public toggleCatalog() {
        this.catalogOpen = !this.catalogOpen;
        if (this.brandingService.operator === 'Tele2' || this.brandingService.operator === 'T2') {
            this.catalogPageOpen = this.catalogOpen;
        }
    };

    public go(url: string) {
        this.closeMenu();
        this.$rootScope.go(url);
    };

    public selectCatalog(id: string) {
        this.closeMenu();
        this.$rootScope.go(`/playlist/${id}/`);
        // this.go(`/playlist/${id}/`);
    };

    public toggleMenu() {
        this.toggle();
        this.catalogOpen = false;
    };

    public availableLanguages(): Array<Language> {
        return this.brandingService.availableLanguages;
    }

    public lang(lang: string): void {
        this.textUtilService.setLanguage(lang);
    };

    public rawLanguageCodesEnabled(): boolean {
        return this.settingsService.isRawLanguageCodesEnabled(this.availableLanguages());
    };
}
