import Song from "../entity/Song";
import {karaoke} from "../karaoke";
import IRootScopeService = karaoke.IRootScopeService;
import StorageService from "./StorageService";
import SongListCompressorService from "./SongListCompressorService";
import DataService from "./DataService";

export default class PlaylistService {

    static $inject = ['$rootScope', 'StorageService', 'SongListCompressorService', 'DataService'];

    private static COOKIE_KEY = "t2k_Playlist";
    public songs: Song[] = []; // todo: private?

    constructor(
        private readonly $rootScope: IRootScopeService,
        private readonly StorageService: StorageService,
        private readonly SongListCompressorService: SongListCompressorService,
        private readonly DataService: DataService
    ) {

        // загрузка данных при старте
        const _this = this;
        const fill = (compressed) => SongListCompressorService.deCompress(compressed, (songs) => {
            _this.songs = songs == null ? [] : songs;
            $rootScope.$broadcast('playlist:updated', _this.songs);
        });

        const localPlaylistSongsId = StorageService.get(PlaylistService.COOKIE_KEY);
        this.DataService.dataHttp<string>('/playlist/load').then((response) => {
            const serverPlaylistSongsId = String(response.data).split(',').map((__) => __.trim()).filter((__) => !!__);
            // console.info(`serverPlaylist: '${serverPlaylistSongsId}'`);
            if ((serverPlaylistSongsId === undefined || serverPlaylistSongsId.length === 0) && (
                !!localPlaylistSongsId && localPlaylistSongsId.length > 0)) {
                console.info(`Upload local playlist to server: '${localPlaylistSongsId}'`);
                // локальное хранилище содержит данные, а серверное - нет
                // загрузим данные и отправим локальное хранилище на сервер
                fill(localPlaylistSongsId);

                setTimeout(() => {
                    const csrf = !!$rootScope.user ? $rootScope.user.csrf : null;
                    DataService.sendPost(`/playlist/save`, localPlaylistSongsId, csrf, '');
                }, 2500);

            } else {
                console.info(`Use server's playlist: ${serverPlaylistSongsId?.length ? serverPlaylistSongsId : 'empty'}`);
                StorageService.set(PlaylistService.COOKIE_KEY, serverPlaylistSongsId);
                fill(serverPlaylistSongsId);
            }
        }, function () {
            console.warn(`Unable to obtain server playlist, using local playlist: ${localPlaylistSongsId}`);
            fill(localPlaylistSongsId);
        });
    }

    /**
     * сохранение данных в куки
     */
    private save() : void {
        const csrf = !!this.$rootScope.user ? this.$rootScope.user.csrf : null;
        const _this = this;
        if (this.songs === null || this.songs.length === 0) {
            this.songs = [];
            this.StorageService.remove(PlaylistService.COOKIE_KEY);
            this.DataService.sendPost(`/playlist/clear`, null, csrf, '');
        } else {
            this.SongListCompressorService.compress(this.songs, function (songsId) {
                _this.StorageService.set(PlaylistService.COOKIE_KEY, songsId);
                console.info(`save songs: ${songsId}`);
                _this.DataService.sendPost(`/playlist/save`, songsId, csrf, '');
            });
        }

        this.$rootScope.$broadcast('playlist:updated', this.songs);
    };

    public find(songId: number): Song {
        let result = null;
        this.songs.forEach(function (song) {
            if (song.id === songId) {
                result = song;
            }
        });
        return result;
    };

    public contains(songId: number) {
        return (this.find(songId) != null);
    };

    public add(song: Song) {
        const exist = this.find(song.id);
        if (exist != null) {
            const index = $.inArray(exist, this.songs);
            this.songs.splice(index, 1);
        }
        this.songs.splice(0, 0, song);
        this.save();
    };

    public clear() {
        this.songs.length = 0;
        this.save();
    };

    /**
     * Добавляет метаданные (добавлен ли трек в плейлист и пр)
     * @param songs массив песен с сервера (может быть null)
     * @returns
     */
    public processExternalSongList(songs: Song[]): Song[] {
        const _this = this;

        let result: Song[] = [];
        if (!!songs) {
            songs.forEach((item) => {
                result.push(new Song(
                    item.id,
                    item.source,
                    item.title,
                    item.description,
                    item.authors,
                    item.authorsText,
                    item.authorsMusic,
                    item.artists,
                    item.catalog,
                    item.copyrights,
                    _this.contains(item.id)
                ))
            });
            /*result = songs;
            // примешать добавлена ли в плейлист
            result.forEach(function (song) {
                song.added = _this.contains(song.id);
            });*/
        }
        return result;
    };
}
