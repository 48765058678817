import TextUtilService from "../services/TextUtilService";
import AuthService from "../services/AuthService";
import {IHttpHeadersGetter, IRequestConfig} from "angular";

/**
 * Контроллер, используемый в страницах page-auth.
 * Позволяет авторизоваться по логину и паролю через метод сервиса AuthService basicAuth()
 */
export default class BasicAuthController {

    static $inject = [
        '$scope', 'TextUtilService', 'AuthService'
    ];

    constructor(
        protected readonly $scope,
        protected readonly textUtilService: TextUtilService,
        protected readonly authService: AuthService,
    ) {

        // обработка кнопки «Войти»
        $scope.doBasicAuth = ($event: UIEvent) => {
            $event?.preventDefault();
            $event?.stopPropagation();
            const login = this.getLogin();
            const password = this.getPassword();
            if (!!login && !!password) {
                this.auth(login, password);
            } else {
                // 'Пожалуйста, введите номер телефона и пароль'
                const msg = this.textUtilService.localizedText("auth.required-fields");
                // doAuth(authForm, msg, authData.login, authData.password);
                this.onError(msg);
            }
        }

        // обработка кнопки «Отмена»
        $scope.doBasicAuthClose = ($event: UIEvent) => {
            $event?.preventDefault();
            $event?.stopPropagation();
            this.cancelAction();
        }
    }

    /**
     * Выполнение basic-авторизации по логину и паролю
     *
     * @param login логин, MSISDN пользователя
     * @param password пароль, например, полученный из SMS при выполнении подписки
     * @protected
     */
    protected auth(login: string, password: string): void {
        this.authService.basicAuth(login, password,
            this.postAction,
            (data: string, status: number, headers: IHttpHeadersGetter, config: IRequestConfig, msg: string) => {
                this.onError(this.authService.authMessage(data, status, headers, config, msg));
            });
    }

    /**
     * Действие, выполняемое после подписки, в данном случае, закрытие автоирзационного окна
     */
    protected postAction = () => {
        let pa = this.$scope.postAction
        if (typeof pa === 'function') {
            pa();
        }
        this.close();
    }

    /**
     * Действие, выполняемое при закрытии автоирзационного окна
     *
     */
    protected cancelAction = () => {
        let ca = this.$scope.cancelAction
        if (typeof ca === 'function') {
            ca();
        }
        this.close();
    }

    /**
     * При эмите события close в AuthService doAuth происходит закрытие модельного окна: dialog.modal('hide');
     */
    public close = () => this.$scope.$emit("close");

    /**
     * Получение логина со страницы авторизации
     */
    public getLogin = () => this.$scope.msisdn;

    /**
     * Получение пароля со страницы авторизации
     */
    public getPassword = () => this.$scope.password;

    /**
     * Отображение ошибки
     */
    public onError = (errorMessage: string) => this.$scope.basicAuthErrorMessage = errorMessage;
}