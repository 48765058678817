/**
 * Playlist каталога
 */
import {PlaylistOptions} from "./BasePlaylistController";
import EventService from "../../services/EventService";
import {CatalogContent} from "../../services/DataService";
import Catalog from "../../entity/Catalog";
import TextUtilService from "../../services/TextUtilService";

export default class EventPlaylistController {

    static $inject = ['$scope', '$routeParams', '$controller', 'EventService', 'TextUtilService'];

    constructor(
        private readonly $scope: ng.IScope,
        private readonly $routeParams: ng.route.IRouteParamsService,
        private readonly $controller: ng.IControllerService,
        private readonly eventService: EventService,
        private readonly textUtilService: TextUtilService
    ) {
        const eventId = $routeParams.eventId;
        this.eventService.promoCatalogs().then(response => {
            response.filter(e => eventService.eventId(e) === eventId).find((promo =>{
                const titleKey = eventService.titleKey(promo);
                const dtt: CatalogContent = new CatalogContent(
                    new Catalog(
                        promo.catalog.id,
                        promo.catalog.source,
                        textUtilService.localizedText(titleKey, {}, promo.catalog.title),
                        promo.catalog.description,
                        promo.catalog.priority
                    ),
                    promo.songs
                );

                angular.extend(this, $controller('SimplePlaylistController', {
                    $scope: $scope,
                    source: dtt,
                    backUrl: "/",
                    playlistOptions: new PlaylistOptions("core.loading", true, true),
                    sourceId: 'event:' + eventId
                }));
            }));
        }); //todo: catch
    }
}